import { SERVICE_TYPES } from '@taxfix/operations-types';
import { DraftReasons, SubmissionTypesIds } from '@taxfix/submissions-types';
import { CountryCodes } from '@taxfix/types';

import { SubmissionState } from '../redux/submission';
import { SubmissionsSummaryInfo } from '../redux/user-submissions';

export const getServiceType = (
  submission: SubmissionState | SubmissionsSummaryInfo,
): SERVICE_TYPES | undefined => {
  if (!submission) return;
  if (submission.countryCode === CountryCodes.IT) {
    return SERVICE_TYPES.DIFM;
  }

  if (
    submission.countryCode === CountryCodes.DE &&
    submission.type === SubmissionTypesIds.Draft &&
    submission.metadata?.draftReason?.includes(DraftReasons.ExpertReview)
  ) {
    return SERVICE_TYPES.DIWM;
  }

  if (submission.metadata?.draftReason?.includes(DraftReasons.DoItForMe)) {
    return SERVICE_TYPES.DIFM;
  }

  if (
    submission.countryCode === CountryCodes.DE &&
    submission.type === SubmissionTypesIds.Mandate
  ) {
    return SERVICE_TYPES.DIY;
  }
};
