import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { v2 as DoItForMeSDK } from '@taxfix/do-it-for-me-sdk';
import {
  createWithFormData,
  get as getDocument,
  update as updateDocument,
} from '@taxfix/documents-sdk/dist/api/v1';
import { GetDocumentsResponse } from '@taxfix/documents-sdk/dist/types/v1/get';
import { AllocationSdk, TaxminSdk } from '@taxfix/operations-sdk';
import {
  NextData,
  NextResponse,
} from '@taxfix/operations-sdk/dist/allocation/types';
import {
  AllocationResourceTypes,
  AllocationStates,
} from '@taxfix/operations-types';
import { Payment } from '@taxfix/payment-sdk';
import { getByAdmin as getQuestionFlowSdk } from '@taxfix/question-flow-sdk';
import { GetResponse, RetrievedAnswer } from '@taxfix/question-flow-types';
import { v1 as ExpertReviewSDK } from '@taxfix/sdk-expert-review';
import * as SubmissionSdk from '@taxfix/submissions-sdk';
import { SubmitResponse } from '@taxfix/submissions-sdk/dist/submit/submit';
import {
  ValidateRequest,
  ValidateResponse,
} from '@taxfix/submissions-sdk/dist/validate/validate';
import {
  DeletionReason,
  Override,
  States,
  SubmissionFraudLabelsWithId,
  SubmissionFraudReasons,
  SubmissionResultWithId,
} from '@taxfix/submissions-types';
import {
  Declaration,
  ExpertReview,
  Prefill,
  UserDetails,
  XMLDeclaration,
} from '@taxfix/tax-authority-es-sdk';
import { ExpertReviewItem } from '@taxfix/tax-authority-es-sdk/dist/expert-review/@types';
import {
  CountryCodes,
  Documents as DocumentTypes,
  Documents,
  Platforms,
  TaxEngine,
} from '@taxfix/types';

import { Prefills } from '../../components/submission/es/prefilled-data/types';
import history from '../../history';
import { intlStringsRegistry } from '../../intl';
import { extractAxiosErrorMessage } from '../../utils';
import { getDIFMReadyAtTimestamp } from '../../utils/get-difm-readyAt-timestamp';
import { getServiceType } from '../../utils/submission-service-type';
import { ResponseError, ResponseErrorType, handleError } from '../common';
import { reportError } from '../errors';
import { SubmissionWithIdAndIdentification } from '../identification-submissions';
import {
  RemoteConfigValues,
  agentIdSelector,
  getCorrelateId,
  getCurrentCountry,
  remoteConfigSelector,
  tokenSelector,
} from '../me';
import { MessageSeverity, displayMessage } from '../messages';
import { SubmissionsData } from '../submissions';
import {
  submissionAdditionalFieldsSelector,
  submissionAdditionalOverridesSelector,
  submissionCountryCodeSelector,
  submissionDigitalPrefillSelector,
  submissionDocumentIdSelector,
  submissionIdSelector,
  submissionInfoSelector,
  submissionOverridesSelector,
  submissionPendingValidationSelector,
  submissionResultsSelector,
  submissionUserIdSelector,
} from './selectors';
import {
  deviationPredictionFailure,
  deviationPredictionRequest,
  deviationPredictionSuccess,
  downloadSpainPrefillFailed,
  downloadSpainPrefillRawRequest,
  downloadSpainPrefillSucceeded,
  exportSpainXMLSubmissionFailed,
  exportSpainXMLSubmissionRequest,
  exportSpainXMLSubmissionSuccess,
  getSpainDraftFailed,
  getSpainDraftRequest,
  getSpainDraftSuccess,
  getSpainExpertReviewStatusFailed,
  getSpainExpertReviewStatusRequest,
  getSpainExpertReviewStatusSuccess,
  getSpainM100PreviewFailed,
  getSpainM100PreviewRequest,
  getSpainM100PreviewSuccess,
  getSpainPrefillAnswersFailed,
  getSpainPrefillAnswersRequest,
  getSpainPrefillAnswersSuccess,
  getSpainPrefillData,
  getSpainPrefillDataFailed,
  getSpainPrefillDataSucceeded,
  getUserRefNumberRequest,
  getUserRefNumberRequestFailed,
  getUserRefNumberRequestSuccess,
  importSpainSubmissionPdfFailed,
  importSpainSubmissionPdfRequest,
  importSpainSubmissionPdfSuccess,
  importSpainXMLSubmissionFailed,
  importSpainXMLSubmissionRequest,
  importSpainXMLSubmissionSuccess,
  newSubmissionFailure,
  newSubmissionRequest,
  newSubmissionSuccess,
  retrySpainSubmissionFailed,
  retrySpainSubmissionRequest,
  retrySpainSubmissionSuccess,
  submissionAddFraudLabelRequest,
  submissionAddFraudLabelSuccess,
  submissionArchiveRequest,
  submissionArchiveSuccess,
  submissionBlockRequest,
  submissionBlockSuccess,
  submissionByDoItForMeRequest,
  submissionByExpertReviewRequest,
  submissionDeleteRequest,
  submissionDeleteSuccess,
  submissionDocumentUploadRequest,
  submissionDocumentUploadStop,
  submissionDocumentUploadSuccess,
  submissionFraudLabelRequest,
  submissionFraudLabelSuccess,
  submissionModifyNotesFailure,
  submissionModifyNotesRequest,
  submissionModifyNotesSuccess,
  submissionPoaIdUploadRequest,
  submissionPoaIdUploadSuccess,
  submissionRequest,
  submissionRequestFailure,
  submissionRequestFinished,
  submissionResultPdfRequest,
  submissionResultSuccess,
  submissionResultsRequest,
  submissionRevertRequest,
  submissionSaveRequest,
  submissionSaveSuccess,
  submissionSpainDeclarationPdfFailed,
  submissionSpainDeclarationPdfRequest,
  submissionSpainDeclarationPdfSuccess,
  submissionSubmitRequest,
  submissionSubmitSuccess,
  submissionSuccess,
  submissionUnarchiveRequest,
  submissionUpdate,
  submissionUpdateRequest,
  updateSpainExpertReviewStatusFailed,
  updateSpainExpertReviewStatusRequest,
  updateSpainExpertReviewStatusSuccess,
} from './submission';
import {
  SaveNotesRequest,
  SpainHaciendaDraft,
  SpainPrefillAnswers,
  SpainPreviewPdfSubmissionRequest,
  SubmissionAddFraudLabelRequest,
  SubmissionAppData,
  SubmissionBlockedSuccess,
  SubmissionByDoItForMeRequest,
  SubmissionByExpertReviewRequest,
  SubmissionDeleteRequest,
  SubmissionDocumentUploadRequest,
  SubmissionDocumentUploadSuccess,
  SubmissionPdfRequest,
  SubmissionRequest,
  SubmissionRevertRequest,
  SubmissionSaveRequest,
  SubmissionState,
} from './types';
import { checkForValidation, stringUtf8toBytes } from './utils';

export function* modifySubmissionNotes(): SagaIterator {
  yield takeLatest(
    submissionModifyNotesRequest,
    function* ({ payload: { id, notes } }: PayloadAction<SaveNotesRequest>) {
      try {
        const token: string = yield select(tokenSelector);
        yield call(() =>
          SubmissionSdk.updateNotes(config.apiUrl, token, id, notes),
        );
        yield put(submissionModifyNotesSuccess({ notes }));
      } catch (error) {
        yield put(
          submissionModifyNotesFailure({
            error: error.message,
          }),
        );
      }
    },
  );
}

export function* getSubmissionByDIFM(): SagaIterator {
  yield takeEvery(
    submissionByDoItForMeRequest,
    function* ({
      payload: { difmId },
    }: PayloadAction<SubmissionByDoItForMeRequest>) {
      try {
        const token: string = yield select(tokenSelector);
        const userCorrelateId: string = yield select(getCorrelateId);
        const country: CountryCodes = yield select(getCurrentCountry);
        const remoteConfig: RemoteConfigValues = yield select(
          remoteConfigSelector,
        );
        const enableAllocationDIFM =
          remoteConfig?.enableAllocationDIFM?.asBoolean() ?? false;
        const submission: SubmissionWithIdAndIdentification = yield call(() =>
          DoItForMeSDK.getSubmission(
            config.apiUrl,
            { userOrToken: token, correlateId: userCorrelateId },
            {
              difmId,
            },
          ),
        );
        if (enableAllocationDIFM && country === CountryCodes.DE) {
          const readyAtTimestamp: Date = yield call(
            getDIFMReadyAtTimestamp,
            submission.id.toString(),
          );
          submission.created = readyAtTimestamp;
        }
        if (typeof submission.metadata === 'string') {
          submission.metadata = JSON.parse(submission.metadata as string);
        }

        if (submission.paymentId) {
          submission.paymentStatus = Payment.PaymentState.completed;
        }

        try {
          const updatedResponses: GetResponse = yield call(() =>
            getQuestionFlowSdk(config.apiUrl, token, {
              year: submission.year,
              userId: submission.userId,
            }),
          );

          if (updatedResponses.length) {
            const responsesInObjFormat: {
              [key: string]: RetrievedAnswer;
            } = {};
            updatedResponses.forEach(response => {
              const key = `${response.year}.${response.answerID}`;
              const oldValue = (submission.appData as any)?.[key]?.answer;

              const newResponse: RetrievedAnswer & { isUpdated?: boolean } = {
                ...response,
                ...(submission.appData as any)?.[key],
              };

              if (
                !oldValue ||
                (typeof oldValue === 'object' &&
                  JSON.stringify(oldValue) !==
                    JSON.stringify(response.answer)) ||
                (typeof oldValue !== 'object' && oldValue !== response.answer)
              ) {
                newResponse.isUpdated = true;
              }
              responsesInObjFormat[key] = newResponse;
            });
            submission.appData =
              responsesInObjFormat as unknown as SubmissionAppData;
          }
        } catch (error) {
          console.error('Error fetching updated responses');
        }
        yield put(submissionSuccess(submission));
      } catch (err) {
        yield put(
          submissionRequestFailure({
            error: err.message,
          }),
        );
        yield put(reportError({ error: err }));
      }
    },
  );
}

export function* getSubmissionByExpertReview(): SagaIterator {
  yield takeEvery(
    submissionByExpertReviewRequest,
    function* ({
      payload: { expertReviewId },
    }: PayloadAction<SubmissionByExpertReviewRequest>) {
      try {
        const token: string = yield select(tokenSelector);
        const userCorrelateId: string = yield select(getCorrelateId);

        const submission: SubmissionWithIdAndIdentification = yield call(() =>
          ExpertReviewSDK.getSubmission(
            config.apiUrl,
            { userOrToken: token, correlateId: userCorrelateId },
            {
              expertReviewId,
            },
          ),
        );

        if (typeof submission.metadata === 'string') {
          submission.metadata = JSON.parse(submission.metadata as string);
        }

        if (submission.paymentId) {
          submission.paymentStatus = Payment.PaymentState.completed;
        }

        yield put(submissionSuccess(submission));
      } catch (err) {
        const error: ResponseErrorType = yield* handleError(err);
        yield put(
          submissionRequestFailure({
            error,
          }),
        );

        const message =
          error === ResponseErrorType.ExpertReviewDeleted
            ? intlStringsRegistry.getMessage(`expert-review.${error}`)
            : error;
        yield put(
          displayMessage({
            message,
            severity: MessageSeverity.Error,
          }),
        );
        yield put(reportError({ error: err } as { error: ResponseError }));
      }
    },
  );
}

export function* getSubmission(): SagaIterator {
  yield takeEvery(
    submissionRequest,
    function* ({ payload: { id } }: PayloadAction<SubmissionRequest>) {
      try {
        const token: string = yield select(tokenSelector);
        const country: CountryCodes = yield select(getCurrentCountry);

        const { data }: SubmissionsData = yield call(() =>
          SubmissionSdk.getAll(config.apiUrl, token, {
            countryCode: country,
            submissionId: id,
            projection: [
              'id',
              'year',
              'userId',
              'deleted',
              'identificationDocumentId',
              'identificationDocumentState',
              'paymentId',
              'attempt',
              'isReviewRequired',
              'state',
              'systemVersion',
              'appData',
              'data',
              'overrides',
              'type',
              'validationResult',
              'validationOutput',
              'validationWarnings',
              'plausibilityOutput',
              'isAutomatedSubmission',
              'version',
              'notes',
              'submittedAt',
              'isPrefilled',
              'countryCode',
              'calculationResult',
              'readyAt',
              'isForced',
              'metadata',
              'failedReason',
              'archivedAt',
              'deletionReasonMessage',
              // Fields not included in submission definition
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ...(['created', 'updated'] as any),
            ],
          }),
        );

        const submission: SubmissionState = data?.[0] as SubmissionState;

        // Parse metadata if it's a string
        if (typeof data?.[0].metadata === 'string') {
          submission.metadata = JSON.parse(data?.[0].metadata as string);
        }

        if (
          submission.state === States.Submitted ||
          submission.state === States.Deleted
        ) {
          yield put(submissionResultsRequest({ id: submission.id }));
        }

        if (country === CountryCodes.DE) {
          submission.serviceType = getServiceType(submission);
        }
        if (submission.paymentId && country === CountryCodes.DE) {
          submission.paymentStatus = Payment.PaymentState.completed;
        } else if (submission.paymentId && country === CountryCodes.IT) {
          try {
            const payment = yield call(() =>
              submission.paymentId
                ? Payment.get(config.apiUrl, token, {
                    id: submission.paymentId,
                  })
                : undefined,
            );
            if (payment) {
              submission.paymentStatus = payment.state as Payment.PaymentState;
            }
          } catch (_) {
            // payment call failed
          }
        }

        if (
          submission.countryCode === CountryCodes.DE &&
          submission.metadata.isVDBPrefill &&
          !submission.identificationDocumentId
        ) {
          try {
            const { data } = yield call(() =>
              getDocument(config.apiUrl, token, {
                countryCode: submission.countryCode,
                userId: submission.userId,
                year: submission.year,
                types: [DocumentTypes.NonReceiptTypes.PoaId],
              }),
            );

            if (data.length) {
              submission.digitalPrefill = {
                poaId: data[0].id,
                poaIdState: data[0].state,
              };
            }
          } catch (_) {
            // poaId info failed
            console.log('Error getting the poaid');
          }
        }

        yield put(submissionSuccess(submission));
      } catch (err) {
        yield put(
          submissionRequestFailure({
            error: err.message,
          }),
        );
        yield put(reportError({ error: err }));
      }
    },
  );
}

export function* revertSubmission(): SagaIterator {
  yield takeLatest(
    submissionRevertRequest,
    function* ({ payload: { id } }: PayloadAction<SubmissionRevertRequest>) {
      try {
        const token: string = yield select(tokenSelector);
        const agentId = yield select(agentIdSelector);

        const data = {
          resourceType: AllocationResourceTypes.submission,
          body: {
            agentId,
            state: AllocationStates.assigned,
          },
        };

        yield call(() => AllocationSdk.update(config.apiUrl, token, id, data));

        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'submission.revert.success',
            ),
            severity: MessageSeverity.Success,
          }),
        );
      } catch (err) {
        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage('submission.revert.error'),
            severity: MessageSeverity.Error,
          }),
        );
      }
    },
  );
}

export function* blockSubmission(): SagaIterator {
  yield takeLatest(
    submissionBlockRequest,
    function* ({ payload: { id } }: PayloadAction<SubmissionRequest>) {
      try {
        const token: string = yield select(tokenSelector);
        const submissionDocumentId: number = yield select(
          submissionDocumentIdSelector,
        );
        const digitalPrefillInfo: {
          poaId?: number;
          poaIdState?: Documents.States;
        } = yield select(submissionDigitalPrefillSelector);

        yield call(() => SubmissionSdk.block(config.apiUrl, token, id));

        const payload: SubmissionBlockedSuccess = {
          id,
          state: States.Blocked,
        };

        const documentId = submissionDocumentId
          ? submissionDocumentId
          : digitalPrefillInfo?.poaId;

        if (documentId) {
          try {
            yield call(() =>
              updateDocument(config.apiUrl, token, {
                id: documentId,
                action: Documents.Actions.NotConsider,
                submissionId: id,
              }),
            );

            // update redux state to not considered in id or poaId depending on the case
            if (submissionDocumentId) {
              payload.identificationDocumentState =
                Documents.States.NotConsidered;
            } else if (digitalPrefillInfo?.poaId) {
              payload.digitalPrefill = {
                poaId: digitalPrefillInfo?.poaId,
                poaIdState: Documents.States.NotConsidered,
              };
            }
          } catch (err) {
            yield put(
              displayMessage({
                message: 'Id could not be updated to not-considered',
                severity: MessageSeverity.Error,
              }),
            );
          }
        }

        yield put(submissionBlockSuccess(payload));
      } catch (err) {
        yield put(
          displayMessage({
            message: err.message,
            severity: MessageSeverity.Error,
          }),
        );
      }
    },
  );
}

export function* addFraudLabel(): SagaIterator {
  yield takeLatest(
    submissionAddFraudLabelRequest,
    function* ({
      payload: { id, isFraud },
    }: PayloadAction<SubmissionAddFraudLabelRequest>) {
      try {
        const token: string = yield select(tokenSelector);
        const agentId: string = yield select(agentIdSelector);

        yield call(() =>
          SubmissionSdk.createSubmissionFraudLabel(config.apiUrl, token, {
            submissionId: id,
            fraudReason: SubmissionFraudReasons.ManualCheck,
            fraudAssessedBy: agentId,
            isFraud,
          }),
        );

        yield put(submissionAddFraudLabelSuccess({ isFraud }));
      } catch (err) {
        yield put(
          displayMessage({
            message: err.message,
            severity: MessageSeverity.Error,
          }),
        );
      }
    },
  );
}

export function* getFraudLabel(): SagaIterator {
  yield takeLatest(
    submissionFraudLabelRequest,
    function* ({ payload: { id } }: PayloadAction<SubmissionRequest>) {
      try {
        const token: string = yield select(tokenSelector);

        const fraudLabel: SubmissionFraudLabelsWithId = yield call(() =>
          SubmissionSdk.getSubmissionFraudLabel(config.apiUrl, token, {
            submissionId: id,
          }),
        );

        yield put(
          submissionFraudLabelSuccess({
            isFraud: Boolean(fraudLabel?.isFraud),
          }),
        );
      } catch (err) {
        yield put(
          displayMessage({
            message: err.message,
            severity: MessageSeverity.Error,
          }),
        );
      }
    },
  );
}

export function* saveSubmission(): SagaIterator {
  yield takeLatest(
    submissionSaveRequest,
    function* ({
      payload: { id, userId, year, country, isRevert },
    }: PayloadAction<SubmissionSaveRequest>) {
      try {
        const token: string = yield select(tokenSelector);
        const overrides: Override[] = yield select(submissionOverridesSelector);
        const additionalFields: TaxEngine.TaxForm.Field[] = yield select(
          submissionAdditionalFieldsSelector,
        );
        const additionalOverrides: Override[] = yield select(
          submissionAdditionalOverridesSelector,
        );

        const data: ValidateRequest = {
          // TODO: Remove when fixing src/submission/validate.ts.
          // TODO: Record<string, unknown> type [taxfix-sdk@2.81.0].
          id,
          overrides: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            overrides,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            additionalFields,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            additionalOverrides,
          },
        };

        const response: ValidateResponse = yield call(() =>
          SubmissionSdk.validate(config.apiUrl, token, data),
        );

        yield put(submissionSaveSuccess(response));
        // Update submission values
        yield put(submissionUpdateRequest());

        if (isRevert) {
          yield put(submissionRevertRequest({ id, userId, year, country }));
        }
      } catch (err) {
        yield put(
          displayMessage({
            message: err.message,
            severity: MessageSeverity.Error,
          }),
        );
      }
    },
  );
}

export function* submitSubmission(): SagaIterator {
  yield takeEvery(
    submissionSubmitRequest,
    function* ({ payload: { id } }: PayloadAction<SubmissionRequest>) {
      try {
        const token: string = yield select(tokenSelector);

        const result: SubmitResponse = yield call(() =>
          SubmissionSdk.submit(config.apiUrl, token, { id }),
        );

        yield put(submissionSubmitSuccess(result));
        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'de.submission.action.submit.success',
            ),
            severity: MessageSeverity.Success,
          }),
        );
      } catch (err) {
        yield put(submissionRequestFinished());
        const errorType: ResponseErrorType = yield* handleError(err);
        let errorMessage = 'de.submission.action.submit.error';
        switch (errorType) {
          case ResponseErrorType.UnreviewedDocsToSubmit:
            errorMessage += `.${ResponseErrorType.UnreviewedDocsToSubmit}`;
            break;
        }

        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(errorMessage),
            severity: MessageSeverity.Error,
          }),
        );
        yield put(reportError({ error: err }));
      }
    },
  );
}

export function* deleteSubmission(): SagaIterator {
  yield takeEvery(
    submissionDeleteRequest,
    function* ({
      payload: { id, deletionReasonMessage, flaggedAnswers },
    }: PayloadAction<SubmissionDeleteRequest>) {
      try {
        const token: string = yield select(tokenSelector);
        const deletionData = flaggedAnswers
          ? {
              flaggedAnswers,
              reason: DeletionReason.Resubmission,
            }
          : undefined;
        yield call(() =>
          SubmissionSdk.softDelete(config.apiUrl, token, {
            id,
            deletionReasonMessage,
            deletionData,
          }),
        );

        yield put(submissionDeleteSuccess());
        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'de.submission.action.delete.success',
            ),
            severity: MessageSeverity.Success,
          }),
        );
      } catch (err) {
        yield put(submissionRequestFinished());
        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'de.submission.action.delete.error',
            ),
            severity: MessageSeverity.Error,
          }),
        );
        yield put(reportError({ error: err }));
      }
    },
  );
}

export function* getSubmissionResults(): SagaIterator {
  yield takeEvery(submissionResultsRequest, function* ({ payload: { id } }) {
    try {
      const token: string = yield select(tokenSelector);

      const results: Array<SubmissionResultWithId> = yield call(() =>
        SubmissionSdk.getResults(config.apiUrl, token, { submissionId: id }),
      );

      yield put(submissionResultSuccess(results));
    } catch (err) {
      yield put(
        displayMessage({
          message: err.message,
          severity: MessageSeverity.Error,
        }),
      );
      yield put(reportError({ error: err }));
    }
  });
}

export function* getSubmissionResultPdf(): SagaIterator {
  yield takeEvery(
    submissionResultPdfRequest,
    function* ({
      payload: { id, newWindow },
    }: PayloadAction<SubmissionPdfRequest>): SagaIterator {
      const results: SubmissionResultWithId[] = yield select(
        submissionResultsSelector,
      );
      const result = results.find(currentResult => currentResult.id === id);

      if (!result) {
        return;
      }

      let pdf: string | undefined;
      if (!result?.pdf) {
        const token: string = yield select(tokenSelector);
        const oneResult: SubmissionResultWithId = yield call(() =>
          SubmissionSdk.getOneResult(config.apiUrl, token, {
            submissionId: result.submissionId,
            resultId: id,
          }),
        );

        yield put(
          submissionResultSuccess(
            results.map(currentResult =>
              currentResult.id === id ? oneResult : currentResult,
            ),
          ),
        );

        pdf = oneResult.pdf;
      } else {
        pdf = result.pdf;
      }

      const url = URL.createObjectURL(
        new Blob(pdf ? [stringUtf8toBytes(pdf)] : undefined, {
          type: 'application/pdf',
        }),
      );

      newWindow?.location.replace(url);
    },
  );
}

export function* archiveSubmission(): SagaIterator {
  yield takeEvery(
    submissionArchiveRequest,
    function* ({
      payload: { id },
    }: PayloadAction<SubmissionRequest>): SagaIterator {
      try {
        const token: string = yield select(tokenSelector);
        yield call(() => SubmissionSdk.archive(config.apiUrl, token, { id }));
        yield put(submissionArchiveSuccess());
      } catch (err) {
        yield put(submissionRequestFinished());
        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'de.submission.action.archive.error',
            ),
            severity: MessageSeverity.Error,
          }),
        );
        yield put(reportError({ error: err }));
      }
    },
  );
}

export function* unarchiveSubmission(): SagaIterator {
  yield takeEvery(
    submissionUnarchiveRequest,
    function* ({
      payload: { id },
    }: PayloadAction<SubmissionRequest>): SagaIterator {
      try {
        const token: string = yield select(tokenSelector);
        yield call(() => SubmissionSdk.unarchive(config.apiUrl, token, { id }));
        yield put(submissionRequest({ id }));
      } catch (err) {
        yield put(submissionRequestFinished());
        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'de.submission.action.unarchive.error',
            ),
            severity: MessageSeverity.Error,
          }),
        );
        yield put(reportError({ error: err }));
      }
    },
  );
}

export function* updateSubmission(): SagaIterator {
  yield takeLatest(submissionUpdateRequest, function* () {
    try {
      const token: string = yield select(tokenSelector);
      const id: ReturnType<typeof submissionIdSelector> = yield select(
        submissionIdSelector,
      );
      const countryCode = yield select(submissionCountryCodeSelector);

      const { data }: SubmissionsData = yield call(() =>
        SubmissionSdk.getAll(config.apiUrl, token, {
          submissionId: id,
          countryCode,
          projection: [
            'state',
            'validationResult',
            'validationOutput',
            'validationWarnings',
            'plausibilityOutput',
          ],
        }),
      );

      const submission = data?.[0];
      yield put(submissionUpdate(submission));
    } catch (err) {
      yield put(
        displayMessage({
          message: intlStringsRegistry.getMessage(
            'de.submission.details.errors.update.error',
          ),
          severity: MessageSeverity.Warning,
        }),
      );
      yield put(reportError({ error: err }));
    }
  });
}

/**
 * These sagas check if a submission is in a temporary state, waiting to be
 * processed by the submission-validation-worker. If it is, we wait a few
 * seconds and re-request the submission, using checkForValidation function
 * repeating this until the submission is processed.
 *
 * checkForSubmission -> waits for the submission to be processed by the
 * submission validation worker.
 */

export function* checkForSubmission(): SagaIterator {
  yield takeLatest([submissionSuccess, submissionUpdate], function* () {
    const submissionPending: ReturnType<
      typeof submissionPendingValidationSelector
    > = yield select(submissionPendingValidationSelector);
    if (submissionPending) {
      yield call(checkForValidation);
    }
  });
}

export function* uploadIdentificationDocument(): SagaIterator {
  yield takeLatest(
    submissionDocumentUploadRequest,
    function* ({
      payload: { identificationFile },
    }: PayloadAction<SubmissionDocumentUploadRequest>) {
      try {
        const {
          year,
          userId,
          countryCode,
        }: ReturnType<typeof submissionInfoSelector> = yield select(
          submissionInfoSelector,
        );

        const remoteConfig: RemoteConfigValues = yield select(
          remoteConfigSelector,
        );

        const token: string = yield select(tokenSelector);
        const currentCountry: CountryCodes = yield select(getCurrentCountry);
        let identificationDocument: GetDocumentsResponse | null = null;

        const { id } = remoteConfig?.enableTaxminApiCreateDoc.asBoolean()
          ? yield call(() =>
              TaxminSdk.createDocument(config.apiUrl, token, {
                year,
                countryCode,
                userId,
                type: DocumentTypes.NonReceiptTypes.Id,
                file: identificationFile,
                platform: Platforms.api,
                platformVersion: 'admin-interface',
                filename: 'imagefile',
              }),
            )
          : yield call(() =>
              createWithFormData(config.apiUrl, token, {
                year,
                countryCode,
                userId,
                type: DocumentTypes.NonReceiptTypes.Id,
                upload: identificationFile,
                platform: Platforms.api,
                platformVersion: 'admin-interface',
                filename: 'imagefile',
              }),
            );
        identificationDocument = yield call(() =>
          getDocument(config.apiUrl, token, {
            id: Number(id),
            countryCode: currentCountry,
          }),
        );

        const getUploadedResult = (): SubmissionDocumentUploadSuccess => {
          if (identificationDocument) {
            return {
              id: identificationDocument.data[0].id,
            };
          }

          throw new Error(
            intlStringsRegistry.getMessage(
              'de.submission.action.upload.error.submission_update',
            ),
          );
        };

        yield put(submissionDocumentUploadSuccess(getUploadedResult()));
        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'de.submission.action.upload.success',
            ),
            severity: MessageSeverity.Success,
          }),
        );
      } catch (err) {
        const message: string =
          err.message ||
          intlStringsRegistry.getMessage('de.submission.action.upload.error');
        yield put(submissionDocumentUploadStop());
        yield put(
          displayMessage({
            message: message,
            severity: MessageSeverity.Error,
          }),
        );
      }
    },
  );
}

export function* uploadPoaIdDocument(): SagaIterator {
  yield takeLatest(
    submissionPoaIdUploadRequest,
    function* ({
      payload: { identificationFile },
    }: PayloadAction<SubmissionDocumentUploadRequest>) {
      try {
        const {
          year,
          userId,
          countryCode,
        }: ReturnType<typeof submissionInfoSelector> = yield select(
          submissionInfoSelector,
        );
        const token: string = yield select(tokenSelector);

        const { id } = yield call(() =>
          TaxminSdk.createDocumentPoaId(config.apiUrl, token, {
            year,
            countryCode,
            userId,
            file: identificationFile,
            platform: Platforms.api,
            platformVersion: 'admin-interface',
            filename: 'imagefile',
          }),
        );

        yield put(submissionPoaIdUploadSuccess({ id }));
        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'de.submission.action.upload.success',
            ),
            severity: MessageSeverity.Success,
          }),
        );
      } catch (err) {
        const message: string =
          err.message ||
          intlStringsRegistry.getMessage('de.submission.action.upload.error');
        yield put(submissionDocumentUploadStop());
        yield put(
          displayMessage({
            message: message,
            severity: MessageSeverity.Error,
          }),
        );
      }
    },
  );
}

export function* getDeviationPrediction(): SagaIterator {
  yield takeEvery(
    deviationPredictionRequest,
    function* ({ payload: { id } }: PayloadAction<SubmissionRequest>) {
      try {
        const token: string = yield select(tokenSelector);

        const prediction: number = yield call(() =>
          SubmissionSdk.getDeviationPrediction(config.apiUrl, token, {
            id,
          }),
        );

        yield put(
          deviationPredictionSuccess({
            id,
            predictive: prediction,
          }),
        );
      } catch (err) {
        const error: ResponseErrorType = yield* handleError(err);
        yield put(
          deviationPredictionFailure({
            id,
            error,
          }),
        );
      }
    },
  );
}

export function* retrySpainSubmission(): SagaIterator {
  yield takeLatest(
    retrySpainSubmissionRequest,
    function* ({ payload: { submissionId, successMessage } }) {
      try {
        const token: string = yield select(tokenSelector);
        yield call(() => {
          Declaration.submit(config.apiUrl, { id: submissionId }, { token });
        });
        yield put(retrySpainSubmissionSuccess());
        yield put(
          displayMessage({
            message: successMessage,
            severity: MessageSeverity.Success,
          }),
        );
      } catch (error) {
        yield put(retrySpainSubmissionFailed());
        yield put(
          displayMessage({
            message: error.message,
            severity: MessageSeverity.Error,
          }),
        );
      }
    },
  );
}

export function* importSpainXMLSubmission(): SagaIterator {
  yield takeLatest(
    importSpainXMLSubmissionRequest,
    function* ({ payload: { file, submissionId, successMessage } }) {
      try {
        const token: string = yield select(tokenSelector);

        yield call(() =>
          XMLDeclaration.importXML(
            config.apiUrl,
            { file, submissionId },
            {
              token,
            },
          ),
        );

        yield put(importSpainXMLSubmissionSuccess());

        yield put(
          displayMessage({
            message: successMessage,
            severity: MessageSeverity.Success,
          }),
        );
      } catch (err) {
        yield put(
          displayMessage({
            message: extractAxiosErrorMessage(err) || err.message,
            severity: MessageSeverity.Error,
          }),
        );

        yield put(importSpainXMLSubmissionFailed());
      }
    },
  );
}

export function* importSpainSubmissionPdf(): SagaIterator {
  yield takeLatest(
    importSpainSubmissionPdfRequest,
    function* ({ payload: { file, submissionId, successMessage } }) {
      try {
        const token: string = yield select(tokenSelector);

        yield call(() =>
          Declaration.updateFiledSubmission(
            config.apiUrl,
            { file, id: submissionId },
            {
              token,
            },
          ),
        );

        yield put(importSpainSubmissionPdfSuccess());

        yield put(
          displayMessage({
            message: successMessage,
            severity: MessageSeverity.Success,
          }),
        );
      } catch (err) {
        yield put(
          displayMessage({
            message: extractAxiosErrorMessage(err) || (err as Error).message,
            severity: MessageSeverity.Error,
          }),
        );

        yield put(importSpainSubmissionPdfFailed());
      }
    },
  );
}

export function* exportSpainXMLSubmission(): SagaIterator {
  yield takeLatest(
    exportSpainXMLSubmissionRequest,
    function* ({ payload: { submissionId } }) {
      try {
        const token: string = yield select(tokenSelector);

        const xmlSubmission: string = yield call(() =>
          XMLDeclaration.exportXML(
            config.apiUrl,
            { submissionId },
            {
              token,
            },
          ),
        );

        yield put(exportSpainXMLSubmissionSuccess(xmlSubmission));
      } catch (err) {
        yield put(
          displayMessage({
            message: err.message,
            severity: MessageSeverity.Error,
          }),
        );

        yield put(exportSpainXMLSubmissionFailed());
      }
    },
  );
}

export function* downloadSpainPrefillRawReadable(): SagaIterator {
  yield takeLatest(
    downloadSpainPrefillRawRequest,
    function* ({ payload: { fileType, year } }) {
      try {
        const token: string = yield select(tokenSelector);
        const userId: number = yield select(submissionUserIdSelector);
        const prefill: string = yield call(() =>
          Prefill.fetchPrefillRaw(
            config.apiUrl,
            { userId, format: fileType, year },
            {
              token,
            },
          ),
        );
        yield put(downloadSpainPrefillSucceeded(prefill));
      } catch (err) {
        yield put(
          displayMessage({
            message: err.message,
            severity: MessageSeverity.Error,
          }),
        );
        yield put(downloadSpainPrefillFailed());
      }
    },
  );
}

export function* getSpainPrefillsData(): SagaIterator {
  yield takeLatest(
    getSpainPrefillData,
    function* ({ payload: { fileType, year } }) {
      try {
        const token: string = yield select(tokenSelector);
        const userId: number = yield select(submissionUserIdSelector);
        const prefills: Array<Prefills> = yield call(() =>
          Prefill.fetchPrefillRaw(
            config.apiUrl,
            { userId, format: fileType, year },
            {
              token,
            },
          ),
        );

        yield put(getSpainPrefillDataSucceeded(prefills));
      } catch (err) {
        yield put(
          displayMessage({
            message: err.message,
            severity: MessageSeverity.Error,
          }),
        );
        yield put(getSpainPrefillDataFailed());
      }
    },
  );
}

export function* getSpainExpertReviewStatus(): SagaIterator {
  yield takeLatest(
    getSpainExpertReviewStatusRequest,
    function* ({ payload: { submissionId } }) {
      try {
        const token: string = yield select(tokenSelector);
        const expertReviewStatus: ExpertReviewItem = yield call(() =>
          ExpertReview.getExpertReview(
            config.apiUrl,
            { submissionId },
            {
              token,
            },
          ),
        );

        yield put(getSpainExpertReviewStatusSuccess(expertReviewStatus.state));
      } catch (err) {
        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'es.submission.expert-review.error',
            ),
            severity: MessageSeverity.Error,
          }),
        );

        yield put(getSpainExpertReviewStatusFailed());
      }
    },
  );
}

export function* getUserRefNumber(): SagaIterator {
  yield takeLatest(
    getUserRefNumberRequest,
    function* ({ payload: { userId } }) {
      try {
        const token: string = yield select(tokenSelector);

        const { refNum } = yield call(() =>
          UserDetails.getUserDetails(
            config.apiUrl,
            { userId },
            {
              token,
            },
          ),
        );

        yield put(getUserRefNumberRequestSuccess(refNum));
      } catch (err) {
        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'es.submission.reference-number.error',
            ),
            severity: MessageSeverity.Error,
          }),
        );

        yield put(getUserRefNumberRequestFailed());
      }
    },
  );
}

export function* updateSpainExpertReviewStatus(): SagaIterator {
  yield takeLatest(
    updateSpainExpertReviewStatusRequest,
    function* ({ payload: { submissionId, newState } }) {
      try {
        const token: string = yield select(tokenSelector);

        const expertReviewStatus: ExpertReviewItem = yield call(() =>
          ExpertReview.updateExpertReviewStatus(
            config.apiUrl,
            { submissionId, newState },
            {
              token,
            },
          ),
        );

        yield put(
          updateSpainExpertReviewStatusSuccess(expertReviewStatus.state),
        );

        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'es.submission.update-expert-review.success',
            ),
            severity: MessageSeverity.Success,
          }),
        );
      } catch (err) {
        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'es.submission.update-expert-review.error',
            ),
            severity: MessageSeverity.Error,
          }),
        );

        yield put(updateSpainExpertReviewStatusFailed());
      }
    },
  );
}

export function* getSpainSubmissionDeclarationPdf(): SagaIterator {
  yield takeLatest(
    submissionSpainDeclarationPdfRequest,
    function* ({
      payload: { submissionId },
    }: PayloadAction<SpainPreviewPdfSubmissionRequest>): SagaIterator {
      try {
        const token: string = yield select(tokenSelector);
        const declarationPdfPreview: string = yield call(() =>
          Declaration.preview(
            config.apiUrl,
            { submissionId },
            {
              tokenOrUser: token,
            },
          ),
        );
        yield put(submissionSpainDeclarationPdfSuccess(declarationPdfPreview));
      } catch (err) {
        if (err.response.status === 404) {
          return yield put(submissionSpainDeclarationPdfSuccess(''));
        }

        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'es.submission.action.download-pdf.error',
            ),
            severity: MessageSeverity.Error,
          }),
        );
        yield put(submissionSpainDeclarationPdfFailed());
      }
    },
  );
}

export function* getSpainPrefillAnswers(): SagaIterator {
  yield takeLatest(
    getSpainPrefillAnswersRequest,
    function* ({ payload: { userId, year } }) {
      try {
        const token: string = yield select(tokenSelector);

        const prefillAnswers: SpainPrefillAnswers = yield call(() =>
          Prefill.fetchPrefillAnswers(
            config.apiUrl,
            { userId, year },
            {
              token,
            },
          ),
        );

        yield put(getSpainPrefillAnswersSuccess(prefillAnswers));
      } catch (err) {
        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'es.submission.prefill-answers.error',
            ),
            severity: MessageSeverity.Error,
          }),
        );

        yield put(getSpainPrefillAnswersFailed());
      }
    },
  );
}

export function* getSpainDraft(): SagaIterator {
  yield takeLatest(
    [getSpainDraftRequest],
    function* ({ payload: { userId, year } }) {
      try {
        const token: string = yield select(tokenSelector);

        const { haciendaDraft }: { haciendaDraft: SpainHaciendaDraft } =
          yield call(() =>
            Declaration.getHaciendaDraft(
              config.apiUrl,
              { userId, year },
              {
                tokenOrUser: token,
              },
            ),
          );

        yield put(getSpainDraftSuccess(haciendaDraft));
      } catch (err) {
        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'es.submission.haciendaDraft.get.error',
            ),
            severity: MessageSeverity.Error,
          }),
        );

        yield put(getSpainDraftFailed());
      }
    },
  );
}

export function* getNewSubmission(): SagaIterator {
  yield takeLatest([newSubmissionRequest], function* (action) {
    let countryCode: CountryCodes;
    try {
      const token: string = yield select(tokenSelector);
      countryCode = yield select(getCurrentCountry);
      const agentId: number = yield select(agentIdSelector);
      const serviceType = action.payload;

      const taskParams: NextData = { agentId, countryCode };
      taskParams.serviceType = serviceType;

      const task: NextResponse = yield call(() =>
        AllocationSdk.nextSubmission(config.apiUrl, token, taskParams),
      );

      yield put(newSubmissionSuccess());
      yield call(() =>
        history.push(`/${countryCode}/submissions/${task.resourceId}`),
      );
    } catch (err) {
      console.error(err);
      const errorType: ResponseErrorType = yield* handleError(err);
      if (errorType === ResponseErrorType.NotFound) {
        yield call(() =>
          history.push(`/${countryCode}/submissions/no-submissions`),
        );
      } else {
        const message = err.response?.data?.message
          ? intlStringsRegistry.getMessage(
              `my-submissions.error.${err.response.data.message}`,
            )
          : err.message;
        yield put(
          displayMessage({
            message,
            severity: MessageSeverity.Error,
          }),
        );
        if ((err as ResponseError).response?.status === 400) {
          yield put(newSubmissionFailure(null));
          return;
        }
        yield put(newSubmissionFailure(errorType));
      }
    }
  });
}

export function* getSpainM100Preview(): SagaIterator {
  yield takeLatest(
    getSpainM100PreviewRequest,
    function* ({
      payload: { submissionId },
    }: PayloadAction<SpainPreviewPdfSubmissionRequest>): SagaIterator {
      try {
        const token: string = yield select(tokenSelector);
        const m100Preview = yield call(() =>
          Declaration.preview(
            config.apiUrl,
            { submissionId },
            {
              tokenOrUser: token,
            },
          ),
        );
        const blob = new Blob([m100Preview], { type: 'application/pdf' });
        const previewPath = URL.createObjectURL(blob);

        yield put(getSpainM100PreviewSuccess(previewPath));
      } catch (err) {
        if (err.response.status !== 404) {
          yield put(
            displayMessage({
              message: intlStringsRegistry.getMessage(
                'es.submission.action.download-pdf.error',
              ),
              severity: MessageSeverity.Error,
            }),
          );
        }

        yield put(getSpainM100PreviewFailed());
      }
    },
  );
}

export {
  getNLUserDetail,
  getNLExpertReviewStatus,
  getNLExpertReviewRefundAmount,
  updateNLExpertReviewRefundAmount,
  updateNetherlandsExpertReviewStatus,
  updateNLUserCategoriesState,
  getNLCategoryState,
  importNLSubmitPdfDeclaration,
} from './sagas-nl';

export * from './au/sagas';

export { importZucchettiFile } from './sagas-zucchetti';
