import React from 'react';
import { useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  useLocation,
} from 'react-router-dom';

import { CountryCodes } from '@taxfix/types';

import { isLoggedInSelector } from '../../redux/me';

type PrivateRouteProps = RouteProps & {
  component: React.FC;
};

type ComposedRouterProps = RouteProps &
  RouteComponentProps<{ country: CountryCodes }>;

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const isLoggedIn = useSelector(isLoggedInSelector);

  const { pathname: redirectTo } = useLocation();

  return isLoggedIn ? (
    <Route
      {...rest}
      render={(props: ComposedRouterProps): React.ReactElement => {
        const { country } = props.match.params;
        const isValidUrl = !country || country.toUpperCase() in CountryCodes;

        return isValidUrl ? <Component {...props} /> : <Redirect to="/" />;
      }}
    />
  ) : (
    <Redirect to={{ pathname: '/login', state: { redirectTo } }} />
  );
};

export default PrivateRoute;
