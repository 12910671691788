import { set } from 'lodash';
import { SagaIterator } from 'redux-saga';
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { Permissions } from '@taxfix/auth-sdk-types';
import { v2 as DoItForMeSDK } from '@taxfix/do-it-for-me-sdk';
import { AllocationSdk, TaxminSdk } from '@taxfix/operations-sdk';
import { FindAgentResponse } from '@taxfix/operations-sdk/dist/allocation/types';
import { SERVICE_TYPES, TaxminAgent } from '@taxfix/operations-types';
import { User } from '@taxfix/taxfix-sdk';
import { CurrentUserResponse } from '@taxfix/taxfix-sdk/dist/user/current';
import { CountryCodes } from '@taxfix/types';

import {
  analyticsSdk,
  authSdk,
  createRemoteConfigValue,
  remoteConfigSdk,
} from '../../firebase';
import {
  cleanOldGoogleData,
  initializeTokenId,
} from '../../hooks/use-google-sign-in';
import { intlStringsRegistry } from '../../intl';
import { getUserClient } from '../../services/user-service';
import { AsyncReturnType } from '../../utils';
import { reportError } from '../errors';
import { GetAssignedProvidersResponse } from '../expert-reviews';
import {
  assignedDoItForMeProvidersRequest,
  assignedDoItForMeProvidersSuccess,
  loginFailure,
  loginGoogleError,
  loginGoogleRequest,
  loginRequest,
  loginSuccess,
  logoutGoogleRequest,
  logoutGoogleSuccess,
  logoutRequest,
  logoutSuccess,
  remoteConfigRequest,
  remoteConfigSuccess,
  tokenCheckFinished,
  tokenCheckRequest,
} from './me';
import {
  agentIdSelector,
  agentRightsSelector,
  getCorrelateId,
  isLoggedInSelector,
  remoteConfigSelector,
  tokenSelector,
} from './selectors';
import { LoginType } from './types';

export function* loginMe(): SagaIterator {
  yield takeEvery(loginRequest, function* (action) {
    const { type, email, secret } = action.payload;

    try {
      let loggedUser: AsyncReturnType<
        typeof User.login | typeof User.loginGoogle
      >;

      if (type === LoginType.Google) {
        loggedUser = yield call(() =>
          User.loginGoogle(config.apiUrl, { googleToken: secret }),
        );
      } else {
        loggedUser = yield call(() =>
          User.login(config.apiUrl, {
            email,
            pwd: secret,
          }),
        );
      }
      if (!loggedUser.accessToken) {
        throw new Error(
          intlStringsRegistry.getMessage('login.error.missing_token'),
        );
      }

      const token = loggedUser.accessToken;

      const [{ id }, { rights }]: [
        AsyncReturnType<typeof User.current>,
        AsyncReturnType<typeof User.getCurrentRights>,
      ] = yield all([
        User.current(config.apiUrl, token, {
          countryCode: CountryCodes.DE,
        }),
        User.getCurrentRights(config.apiUrl, token),
      ]);

      let department;
      let allocationRoles;
      try {
        const { department: agentDepartment }: TaxminAgent = yield call(() =>
          TaxminSdk.getTaxminAgent(config.apiUrl, token, {
            agentId: id,
          }),
        );
        department = agentDepartment;
        const remoteConfig: ReturnType<typeof remoteConfigSelector> =
          yield select(remoteConfigSelector);
        const useAllocationAgentsTable =
          remoteConfig?.useAllocationAgentsTable?.asBoolean() ?? false;
        const enableAllocationDIFM =
          remoteConfig?.enableAllocationDIFM?.asBoolean() ?? false;

        if (useAllocationAgentsTable) {
          const agent: FindAgentResponse = yield call(() =>
            AllocationSdk.findAgent(config.apiUrl, token, { agentId: id }),
          );
          allocationRoles = agent
            .filter(
              ({ roleId, serviceType, countryCode }) =>
                roleId &&
                serviceType &&
                countryCode &&
                (enableAllocationDIFM || serviceType !== SERVICE_TYPES.DIFM),
            )
            .reduce(
              (acc, { roleId, serviceType, countryCode }) =>
                set(acc, `${countryCode}.${serviceType}`, roleId),
              {} as any,
            );
        }
      } catch (e) {
        if (e.response.status !== 404) {
          yield put(reportError({ error: e }));
        }
      }

      yield put(
        loginSuccess({
          email,
          id,
          token,
          rights: rights as Permissions[],
          department,
          allocationRoles,
        }),
      );
    } catch (err) {
      yield put(
        loginFailure({
          reason: err.message,
          status: err.response ? err.response.status : 500,
        }),
      );
      yield put(reportError({ error: err }));
    }
  });
}

export function* logoutMe(): SagaIterator {
  yield takeEvery(logoutRequest, function* () {
    try {
      const token: string = yield select(tokenSelector);
      const userClient = getUserClient(token);

      yield call(() => userClient.logoutCurrentUser());
    } finally {
      yield put(logoutSuccess());
    }
  });
}

export function* tokenCheck(): SagaIterator {
  yield takeEvery(
    tokenCheckRequest,
    function* ({ payload: { country = CountryCodes.DE } = {} }) {
      const isLoggedIn: boolean = yield select(isLoggedInSelector);

      if (isLoggedIn) {
        const token: string = yield select(tokenSelector);

        try {
          // Try to get the current user
          const currentAgent: CurrentUserResponse = yield call(() =>
            User.current(config.apiUrl, token, { countryCode: country }),
          );

          yield put(tokenCheckFinished());

          const userProperties: { [key: string]: string | number | boolean } = {
            agent_id: currentAgent.id,
          };

          const agentRights = yield select(agentRightsSelector);
          Object.keys(Permissions).forEach(right => {
            userProperties[`user_right_${right}`] = agentRights.includes(right);
          });

          analyticsSdk.setUserProperties(userProperties);
        } catch (err) {
          console.error(err);
          // If it fails, our token is outdated, so log out
          yield put(logoutSuccess());
          yield put(tokenCheckFinished());
        }
      }
    },
  );
}

export function* getRemoteConfig(): SagaIterator {
  yield takeLatest(remoteConfigRequest, function* () {
    try {
      yield remoteConfigSdk.activate();
      const remoteConfig = remoteConfigSdk.getAll();
      if (
        process.env.NODE_CONFIG_ENV !== 'production' &&
        config.remoteConfigOverride
      ) {
        Object.entries(config.remoteConfigOverride).forEach(([key, value]) => {
          if (typeof value !== 'string') {
            throw new Error('Remote config values should be strings');
          } else {
            remoteConfig[key] = createRemoteConfigValue(value);
          }
        });
      }
      yield put(remoteConfigSuccess({ config: remoteConfig }));
      yield remoteConfigSdk.fetch();
    } catch (e) {
      console.error(e);
      yield put(reportError({ error: e }));
    }
  });
}

export function* loginMeWithGoogle(): SagaIterator {
  yield takeLatest(loginGoogleRequest, function* () {
    try {
      if (!window.google) {
        return;
      }

      yield initializeTokenId();
    } catch (error) {
      yield put(loginFailure({ status: 401, reason: error }));
      cleanOldGoogleData();
      if (error.response?.status && error.response?.status !== 401) {
        yield put(reportError({ error }));
        yield put(loginGoogleError());
      }
    }
  });
}

export function* logoutWithGoogle(): SagaIterator {
  yield takeLatest(logoutGoogleRequest, function* () {
    try {
      if (!window.google) {
        return;
      }

      yield authSdk.signOut();

      const accessToken = localStorage.getItem('google.accessToken');
      if (accessToken) {
        yield google.accounts.oauth2.revoke(accessToken, () => {
          cleanOldGoogleData();
          console.info('access token revoked');
        });
      }

      yield put(logoutGoogleSuccess());
    } catch (e) {
      console.error(e);
    }
  });
}

export function* requestAssignedProvidersForCurrentUser(): SagaIterator {
  yield takeLatest(assignedDoItForMeProvidersRequest, function* () {
    const advisorUserId: number = yield select(agentIdSelector);
    const agentRights: Permissions[] = yield select(agentRightsSelector);
    const token: string = yield select(tokenSelector);
    const userCorrelateId: string = yield select(getCorrelateId);

    try {
      if (agentRights.includes(Permissions.api_difm_de)) {
        const data: GetAssignedProvidersResponse = yield call(() =>
          DoItForMeSDK.getUsersAssignedProviders(
            config.apiUrl,
            { userOrToken: token, correlateId: userCorrelateId },
            {
              advisorUserId,
            },
          ),
        );
        yield put(
          assignedDoItForMeProvidersSuccess({
            assignedDoItForMeProviders: data.providerIds,
          }),
        );
      }
    } catch (e) {
      console.error(e);
    }
  });
}
