import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { submit } from '@taxfix/submissions-sdk';
import { ExpertReview, UserDetails } from '@taxfix/tax-authority-au-sdk';
import {
  GetExpertReviewResponse,
  ReviewStatesKey,
} from '@taxfix/tax-authority-au-sdk/dist/expert-review/@types';
import {
  GetOneUserDetailReply,
  GetUserPictureResponse,
  UserCategoryState,
} from '@taxfix/tax-authority-au-sdk/dist/user-details/@types';

import { intlStringsRegistry } from '../../../intl';
import { tokenSelector } from '../../me';
import { MessageSeverity, displayMessage } from '../../messages';
import {
  approveIdentificationAction,
  deleteAUSubmissionAction,
  getAustraliaUserIdentificationFailure,
  getAustraliaUserIdentificationRequest,
  getAustraliaUserIdentificationSuccess,
  getSubmissionAustraliaDetailsFailure,
  getSubmissionAustraliaDetailsRequest,
  getSubmissionAustraliaDetailsSuccess,
  inputNeededAction,
  markAsFiledAction,
  markAsPendingAction,
  rejectIdentificationAction,
  requestIdentificationAction,
  sendToUserAction,
} from '../submission';

export function* getSubmissionAustraliaDetails() {
  yield takeLatest(
    getSubmissionAustraliaDetailsRequest,
    function* ({ payload: { userId, submissionId } }) {
      try {
        const token: string = yield select(tokenSelector);

        const expertReviewResponse: GetExpertReviewResponse = yield call(() =>
          ExpertReview.getExpertReview(
            config.apiUrl,
            { submissionId },
            { token },
          ),
        );

        const userDetailsResponse: GetOneUserDetailReply = yield call(() =>
          UserDetails.getOneUserDetails(config.apiUrl, { userId }, { token }),
        );

        const { state: expertReviewStatus } = expertReviewResponse;
        const { categoryState } = userDetailsResponse;

        yield put(
          getSubmissionAustraliaDetailsSuccess({
            expertReviewStatus,
            categoryState,
          }),
        );
      } catch (error) {
        yield put(
          displayMessage({
            severity: MessageSeverity.Error,
            message: intlStringsRegistry.getMessage(
              'au.submission.expert-review.error',
            ),
          }),
        );
        yield put(getSubmissionAustraliaDetailsFailure(error.message));
      }
    },
  );
}

export function* sendToUser() {
  yield takeLatest(
    sendToUserAction,
    function* ({ payload: { userId, submissionId } }) {
      try {
        const token: string = yield select(tokenSelector);

        yield call(() =>
          ExpertReview.expertReviewChange(
            config.apiUrl,
            {
              submissionId,
              newExpertReviewState: ReviewStatesKey.DraftReady,
            },
            { token },
          ),
        );

        yield call(() =>
          UserDetails.updateOneUserDetails(
            config.apiUrl,
            {
              userId,
              submissionId,
              categoryState: UserCategoryState.DraftReady,
            },
            { token },
          ),
        );

        yield put(
          getSubmissionAustraliaDetailsRequest({
            userId,
            submissionId,
          }),
        );

        yield put(
          displayMessage({
            severity: MessageSeverity.Success,
            message: intlStringsRegistry.getMessage(
              'au.submission.send-to-user.success',
            ),
          }),
        );
      } catch (error) {
        yield put(
          displayMessage({
            severity: MessageSeverity.Error,
            message: intlStringsRegistry.getMessage(
              'au.submission.send-to-user.error',
            ),
          }),
        );
        yield put(getSubmissionAustraliaDetailsFailure(error.message));
      }
    },
  );
}

export function* markAsPending() {
  yield takeLatest(
    markAsPendingAction,
    function* ({ payload: { userId, submissionId } }) {
      try {
        const token: string = yield select(tokenSelector);

        yield call(() =>
          ExpertReview.expertReviewChange(
            config.apiUrl,
            {
              submissionId,
              newExpertReviewState: ReviewStatesKey.Pending,
            },
            { token },
          ),
        );

        yield call(() =>
          UserDetails.updateOneUserDetails(
            config.apiUrl,
            {
              userId,
              submissionId,
              categoryState: UserCategoryState.UnderReview,
            },
            { token },
          ),
        );

        yield put(
          getSubmissionAustraliaDetailsRequest({
            userId,
            submissionId,
          }),
        );

        yield put(
          displayMessage({
            severity: MessageSeverity.Success,
            message: intlStringsRegistry.getMessage(
              'au.submission.pending.success',
            ),
          }),
        );
      } catch (error) {
        yield put(
          displayMessage({
            severity: MessageSeverity.Error,
            message: intlStringsRegistry.getMessage(
              'au.submission.pending.error',
            ),
          }),
        );
        yield put(getSubmissionAustraliaDetailsFailure(error.message));
      }
    },
  );
}

export function* markAsFiled() {
  yield takeLatest(
    markAsFiledAction,
    function* ({ payload: { userId, submissionId } }) {
      try {
        const token: string = yield select(tokenSelector);

        yield call(() => submit(config.apiUrl, token, { id: submissionId }));

        yield call(() =>
          ExpertReview.expertReviewChange(
            config.apiUrl,
            {
              submissionId,
              newExpertReviewState: ReviewStatesKey.Filed,
            },
            { token },
          ),
        );

        yield call(() =>
          UserDetails.updateOneUserDetails(
            config.apiUrl,
            {
              userId,
              submissionId,
              categoryState: UserCategoryState.Filed,
            },
            { token },
          ),
        );

        yield put(
          getSubmissionAustraliaDetailsRequest({
            userId,
            submissionId,
          }),
        );

        yield put(
          displayMessage({
            severity: MessageSeverity.Success,
            message: intlStringsRegistry.getMessage(
              'au.submission.filed.success',
            ),
          }),
        );
      } catch (error) {
        yield put(
          displayMessage({
            severity: MessageSeverity.Error,
            message: intlStringsRegistry.getMessage(
              'au.submission.filed.error',
            ),
          }),
        );
        yield put(getSubmissionAustraliaDetailsFailure(error.message));
      }
    },
  );
}

export function* inputNeeded() {
  yield takeLatest(
    inputNeededAction,
    function* ({ payload: { userId, submissionId } }) {
      try {
        const token: string = yield select(tokenSelector);

        yield call(() =>
          ExpertReview.expertReviewChange(
            config.apiUrl,
            {
              submissionId,
              newExpertReviewState: ReviewStatesKey.InputNeeded,
            },
            { token },
          ),
        );

        yield call(() =>
          UserDetails.updateOneUserDetails(
            config.apiUrl,
            {
              userId,
              submissionId,
              categoryState: UserCategoryState.InputNeeded,
            },
            { token },
          ),
        );

        yield put(
          getSubmissionAustraliaDetailsRequest({
            userId,
            submissionId,
          }),
        );

        yield put(
          displayMessage({
            severity: MessageSeverity.Success,
            message: intlStringsRegistry.getMessage(
              'au.submission.input-needed.success',
            ),
          }),
        );
      } catch (error) {
        yield put(
          displayMessage({
            severity: MessageSeverity.Error,
            message: intlStringsRegistry.getMessage(
              'au.submission.input-needed.error',
            ),
          }),
        );
        yield put(getSubmissionAustraliaDetailsFailure(error.message));
      }
    },
  );
}

export function* requestIdentification() {
  yield takeLatest(
    requestIdentificationAction,
    function* ({ payload: { userId, submissionId } }) {
      try {
        const token: string = yield select(tokenSelector);

        yield call(() =>
          ExpertReview.expertReviewChange(
            config.apiUrl,
            {
              submissionId,
              newExpertReviewState: ReviewStatesKey.Pending,
            },
            { token },
          ),
        );

        yield call(() =>
          UserDetails.updateOneUserDetails(
            config.apiUrl,
            {
              userId,
              submissionId,
              categoryState: UserCategoryState.IdNeeded,
            },
            { token },
          ),
        );

        yield put(
          getSubmissionAustraliaDetailsRequest({
            userId,
            submissionId,
          }),
        );

        yield put(
          displayMessage({
            severity: MessageSeverity.Success,
            message: intlStringsRegistry.getMessage(
              'au.submission.request-identification.success',
            ),
          }),
        );
      } catch (error) {
        yield put(
          displayMessage({
            severity: MessageSeverity.Error,
            message: intlStringsRegistry.getMessage(
              'au.submission.request-identification.error',
            ),
          }),
        );
        yield put(getSubmissionAustraliaDetailsFailure(error.message));
      }
    },
  );
}

export function* deleteAUSubmission(): SagaIterator {
  yield takeLatest(
    deleteAUSubmissionAction,
    function* ({ payload: { submissionId, userId } }) {
      try {
        const token: string = yield select(tokenSelector);

        yield call(() =>
          ExpertReview.expertReviewChange(
            config.apiUrl,
            {
              submissionId,
              newExpertReviewState: ReviewStatesKey.SubmissionDeleted,
            },
            { token },
          ),
        );

        yield call(() =>
          UserDetails.updateOneUserDetails(
            config.apiUrl,
            {
              userId,
              submissionId,
              categoryState: UserCategoryState.SubmissionDeleted,
            },
            { token },
          ),
        );

        yield put(
          getSubmissionAustraliaDetailsRequest({
            userId,
            submissionId,
          }),
        );

        yield put(
          displayMessage({
            severity: MessageSeverity.Success,
            message: intlStringsRegistry.getMessage(
              'au.submission.delete-submission.success',
            ),
          }),
        );
      } catch (error) {
        yield put(
          displayMessage({
            severity: MessageSeverity.Error,
            message: intlStringsRegistry.getMessage(
              'au.submission.delete-submission.error',
            ),
          }),
        );
        yield put(getSubmissionAustraliaDetailsFailure(error.message));
      }
    },
  );
}

export function* approveIdentification(): SagaIterator {
  yield takeLatest(
    approveIdentificationAction,
    function* ({ payload: { submissionId, userId } }) {
      try {
        const token: string = yield select(tokenSelector);

        yield call(() =>
          ExpertReview.expertReviewChange(
            config.apiUrl,
            {
              submissionId,
              newExpertReviewState: ReviewStatesKey.Pending,
            },
            { token },
          ),
        );

        yield call(() =>
          UserDetails.updateOneUserDetails(
            config.apiUrl,
            {
              userId,
              submissionId,
              categoryState: UserCategoryState.UnderReview,
            },
            { token },
          ),
        );

        yield put(
          getSubmissionAustraliaDetailsRequest({
            userId,
            submissionId,
          }),
        );

        yield put(
          displayMessage({
            severity: MessageSeverity.Success,
            message: intlStringsRegistry.getMessage(
              'au.submission.identification.confirm.success',
            ),
          }),
        );
      } catch (error) {
        yield put(
          displayMessage({
            severity: MessageSeverity.Error,
            message: intlStringsRegistry.getMessage(
              'au.submission.identification.confirm.failed',
            ),
          }),
        );
        yield put(getSubmissionAustraliaDetailsFailure(error.message));
      }
    },
  );
}

export function* rejectIdentification(): SagaIterator {
  yield takeLatest(
    rejectIdentificationAction,
    function* ({ payload: { submissionId, userId } }) {
      try {
        const token: string = yield select(tokenSelector);

        yield call(() =>
          ExpertReview.expertReviewChange(
            config.apiUrl,
            {
              submissionId,
              newExpertReviewState: ReviewStatesKey.IdRejected,
            },
            { token },
          ),
        );

        yield call(() =>
          UserDetails.updateOneUserDetails(
            config.apiUrl,
            {
              userId,
              submissionId,
              categoryState: UserCategoryState.InputNeeded,
            },
            { token },
          ),
        );

        yield put(
          getSubmissionAustraliaDetailsRequest({
            userId,
            submissionId,
          }),
        );

        yield put(
          displayMessage({
            severity: MessageSeverity.Success,
            message: intlStringsRegistry.getMessage(
              'au.submission.identification.reject.success',
            ),
          }),
        );
      } catch (error) {
        yield put(
          displayMessage({
            severity: MessageSeverity.Error,
            message: intlStringsRegistry.getMessage(
              'au.submission.identification.reject.failed',
            ),
          }),
        );
        yield put(getSubmissionAustraliaDetailsFailure(error.message));
      }
    },
  );
}

export function* getUserIdentification() {
  yield takeLatest(
    getAustraliaUserIdentificationRequest,
    function* ({ payload: { userId } }) {
      try {
        const token: string = yield select(tokenSelector);

        const document: GetUserPictureResponse = yield call(() =>
          UserDetails.getUserPicture(config.apiUrl, { userId }, { token }),
        );

        yield put(
          getAustraliaUserIdentificationSuccess({
            document,
          }),
        );
      } catch (error) {
        yield put(getAustraliaUserIdentificationFailure(error.message));
      }
    },
  );
}
