import { InteractionTypes } from '@taxfix/operations-types';

import { SubmissionCommentsState } from './types';

export const submissionCommentsSelector = ({
  submissionComments,
}: {
  submissionComments: SubmissionCommentsState;
}): SubmissionCommentsState => {
  return submissionComments;
};

// Temporal selector until DE removes "Messages" tab
export const submissionDeletionReasonsSelector = ({
  submissionComments,
}: {
  submissionComments: SubmissionCommentsState;
}): SubmissionCommentsState => {
  return {
    ...submissionComments,
    items: submissionComments.items.filter(
      item => item.type === InteractionTypes.deletionReason,
    ),
  };
};
