export enum MessageSeverity {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

export type Message = {
  id: string;
  severity: MessageSeverity;
  message: string;
  error?: Error;
};

export type MessagesState = Message[];

export type MessagesRemove = Message['id'];

export type PostMessage = Omit<Message, 'id'> & {
  timeout?: number;
};
