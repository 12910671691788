import { SagaIterator } from 'redux-saga';
import { delay, put, select } from 'redux-saga/effects';

import { GroupOutput as GroupOutputIT } from '@taxfix/submission';
import { Override, States } from '@taxfix/submissions-types';
import { GroupOutput as GroupOutputDE } from '@taxfix/tax-authority-de-types';
import { TaxEngine } from '@taxfix/types';

import { intlStringsRegistry } from '../../intl';
import { JobTitles } from '../../types';
import { MessageSeverity, displayMessage } from '../messages';
import {
  submissionAdditionalFieldsSelector,
  submissionGroupedFieldsSelector,
  submissionUpdateRetriesSelector,
} from './selectors';
import { submissionUpdateRequest } from './submission';
import {
  FieldGroupCustom,
  SpanishFieldGroup,
  SubmissionDetailsRowData,
  SubmissionField,
  SubmissionModifyOverride,
  SubmissionState,
} from './types';

const JA_FIELD = '0101201';
const JOB_TITLE_A_FIELD = '0100403';
const JOB_TITLE_B_FIELD = '0101003';
const NAME_PERSON_B = '0100801';

// One object per partner. Second object used in case of joint assessment
// Arrays to include any field that could contain this information
export const IDENTIFICATION_DATA_FIELDS = [
  {
    firstName: ['0100301'],
    lastName: ['0100201'],
    street: ['0101104'],
    houseNumber: ['0101206'],
    houserNumberAddition: ['0101207'],
    zipCode: ['0100601', '0101404', '0101405'],
    city: ['0100602'],
    addressAddition: ['0101301'],
    country: ['0101403'],
    birthDate: ['0100401'],
  },
  {
    firstName: ['0100801'],
    lastName: ['0100901'],
    street: ['0102105'],
    houseNumber: ['0102202'],
    houserNumberAddition: ['0102203'],
    zipCode: ['0101701', '0102405', '0102505'],
    city: ['0101702'],
    addressAddition: ['0102301'],
    country: ['0102404'],
    birthDate: ['0101001'],
  },
];

// TODO: Check why fields is null or undefined.
export const getFieldValue = (
  fields: TaxEngine.TaxForm.Field[],
  nr: string,
): number | string | undefined =>
  fields?.find(({ coordinate }) => coordinate.id === nr)?.value;

export const isJointAssessment = (
  fields: TaxEngine.TaxForm.Field[] = [],
): boolean => getFieldValue(fields, JA_FIELD) === 'X';

export const isJointAssessmentTaxCore = (
  fields: TaxEngine.TaxForm.Field[] = [],
): boolean => Boolean(getFieldValue(fields, NAME_PERSON_B));

export const getJobTitles = (
  fields: TaxEngine.TaxForm.Field[] = [],
): JobTitles => ({
  A: (getFieldValue(fields, JOB_TITLE_A_FIELD) as string | undefined) || '',
  B: getFieldValue(fields, JOB_TITLE_B_FIELD) as string | undefined,
});

export const enrichAdditionalFields = (
  additionalFields: SubmissionField[],
  groupedFields: GroupOutputDE,
): SubmissionField[] =>
  additionalFields.map((value: SubmissionField) => {
    for (const group of groupedFields) {
      const fieldFound = group.fields.find(
        groupField =>
          groupField.coordinate.id === value.coordinate.id &&
          groupField.coordinate.lfdNr === value.coordinate.lfdNr &&
          groupField.coordinate.index === value.coordinate.index,
      );

      if (fieldFound) {
        return {
          ...value,
          coordinate: {
            ...fieldFound?.coordinate,
            ...value.coordinate,
          },
          isIncludedInSection: true,
          section: group.title,
        };
      }
    }

    throw new Error('Field does not exist');
  });

export const enrichAdditionalFieldsIt = (
  additionalFields: SubmissionField[],
  groupedFields: GroupOutputIT,
): SubmissionField[] =>
  additionalFields.map((value: SubmissionField) => {
    for (const group of groupedFields) {
      const fieldFound = group.fields.find(groupField => {
        const valueId = value.coordinate.id;
        const coordinateId = valueId.substring(valueId.lastIndexOf('/') + 1);
        return (
          groupField.coordinate.id === coordinateId &&
          groupField.coordinate.indexes === value.coordinate.indexes
        );
      });

      if (fieldFound) {
        return {
          ...value,
          coordinate: {
            ...fieldFound?.coordinate,
            ...value.coordinate,
          },
          isIncludedInSection: true,
          section: group.title,
        };
      }
    }

    throw new Error('Field does not exist');
  });

export const stringUtf8toBytes = (input: string): ArrayBuffer => {
  const buffer = new ArrayBuffer(input.length);
  const bufferView = new Uint8Array(buffer);
  Array.from(input).forEach((char, i) => {
    bufferView[i] = char.charCodeAt(0);
  });
  return buffer;
};

const filterUndefinedValues = <T extends object>(obj: T) =>
  Object.fromEntries(
    Object.entries(obj).filter(
      ([_, value]) => value !== undefined && !Number.isNaN(value),
    ),
  );

export const modifyOverrides = (
  state: SubmissionState,
  overrides: SubmissionModifyOverride[],
): void => {
  const fields = submissionGroupedFieldsSelector({
    submission: state,
  }).flatMap(({ fields }) => fields);

  const additionalFields = submissionAdditionalFieldsSelector({
    submission: state,
  });

  for (const { originalIndex, isAdditionalField, override } of overrides) {
    const overrideKey = isAdditionalField ? 'additionalOverrides' : 'overrides';
    const originalFields = isAdditionalField ? additionalFields : fields;

    const newOverride = {
      ...state.overrides[overrideKey][originalIndex],
      ...override,
    };

    const originalValues = originalFields.find(
      ({ originalIndex: i }) => i === originalIndex,
    );

    const newValue =
      typeof originalValues?.value === 'number' &&
      typeof newOverride.value === 'string'
        ? parseFloat(newOverride.value)
        : newOverride.value;

    const newIndex =
      typeof newOverride.index === 'string'
        ? parseFloat(newOverride.index)
        : newOverride.index;

    const newLfdNr =
      typeof newOverride.lfdNr === 'string'
        ? parseFloat(newOverride.lfdNr)
        : newOverride.lfdNr;

    const parsedOverride = filterUndefinedValues({
      ...newOverride,
      value: newValue,
      index: newIndex,
      lfdNr: newLfdNr,
    }) as Override;

    const valuesChanged =
      ('value' in parsedOverride && newValue !== originalValues?.value) ||
      ('index' in parsedOverride &&
        newIndex !== originalValues?.coordinate.index) ||
      ('lfdNr' in parsedOverride &&
        newLfdNr !== originalValues?.coordinate.lfdNr);

    const shouldBeOverriden = newOverride.override && valuesChanged;

    if (shouldBeOverriden) {
      state.overrides[overrideKey][originalIndex] = parsedOverride;
    } else {
      state.overrides[overrideKey][originalIndex] = {
        override: false,
        deleted: parsedOverride.deleted,
      };
    }
  }
};

const overridenDifferentToOriginal = (
  index: number | undefined,
  lfdNr: number,
  value: null | number | string,
  overridenObj: Override,
): Override => {
  if (
    overridenObj?.override &&
    Number(lfdNr) === Number(overridenObj.lfdNr) &&
    Number(index) === Number(overridenObj.index) &&
    value?.toString() === overridenObj.value?.toString()
  ) {
    return { ...overridenObj, override: false };
  }

  return overridenObj;
};

/**
 * Function that decides what flag is to be shown for a submission
 * @param validationFailed state of the submission
 * @param overrides overrides, changes made
 * @param additionalOverrides additional changes made to the submission
 * @param additionalFields New fields added to the submissionn
 *
 * @returns boolean - true or false
 */

export const needsManualFlag = (
  submissionStatus: number,
  overrides: Override[],
  additionalOverrides: Override[],
  additionalFields: SubmissionField[],
): boolean => {
  const failedValidation = submissionStatus === States.ValidationFailed;
  if (failedValidation) {
    return true;
  }
  const hasAdditionalFields =
    additionalOverrides.length || additionalFields.length;
  for (const currentOverride of overrides) {
    const { override, deleted } = currentOverride;
    if (override || deleted || hasAdditionalFields) {
      return true;
    }
  }
  return false;
};

/**
 * Function to format Original Submission Fields into the data
 * needed by the submission details table.
 * Returns an object with the fields considering override values
 * @param fieldGroup Section blocks in the submission table
 * @param overrides Override values for the fields
 */
export const fieldGroupToSectionData = (
  fieldGroup: FieldGroupCustom,
  overrides: Override[],
  additionalOverrides: Override[],
): { title: string; data: SubmissionDetailsRowData[] } => ({
  title: fieldGroup.title,
  /**
   * TS2349: This error is cause because of the new type Group which is an union of
   * the Group for DE and IT with identical structure.
   * TODO: This was fixed in typescript > 4.2.
   * https://github.com/microsoft/TypeScript/pull/31023
   */
  // @ts-ignore: TS2349: This expression is not callable.
  data: fieldGroup.fields.map(field => {
    const {
      coordinate,
      value,
      originalIndex,
      isAdditionalField,
      isIncludedInSection,
      section,
    } = field;
    const overrideObj = isAdditionalField ? additionalOverrides : overrides;
    return {
      person: coordinate.person === 'AB' ? undefined : coordinate.person,
      submissionField: {
        id: coordinate.id,
        label: coordinate.title,
      },
      index: coordinate.index,
      lfdNr: coordinate.lfdNr,
      id: `${coordinate.id}-${coordinate.lfdNr}-${coordinate.index}-orig${originalIndex}`,
      value,
      originalIndex,
      overrides: overridenDifferentToOriginal(
        coordinate.index,
        coordinate.lfdNr,
        value,
        overrideObj[originalIndex],
      ),
      deleted: overrideObj[originalIndex]?.deleted ?? false,
      isAdditionalField,
      isIncludedInSection: !!isIncludedInSection,
      section,
    };
  }),
});

export const fieldGroupToSectionDataES = (
  fieldGroup: SpanishFieldGroup,
  overrides: Override[],
  additionalOverrides: Override[],
): { title: string; data: Omit<SubmissionDetailsRowData, 'lfdNr'>[] } => ({
  title: fieldGroup.title,
  data: fieldGroup.fields.map(field => {
    const {
      coordinate,
      value,
      originalIndex,
      isAdditionalField,
      isIncludedInSection,
      section,
    } = field;
    const overrideObj = isAdditionalField ? additionalOverrides : overrides;
    const descriptionStr = coordinate.description ?? '';
    const label = coordinate.category
      ? `${coordinate.category} > ${descriptionStr}`
      : descriptionStr;
    return {
      person: coordinate.person === 'AB' ? undefined : coordinate.person,
      submissionField: {
        id: coordinate.id,
        label,
      },
      index: coordinate.index,
      id: `${coordinate.id}}-${coordinate.index}-orig${originalIndex}`,
      value,
      originalIndex,
      overrides: overridenDifferentToOriginal(
        coordinate.index,
        coordinate.lfdNr,
        value,
        overrideObj[originalIndex],
      ),
      deleted: overrideObj[originalIndex]?.deleted ?? false,
      isAdditionalField,
      isIncludedInSection: !!isIncludedInSection,
      section,
    };
  }),
});

export function* checkForValidation(): SagaIterator {
  const retries: ReturnType<typeof submissionUpdateRetriesSelector> =
    yield select(submissionUpdateRetriesSelector);

  if (retries >= decaySchedule.length) {
    yield put(
      displayMessage({
        message: intlStringsRegistry.getMessage(
          'de.submission.details.errors.retry.error',
        ),
        severity: MessageSeverity.Error,
      }),
    );

    return;
  }

  // Re-check according to decay schedule
  yield delay(decaySchedule[retries]);
  yield put(submissionUpdateRequest({ retry: true }));
}

export type PersonalInformation = {
  [key in keyof typeof IDENTIFICATION_DATA_FIELDS[0]]?: string;
};

export const decaySchedule = [
  1000, // After 1 second
  1000 * 3, // After 3 seconds
  1000 * 5, // After 5 seconds
  1000 * 5, // After 10 seconds
  1000 * 15, // After 20 seconds
  1000 * 30, // After 30 seconds
  1000 * 60, // After 1 minute
  1000 * 60 * 3, // After 3 minutes
  1000 * 60 * 5, // After 5 minutes
  1000 * 60 * 10, // After 10 minutes
  1000 * 60 * 30, // After 30 minutes
  1000 * 60 * 60, // After 1 hour
];
