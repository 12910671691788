import { Users, UsersAdmin } from '@taxfix/users-sdk';

export const getUserAdminClient = (accessToken: string) =>
  new UsersAdmin.UsersAPIAdminClient({
    baseURL: config.apiUrl,
    auth: {
      tokenType: 'Token',
      accessToken,
    },
  });

export const getUserClient = (accessToken = '') =>
  new Users.UsersAPIClient({
    baseURL: config.apiUrl,
    auth: {
      tokenType: 'Token',
      accessToken,
    },
  });
