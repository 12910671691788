import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { Message, MessagesRemove, MessagesState, PostMessage } from './types';

const initialState: MessagesState = [];

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    addMessage: (state, { payload }: PayloadAction<Message>): MessagesState => [
      ...state,
      payload,
    ],
    removeMessage: (
      state,
      { payload: idToRemove }: PayloadAction<MessagesRemove>,
    ): MessagesState => state.filter(({ id }) => id !== idToRemove),
  },
});

export default messagesSlice;

export const displayMessage = createAction<PostMessage>(
  `${messagesSlice.name}/displayMessage`,
);
export const { addMessage, removeMessage } = messagesSlice.actions;
