import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Button, Typography } from '@taxfix/ui';

const StyledTypography = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;

const StyledLinkButton = styled(Button)`
  padding: 0px;
  justify-content: flex-start;
  padding: 6px;
  width: 100%;
`;

const HelpfulLink: React.FC<{
  href?: string;
  text: string;
  onClick: () => void;
}> = ({ href, text, onClick }) => {
  const { formatMessage } = useIntl();

  return (
    <StyledLinkButton
      {...(href ? { href, target: '_blank' } : {})}
      variant="text"
      onClick={onClick}
    >
      <StyledTypography noWrap color="inherit">
        {formatMessage({ id: text })}
      </StyledTypography>
    </StyledLinkButton>
  );
};

export default HelpfulLink;
