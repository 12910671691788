import { User } from '@firebase/auth-types';
import jwtDecode from 'jwt-decode';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GoogleAuthProvider, authSdk } from '../firebase';
import {
  LoginStatus,
  LoginType,
  loginFailure,
  loginGoogleError,
  loginHalted,
  loginPopUpsError,
  loginRequest,
  loginStatusSelector,
} from '../redux/me';
import { useExternalScript } from './use-external-script';

export type GoogleSignInConfig = {
  clientId: string;
  cookiePolicy?: string;
  scope?: string;
  fetchBasicProfile?: boolean;
  hostedDomain?: string;
  openIdRealm?: string;
  uxMode?: 'popup' | 'redirect';
  redirectUri?: string;
};

export type GoogleSignInUser = {
  idToken: string;
  accessToken: string;
  expiresAt: number;
  googleId?: string;
  imageUrl?: string;
  email?: string;
  name?: string;
  givenName?: string;
  familyName?: string;
};

const DOM_ID = '__GOOGLE_SIGN_IN__';
const GSI_SRC = 'https://accounts.google.com/gsi/client';
export let initializeTokenId = (): void => {};

export type DecodedGoogleOAuthJwt = {
  iss: string;
  sub: string;
  azp: string;
  aud: string;
  iat: string;
  exp: number;
  email: string;
  email_verified: boolean;
  name: string;
  picture: string;
  given_name: string;
  family_name: string;
  locale: string;
};

let credentialsDecoded: DecodedGoogleOAuthJwt;

export const cleanOldGoogleData = (): void => {
  localStorage.removeItem('google.accessToken');
  localStorage.removeItem('google.expiresAt');
  localStorage.removeItem('google.idToken');
  localStorage.removeItem('google.email');
  clearCooldownCookie();
};

export const getGoogleUser = (
  accessToken: string,
  idToken: string,
  fetchBasicProfile = true,
): GoogleSignInUser | undefined => {
  const { exp } = credentialsDecoded;
  let user: GoogleSignInUser = {
    idToken,
    accessToken,
    expiresAt: exp,
  };

  if (fetchBasicProfile) {
    const { sub, picture, email, name, given_name, family_name } =
      credentialsDecoded;
    user = {
      ...user,
      googleId: sub,
      imageUrl: picture,
      email,
      name,
      givenName: given_name,
      familyName: family_name,
    };
  }

  return user;
};

const isGoogleUserEqualToFirebaseUser = (
  googleUser: GoogleSignInUser,
  firebaseUser: User | null,
): boolean => {
  if (firebaseUser) {
    return firebaseUser.providerData.some(provider => {
      return (
        provider?.providerId === GoogleAuthProvider.PROVIDER_ID &&
        provider.uid === googleUser.googleId
      );
    });
  }
  return false;
};

const clearCooldownCookie = (): void => {
  document.cookie = 'g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
};

export const useGoogleSignIn = (clientConfig: GoogleSignInConfig): void => {
  const loginStatus = useSelector(loginStatusSelector);
  const dispatch = useDispatch();
  const firebaseUnsubscribe = useRef<() => void | undefined>();

  // IMPORTANT: workaround to make sure onCurrentUserChange
  // will always get the updated value from enableFirebaseSignIn
  const mutableOptions = useRef<{
    loginStatus: LoginStatus;
  }>({
    loginStatus,
  });

  mutableOptions.current.loginStatus = loginStatus;

  useEffect(() => {
    if (loginStatus === LoginStatus.busy) {
      // If user closes the window before the login is complete,
      // we need to dispatch loginHalted to reset the login status
      const handleLoginHalt = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        if (
          !localStorage.getItem('google.accessToken') &&
          loginStatus === LoginStatus.busy
        ) {
          dispatch(loginHalted());
        }
      };
      window.addEventListener('beforeunload', handleLoginHalt);
      // remove loginHalt event listener when log in is completed
      return () => {
        window.removeEventListener('beforeunload', handleLoginHalt);
      };
    }
  }, [loginStatus]);

  const onCurrentUserChange = (accessToken: string, idToken: string): void => {
    const now = new Date().getTime();
    const expiresAt = parseInt(localStorage.getItem('google.expiresAt') || '0');
    const isSignedIn: boolean = now < expiresAt;
    const googleUser = getGoogleUser(accessToken, idToken);

    if (firebaseUnsubscribe.current) {
      firebaseUnsubscribe.current();
    }
    if (isSignedIn) {
      firebaseUnsubscribe.current = authSdk.onAuthStateChanged(firebaseUser => {
        if (
          googleUser &&
          !isGoogleUserEqualToFirebaseUser(googleUser, firebaseUser)
        ) {
          const credential = GoogleAuthProvider.credential(idToken);
          authSdk
            .signInWithCredential(credential)
            .catch(error => console.error(error));
        }
      });
    }
    const { email = '' } = googleUser || {};
    if (
      isSignedIn &&
      idToken &&
      mutableOptions.current.loginStatus !== LoginStatus.loggedIn
    ) {
      dispatch(
        loginRequest({
          type: LoginType.Google,
          email,
          secret: idToken,
        }),
      );
    }
    setTimeout(() => {
      client ? client.requestAccessToken() : oauthSignIn(email, idToken);
    }, expiresAt - now);
  };

  const oauthCallback = (
    tokenResponse: google.accounts.oauth2.TokenResponse,
    idToken: string,
  ) => {
    const now = new Date().getTime();
    const expiresAt = now + parseInt(tokenResponse.expires_in) * 1000;
    localStorage.setItem('google.expiresAt', expiresAt.toString());
    const accessToken = tokenResponse.access_token;
    localStorage.setItem('google.accessToken', accessToken);
    onCurrentUserChange(tokenResponse.access_token, idToken);
  };

  let client: google.accounts.oauth2.TokenClient | undefined;
  const oauthSignIn = (email: string, idToken: string) => {
    client = google.accounts.oauth2.initTokenClient({
      client_id: clientConfig.clientId,
      scope: 'profile email',
      hint: email,
      prompt: '',
      callback: tokenResponse => {
        oauthCallback(tokenResponse, idToken);
      },
      error_callback: error => {
        if (error.type === 'popup_failed_to_open') {
          cleanOldGoogleData();
          dispatch(loginPopUpsError());
        }
        console.error(error, 'error calling initTokenClient');
        dispatch(loginFailure({ status: 400, reason: error.message }));
      },
    });

    const now = new Date().getTime();
    const expiresAt = parseInt(localStorage.getItem('google.expiresAt') || '0');
    if (expiresAt === 0 || now > expiresAt) {
      client.requestAccessToken();
    } else {
      const email = localStorage.getItem('google.email') || '';
      const idToken = localStorage.getItem('google.idToken') || '';
      dispatch(
        loginRequest({ type: LoginType.Google, email, secret: idToken }),
      );
    }
  };

  const handleCredentialResponse = (
    response: google.accounts.id.CredentialResponse,
  ): void => {
    credentialsDecoded = jwtDecode(response.credential);
    localStorage.setItem('google.idToken', response.credential);
    localStorage.setItem('google.email', credentialsDecoded.email);
    if (!credentialsDecoded.email_verified) {
      dispatch(loginFailure({ status: 401, reason: 'Email not verified' }));
    } else {
      oauthSignIn(credentialsDecoded.email, response.credential);
    }
  };

  initializeTokenId = () => {
    const now = new Date().getTime();
    const expiresAt = parseInt(localStorage.getItem('google.expiresAt') || '0');
    if (credentialsDecoded && now < expiresAt) {
      const accessToken = localStorage.getItem('google.accessToken') || '';
      const idToken = localStorage.getItem('google.idToken') || '';
      onCurrentUserChange(accessToken, idToken);
    } else {
      google.accounts.id.initialize({
        client_id: clientConfig.clientId,
        callback: handleCredentialResponse,
        cancel_on_tap_outside: false,
      });
      google.accounts.id.prompt(notification => {
        // if the user clicks on the X in the One Tap dialog, it will launch
        // a cooldown period, during which the user will not be prompted again
        // https://developers.google.com/identity/gsi/web/guides/features#exponential_cooldown
        if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
          console.error(notification.getNotDisplayedReason());
          document.cookie =
            'g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
          dispatch(loginGoogleError());
        }
      });
    }
  };

  useExternalScript(
    DOM_ID,
    GSI_SRC,
    () => initializeTokenId,
    error => {
      console.error(error);
    },
  );
};
