import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  Declaration as NLDeclaration,
  ExpertReview as NLExpertReview,
  UserDetails as NLUserDetails,
} from '@taxfix/tax-authority-nl-sdk';
import {
  GetRefundAmountResponse,
  ExpertReviewItem as NLExpertReviewItem,
} from '@taxfix/tax-authority-nl-sdk/dist/expert-review/@types';
import {
  UpdateUserDetailsReply as NLUserDetailsType,
  UserCategoryState,
} from '@taxfix/tax-authority-nl-sdk/dist/user-details/@types';

import { intlStringsRegistry } from '../../intl';
import { extractAxiosErrorMessage } from '../../utils';
import { tokenSelector } from '../me';
import { MessageSeverity, displayMessage } from '../messages';
import {
  getNLExpertReviewRefundAmountFailed,
  getNLExpertReviewRefundAmountRequest,
  getNLExpertReviewRefundAmountSuccess,
  getNLExpertReviewStatusFailed,
  getNLExpertReviewStatusRequest,
  getNLExpertReviewStatusSuccess,
  getNLUserCategoryStateRequest,
  getNLUserCategoryStateRequestSuccess,
  getNLUserCategoryStateRequesttFailed,
  getNLUserDetailRequest,
  getNLUserDetailRequestFailed,
  getNLUserDetailRequestSuccess,
  importNLSubmitPdfDeclarationFailed,
  importNLSubmitPdfDeclarationRequest,
  importNLSubmitPdfDeclarationSuccess,
  updateNLExpertReviewRefundAmountFailed,
  updateNLExpertReviewRefundAmountRequest,
  updateNLExpertReviewRefundAmountSuccess,
  updateNLExpertReviewStatusFailed,
  updateNLExpertReviewStatusRequest,
  updateNLExpertReviewStatusSuccess,
  updateNLUserCategoriesStateFailed,
  updateNLUserCategoriesStateRequest,
  updateNLUserCategoriesStateSuccess,
} from './submission';

export function* getNLUserDetail(): SagaIterator {
  yield takeLatest(getNLUserDetailRequest, function* ({ payload: { userId } }) {
    try {
      const token: string = yield select(tokenSelector);

      const {
        refNum,
        userDetails,
        partnerDetails,
        selectedPrefillOption,
        userAuthorizationCode,
        partnerAuthorizationCode,
        rejectionReason = [],
        rejectionOwnership,
        rejectionDetails,
      } = yield call(() =>
        NLUserDetails.getUserDetails(config.apiUrl, { userId }, { token }),
      );

      const [reason] = rejectionReason as string[];
      const rejection = reason
        ? {
            reason: rejectionReason,
            ownership: rejectionOwnership,
            details: rejectionDetails,
          }
        : undefined;

      yield put(
        getNLUserDetailRequestSuccess({
          refNum,
          userDetails,
          partnerDetails,
          bsn: userDetails?.bsn,
          partnerACode: userAuthorizationCode,
          partnerBCode: partnerAuthorizationCode,
          prefillMethod: selectedPrefillOption,
          rejection,
        }),
      );
    } catch (err) {
      yield put(
        displayMessage({
          message: intlStringsRegistry.getMessage(
            'nl.submission.user-detail.error',
          ),
          severity: MessageSeverity.Error,
        }),
      );

      yield put(getNLUserDetailRequestFailed());
    }
  });
}

export function* getNLExpertReviewRefundAmount(): SagaIterator {
  yield takeLatest(
    getNLExpertReviewRefundAmountRequest,
    function* ({ payload: { submissionId } }) {
      try {
        const token: string = yield select(tokenSelector);
        const expertReviewStatus: GetRefundAmountResponse = yield call(() =>
          NLExpertReview.getRefundAmount(
            config.apiUrl,
            { submissionId },
            { token },
          ),
        );

        yield put(
          getNLExpertReviewRefundAmountSuccess(expertReviewStatus.amountCents),
        );
      } catch (err) {
        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'nl.submission.return-amount-form.error',
            ),
            severity: MessageSeverity.Error,
          }),
        );

        yield put(getNLExpertReviewRefundAmountFailed());
      }
    },
  );
}

export function* updateNLExpertReviewRefundAmount(): SagaIterator {
  yield takeLatest(
    updateNLExpertReviewRefundAmountRequest,
    function* ({ payload: { submissionId, newState } }) {
      try {
        const token: string = yield select(tokenSelector);

        yield call(() =>
          NLExpertReview.storeRefundAmount(
            config.apiUrl,
            { submissionId, amountCents: newState },
            {
              token,
            },
          ),
        );

        yield put(updateNLExpertReviewRefundAmountSuccess(newState));

        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'nl.submission.return-amount-form.success',
            ),
            severity: MessageSeverity.Success,
          }),
        );
      } catch (err) {
        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'nl.submission.return-amount-form.failure',
            ),
            severity: MessageSeverity.Error,
          }),
        );

        yield put(updateNLExpertReviewRefundAmountFailed());
      }
    },
  );
}

export function* getNLExpertReviewStatus(): SagaIterator {
  yield takeLatest(
    getNLExpertReviewStatusRequest,
    function* ({ payload: { submissionId } }) {
      try {
        const token: string = yield select(tokenSelector);
        const expertReviewStatus: NLExpertReviewItem = yield call(() =>
          NLExpertReview.getExpertReview(
            config.apiUrl,
            { submissionId },
            {
              token,
            },
          ),
        );

        yield put(getNLExpertReviewStatusSuccess(expertReviewStatus.state));
      } catch (err) {
        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'es.submission.expert-review.error',
            ),
            severity: MessageSeverity.Error,
          }),
        );

        yield put(getNLExpertReviewStatusFailed());
      }
    },
  );
}

export function* updateNetherlandsExpertReviewStatus(): SagaIterator {
  yield takeLatest(
    updateNLExpertReviewStatusRequest,
    function* ({ payload: { submissionId, newState } }) {
      try {
        const token: string = yield select(tokenSelector);

        const expertReviewStatus: NLExpertReviewItem = yield call(() =>
          NLExpertReview.expertReviewChange(
            config.apiUrl,
            { submissionId, newExpertReviewState: newState },
            {
              token,
            },
          ),
        );

        yield put(updateNLExpertReviewStatusSuccess(expertReviewStatus.state));

        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'es.submission.update-expert-review.success',
            ),
            severity: MessageSeverity.Success,
          }),
        );
      } catch (err) {
        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'es.submission.update-expert-review.error',
            ),
            severity: MessageSeverity.Error,
          }),
        );

        yield put(updateNLExpertReviewStatusFailed());
      }
    },
  );
}

export function* updateNLUserCategoriesState(): SagaIterator {
  yield takeLatest(
    updateNLUserCategoriesStateRequest,
    function* ({ payload: { userId, categoryState, submissionId } }) {
      try {
        const token: string = yield select(tokenSelector);

        const userDetails: NLUserDetailsType = yield call(() =>
          NLUserDetails.updateUserDetails(
            config.apiUrl,
            { userId, categoryState, submissionId },
            {
              token,
            },
          ),
        );

        yield put(
          updateNLUserCategoriesStateSuccess(
            userDetails.categoryState as UserCategoryState,
          ),
        );

        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'nl.submission.update-category-state.success',
            ),
            severity: MessageSeverity.Success,
          }),
        );
      } catch (err) {
        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'nl.submission.update-category-state.error',
            ),
            severity: MessageSeverity.Error,
          }),
        );

        yield put(updateNLUserCategoriesStateFailed());
      }
    },
  );
}

export function* getNLCategoryState(): SagaIterator {
  yield takeLatest(
    getNLUserCategoryStateRequest,
    function* ({ payload: { userId } }) {
      try {
        const token: string = yield select(tokenSelector);

        const { categoryState } = yield call(() =>
          NLUserDetails.getUserDetails(
            config.apiUrl,
            { userId },
            {
              token,
            },
          ),
        );

        yield put(getNLUserCategoryStateRequestSuccess(categoryState));
      } catch (err) {
        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'nl.submission.category-state.error',
            ),
            severity: MessageSeverity.Error,
          }),
        );

        yield put(getNLUserCategoryStateRequesttFailed());
      }
    },
  );
}

export function* importNLSubmitPdfDeclaration(): SagaIterator {
  yield takeLatest(
    importNLSubmitPdfDeclarationRequest,
    function* ({ payload: { file, submissionId, successMessage } }) {
      try {
        const token: string = yield select(tokenSelector);

        yield call(() =>
          NLDeclaration.submit(
            config.apiUrl,
            { submissionId, file },
            {
              token,
            },
          ),
        );

        yield put(importNLSubmitPdfDeclarationSuccess());

        yield put(
          displayMessage({
            message: successMessage,
            severity: MessageSeverity.Success,
          }),
        );
      } catch (err) {
        yield put(
          displayMessage({
            message: extractAxiosErrorMessage(err) || (err as Error).message,
            severity: MessageSeverity.Error,
          }),
        );

        yield put(importNLSubmitPdfDeclarationFailed());
      }
    },
  );
}
