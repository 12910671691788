import React from 'react';

import { Grid, Typography } from '@taxfix/ui';

import { HotKeysMap } from '../../../hooks/use-hotkeys-map';
import ShortcutsList from './shortcuts-list';

type ShortcutsGroup = {
  title: string;
  keyMap: HotKeysMap;
};

export type ShortcutsCheatsheetProps = {
  shortcuts: ShortcutsGroup[];
};

const ShortcutsCheatsheet: React.FC<ShortcutsCheatsheetProps> = ({
  shortcuts,
}) => {
  return (
    <Grid container spacing={2}>
      {shortcuts.map(section => (
        <Grid item xs={6} key={section.title}>
          <Typography variant="h3">{section.title}</Typography>
          <ShortcutsList shortcuts={Object.values(section.keyMap)} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ShortcutsCheatsheet;
