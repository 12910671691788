import { Value } from '@firebase/remote-config-types';
import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/remote-config';

const { GoogleAuthProvider } = firebase.auth;

let analyticsSdk = {} as unknown as firebase.analytics.Analytics;
let remoteConfigSdk = {} as unknown as firebase.remoteConfig.RemoteConfig;
let authSdk = {} as unknown as firebase.auth.Auth;
let templatesCollection = {} as unknown as firebase.firestore.DocumentData;

if (process.env.NODE_ENV !== 'test') {
  firebase.initializeApp(config.firebase);

  analyticsSdk = firebase.analytics();

  remoteConfigSdk = firebase.remoteConfig();
  remoteConfigSdk.settings = {
    fetchTimeoutMillis: config.remoteConfigFetchTimeoutMillis,
    minimumFetchIntervalMillis: config.remoteConfigFetchIntervalMillis,
  };

  authSdk = firebase.auth();

  templatesCollection = firebase
    .firestore()
    .collection(config.firestores.templates);
}

// I know this is ugly asf
// didn't find a better way to create "Values" – there's no export.
// https://github.com/firebase/firebase-js-sdk/blob/master/packages/remote-config/src/value.ts
export const createRemoteConfigValue = (value: string): Value => ({
  // private properties "_value", "_source" aren't included in the Value type
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  _value: value,
  _source: 'default',
  asBoolean(): boolean {
    return ['1', 'true', 't', 'yes', 'y', 'on'].includes(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this._value.toLowerCase(),
    );
  },
  asNumber(): number {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const num = Number(this._value);
    return Number.isNaN(num) ? 0 : num;
  },
  asString(): string {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this._value;
  },
});

export {
  remoteConfigSdk,
  analyticsSdk,
  authSdk,
  GoogleAuthProvider,
  templatesCollection,
};
