import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  v2 as DoItForMeSDK,
  v4 as DoItForMeSDKv4,
} from '@taxfix/do-it-for-me-sdk';
import {
  DoItForMeState,
  DoItForMeStatus,
} from '@taxfix/do-it-for-me-sdk/dist/types';
import { UpdateResponse } from '@taxfix/do-it-for-me-sdk/dist/v2';
import { get as getDocument } from '@taxfix/documents-sdk/dist/api/v1';
import { SubmissionWithId } from '@taxfix/submissions-types';
import { CountryCodes, Documents as DocumentTypes } from '@taxfix/types';

import { intlStringsRegistry } from '../../intl';
import { ResponseError, ResponseErrorType, handleError } from '../common';
import { reportError } from '../errors';
import { getCorrelateId, tokenSelector } from '../me';
import { MessageSeverity, displayMessage } from '../messages';
import {
  submissionAddAdditional,
  submissionAddFieldsDE,
  submissionCountryCodeSelector,
  submissionModifyOverride,
  submissionModifyOverrides,
  submissionPendingValidationSelector,
  submissionSaveSuccess,
  submissionSelector,
  submissionUpdate,
  submissionYearSelector,
} from '../submission';
import {
  SubmissionComment,
  submissionCommentsAddCommentRequest,
  submissionCommentsFailure,
  submissionCommentsSuccess,
} from '../submission-comments';
import {
  submissionDocumentUpdateDoItForMeRequest,
  submissionDocumentUploadDoItForMeRequest,
} from '../submission-documents';
import { checkForValidation } from '../submission/utils';
import { templatesRequest } from '../templates';
import {
  approvedIdentificationDoItForMeRequest,
  doItForMeAutoUpdateToPreparation,
  doItForMeCreateReviewFailure,
  doItForMeCreateReviewRequest,
  doItForMeCreateReviewSuccess,
  doItForMeCurrentsubmissionRequest,
  doItForMeFailure,
  doItForMeNextStatus,
  doItForMeProviderFailure,
  doItForMeProviderSuccess,
  doItForMeRequest,
  doItForMeReviewFailure,
  doItForMeReviewRequest,
  doItForMeReviewSuccess,
  doItForMeSaveRequest,
  doItForMeStatusChangeFailure,
  doItForMeStatusChangeSuccess,
  doItForMeSubmissionCommentsRequest,
  doItForMeSubmissionPDFFailure,
  doItForMeSubmissionPDFRequest,
  doItForMeSubmissionPDFSuccess,
  doItForMeSubmissionUpdateRequest,
  doItForMeSuccess,
  doItForMeUpdateReviewFailure,
  doItForMeUpdateReviewRequest,
  doItForMeUpdateReviewSuccess,
} from './difm';
import {
  allowDifmAutoUpdateToPreparationSelector,
  doItForMeIdSelector,
  doItForMeSelector,
  doItForMeStatusSelector,
  doItForMeSubmissionPDFSelector,
} from './selectors';
import {
  CreateReviewRequest,
  DoItForMe,
  DoItForMeNextStatus,
  DoItForMeRequest,
  DoItForMeSubmissionPDFRequest,
  DoItForMeSuccess,
  DoItForMe as DoItForMeType,
  GetReviewRequest,
  ReviewResponseSuccess as GetReviewResponse,
  IdApprovedRequest,
  UpdateReviewRequest,
} from './types';

export function* getDoItForMeByFilter(): SagaIterator<void> {
  yield takeEvery(
    doItForMeCurrentsubmissionRequest,
    function* getDoItForMeById(
      action: PayloadAction<{ userId: number }>,
    ): SagaIterator<void> {
      const token: string = yield select(tokenSelector);
      const userCorrelateId = yield select(getCorrelateId);
      const year = yield select(submissionYearSelector);
      const countryCode = yield select(submissionCountryCodeSelector);
      const { userId } = action.payload;

      try {
        const data = yield call(() =>
          DoItForMeSDK.getAll(
            config.apiUrl,
            { userOrToken: token, correlateId: userCorrelateId },
            {
              userId,
            },
          ),
        );
        const difm = data.doItForMe.find(
          (difm: DoItForMe) =>
            difm.countryCode === countryCode && difm.year === year,
        );

        if (difm) {
          yield put(doItForMeSuccess(difm));
        } else {
          throw new Error();
        }
      } catch (error) {
        if (error.message !== 'Request failed with status code 404') {
          yield put(doItForMeFailure(error));
          yield put(reportError({ error }));
        }
      }
    },
  );
}

export function* getDoItForMeById(): SagaIterator<void> {
  yield takeEvery(
    doItForMeRequest,
    function* getDoItForMeById(
      action: PayloadAction<DoItForMeRequest>,
    ): SagaIterator<void> {
      const token: string = yield select(tokenSelector);
      const userCorrelateId = yield select(getCorrelateId);
      const { id } = action.payload;

      try {
        const difmData: DoItForMeType = yield call(() =>
          DoItForMeSDK.getOne(
            config.apiUrl,
            { userOrToken: token, correlateId: userCorrelateId },
            {
              id,
            },
          ),
        );

        if (
          difmData.countryCode === CountryCodes.DE &&
          (![DoItForMeStatus.OptedIn, DoItForMeStatus.Cancelled].includes(
            difmData.status as DoItForMeStatus,
          ) ||
            ![DoItForMeState.OptedIn, DoItForMeState.Cancelled].includes(
              difmData.state as DoItForMeState,
            ))
        ) {
          try {
            const { data } = yield call(() =>
              getDocument(config.apiUrl, token, {
                countryCode: difmData.countryCode,
                userId: difmData.userId,
                year: difmData.year,
                types: [
                  DocumentTypes.NonReceiptTypes.PoaId,
                  DocumentTypes.NonReceiptTypes.Address,
                ],
              }),
            );
            if (data.length) {
              if (difmData.isPrefilled) {
                const poaIdentification = data.find(
                  (doc: { type: DocumentTypes.NonReceiptTypes }) =>
                    doc.type === DocumentTypes.NonReceiptTypes.PoaId,
                );

                difmData.poaIdentification = {
                  poaId: poaIdentification?.id,
                  poaState: poaIdentification?.state,
                };
              }

              const addressType = data.find(
                (doc: { type: DocumentTypes.NonReceiptTypes }) =>
                  doc.type === DocumentTypes.NonReceiptTypes.Address,
              );

              if (addressType) {
                difmData.addressIdentification = {
                  id: addressType.id,
                  state: addressType.state,
                };
              }
            }
          } catch (error) {
            yield put(doItForMeFailure(error));
          }
        }

        yield put(doItForMeSuccess(difmData));
      } catch (error) {
        if (error.message !== 'Request failed with status code 404') {
          yield put(doItForMeFailure(error));
          yield put(
            displayMessage({
              message: error.message,
              severity: MessageSeverity.Error,
            }),
          );
          yield put(reportError({ error }));
        }
      }
    },
  );
}

export function* getDoItForMeProvider(): SagaIterator<void> {
  yield takeLatest(
    doItForMeSuccess,
    function* getDoItForMeProvider(
      action: PayloadAction<DoItForMeSuccess>,
    ): SagaIterator<void> {
      const token: string = yield select(tokenSelector);
      const userCorrelateId = yield select(getCorrelateId);
      const id = action.payload.reviewProviderId;
      try {
        const provider = yield call(() =>
          DoItForMeSDK.getProvider(
            config.apiUrl,
            { userOrToken: token, correlateId: userCorrelateId },
            {
              id,
            },
          ),
        );

        if (provider?.name) {
          yield put(doItForMeProviderSuccess(provider.name));
        }
      } catch (error) {
        yield put(doItForMeProviderFailure(error));
        yield put(
          displayMessage({
            message: error.message,
            severity: MessageSeverity.Error,
          }),
        );
        yield put(reportError({ error }));
      }
    },
  );
}

export function* getReviews(): SagaIterator {
  yield takeLatest(
    doItForMeReviewRequest,
    function* (action: PayloadAction<GetReviewRequest>): SagaIterator {
      try {
        const token: string = yield select(tokenSelector);
        const userCorrelateId = yield select(getCorrelateId);
        const review: GetReviewResponse = yield call(() =>
          DoItForMeSDK.getReview(
            config.apiUrl,
            { userOrToken: token, correlateId: userCorrelateId },
            { ...action.payload },
          ),
        );

        yield put(doItForMeReviewSuccess(review));
      } catch (error) {
        const errorType: ResponseErrorType = yield* handleError(
          error as ResponseError,
        );
        yield put(doItForMeReviewFailure(errorType));
      }
    },
  );
}

export function* updateDoItForMeStatus(): SagaIterator<void> {
  yield takeLatest(
    doItForMeNextStatus,
    function* updateDoItForMeStatus(
      action: PayloadAction<DoItForMeNextStatus>,
    ): SagaIterator<void> {
      // call the endpoint to update the status.
      const token: string = yield select(tokenSelector);
      const userCorrelateId = yield select(getCorrelateId);
      const id = yield select(doItForMeIdSelector);
      const currentStatus = yield select(doItForMeStatusSelector);
      const { nextStatus, ...extraPayload } = action.payload;
      try {
        const response: UpdateResponse = yield call(
          async () =>
            await DoItForMeSDKv4.update(
              config.apiUrl,
              {
                userOrToken: token,
                correlateId: userCorrelateId,
              },
              {
                id,
                status: nextStatus,
                ...extraPayload,
              },
            ),
        );
        if (response?.status) {
          yield put(doItForMeStatusChangeSuccess(response));
        }
        const message = intlStringsRegistry.getMessage(
          'difm.state-machine.successfully-updated',
          {
            status: nextStatus,
          },
        );
        yield put(
          displayMessage({
            message,
            severity: MessageSeverity.Success,
          }),
        );
      } catch (error) {
        yield put(
          doItForMeStatusChangeFailure({
            status: currentStatus,
          }),
        );
        yield put(
          displayMessage({
            message: error.message,
            severity: MessageSeverity.Error,
          }),
        );
        yield put(reportError({ error }));
      }
    },
  );
}

export function* saveDoItForMeSubmission(): SagaIterator<void> {
  yield takeEvery(
    doItForMeSaveRequest,
    function* saveDoItForMeSubmission(): SagaIterator<void> {
      const token: string = yield select(tokenSelector);
      const userCorrelateId = yield select(getCorrelateId);
      const { id } = yield select(doItForMeSelector);
      const { overrides, id: submissionId } = yield select(submissionSelector);

      const response = yield call(() =>
        DoItForMeSDK.validate(
          config.apiUrl,
          {
            userOrToken: token,
            correlateId: userCorrelateId,
          },
          {
            id,
            submissionId,
            overrides,
          },
        ),
      );

      yield put(submissionSaveSuccess(response));

      // Update submission values
      yield put(doItForMeSubmissionUpdateRequest());
    },
  );
}

export function* updateDIFMSubmission(): SagaIterator<void> {
  yield takeLatest(
    doItForMeSubmissionUpdateRequest,
    function* updateDIFMSubmission(): SagaIterator<void> {
      try {
        const userOrToken: string = yield select(tokenSelector);
        const correlateId: string = yield select(getCorrelateId);
        const { id } = yield select(doItForMeSelector);

        const submission: Partial<SubmissionWithId> = yield call(() =>
          DoItForMeSDK.getSubmission(
            config.apiUrl,
            {
              userOrToken,
              correlateId,
            },
            {
              difmId: id,
            },
          ),
        );

        const {
          state,
          validationResult,
          validationOutput,
          validationWarnings,
          plausibilityOutput,
        } = submission;

        yield put(
          submissionUpdate({
            state,
            validationResult,
            validationOutput,
            validationWarnings,
            plausibilityOutput,
          }),
        );
      } catch (error) {
        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'de.submission.details.errors.update.error',
            ),
            severity: MessageSeverity.Warning,
          }),
        );
        yield put(reportError({ error }));
      }
    },
  );
}

export function* checkForDIFMSubmission(): SagaIterator {
  yield takeLatest([doItForMeSubmissionUpdateRequest], function* () {
    const submissionPending: ReturnType<
      typeof submissionPendingValidationSelector
    > = yield select(submissionPendingValidationSelector);
    if (submissionPending) {
      yield call(checkForValidation);
    }
  });
}

export function* getDoItForMeSubmissionPdf(): SagaIterator<void> {
  yield takeLatest(
    doItForMeSubmissionPDFRequest,
    function* getDoItForMeSubmissionPdf(
      action: PayloadAction<DoItForMeSubmissionPDFRequest>,
    ): SagaIterator<void> {
      const doItForMeSubmissionPDF = yield select(
        doItForMeSubmissionPDFSelector,
      );
      const { doItForMeId } = action.payload;

      if (!doItForMeSubmissionPDF.submissionPDF) {
        const token: string = yield select(tokenSelector);
        const userCorrelateId = yield select(getCorrelateId);

        try {
          const base64PDF: string = yield call(() =>
            DoItForMeSDK.getSubmissionPDF(
              config.apiUrl,
              { userOrToken: token, correlateId: userCorrelateId },
              {
                doItForMeId,
              },
            ),
          );
          yield put(doItForMeSubmissionPDFSuccess(atob(base64PDF)));
        } catch (error) {
          const errorType: ResponseErrorType = yield* handleError(error);
          const message =
            errorType === ResponseErrorType.NoProviderAccess
              ? intlStringsRegistry.getMessage(`expert-review.${errorType}`)
              : errorType;
          yield put(doItForMeSubmissionPDFFailure());
          yield put(
            displayMessage({
              message,
              severity: MessageSeverity.Error,
            }),
          );
          yield put(reportError({ error } as { error: ResponseError }));
        }
      }
    },
  );
}

export function* createDoItForMeReview(): SagaIterator<void> {
  yield takeLatest(
    doItForMeCreateReviewRequest,
    function* createDoItForMeReview(
      action: PayloadAction<CreateReviewRequest>,
    ): SagaIterator<void> {
      const token: string = yield select(tokenSelector);
      const userCorrelateId = yield select(getCorrelateId);
      try {
        const response: GetReviewResponse = yield call(
          async () =>
            await DoItForMeSDK.createReview(
              config.apiUrl,
              {
                userOrToken: token,
                correlateId: userCorrelateId,
              },
              action.payload,
            ),
        );
        if (response?.id) {
          yield put(doItForMeCreateReviewSuccess(response));
        } else {
          throw new ResponseError(ResponseErrorType.Unknown);
        }
      } catch (error) {
        const errorType: ResponseErrorType = yield* handleError(error);
        yield put(doItForMeCreateReviewFailure(errorType));
        yield put(
          displayMessage({
            message: error.message,
            severity: MessageSeverity.Error,
          }),
        );
        yield put(reportError({ error }));
      }
    },
  );
}

export function* updateDoItForMeReview(): SagaIterator<void> {
  yield takeLatest(
    doItForMeUpdateReviewRequest,
    function* updateDoItForMeReview(
      action: PayloadAction<UpdateReviewRequest>,
    ): SagaIterator<void> {
      const token: string = yield select(tokenSelector);
      const userCorrelateId = yield select(getCorrelateId);

      try {
        const response: GetReviewResponse = yield call(
          async () =>
            await DoItForMeSDK.updateReview(
              config.apiUrl,
              {
                userOrToken: token,
                correlateId: userCorrelateId,
              },
              action.payload,
            ),
        );
        if (response?.id) {
          yield put(doItForMeUpdateReviewSuccess(response));
        } else {
          throw new ResponseError(ResponseErrorType.NotFound);
        }
      } catch (error) {
        const errorType: ResponseErrorType = yield* handleError(error);
        yield put(doItForMeUpdateReviewFailure(errorType));
        yield put(
          displayMessage({
            message: error.message,
            severity: MessageSeverity.Error,
          }),
        );
        yield put(reportError({ error }));
      }
    },
  );
}

export function* getSubmissionComments(): SagaIterator<void> {
  yield takeLatest(
    doItForMeSubmissionCommentsRequest,
    function* getSubmissionComments(): SagaIterator<void> {
      try {
        const token: string = yield select(tokenSelector);
        const correlateId: string = yield select(getCorrelateId);
        const doItForMeId: number = yield select(doItForMeIdSelector);
        const results: SubmissionComment[] = yield call(() =>
          DoItForMeSDK.getSubmissionComments(
            config.apiUrl,
            {
              userOrToken: token,
              correlateId,
            },
            {
              doItForMeId,
            },
          ),
        );
        yield put(submissionCommentsSuccess(results));
      } catch (error) {
        yield put(
          displayMessage({
            message: error.message,
            severity: MessageSeverity.Error,
          }),
        );
        yield put(submissionCommentsFailure({ error: error.message }));
      }
    },
  );
}

export function* autoUpdateDifmStatus(): SagaIterator {
  yield takeLatest(
    [
      doItForMeAutoUpdateToPreparation,
      submissionModifyOverride,
      submissionAddAdditional,
      submissionModifyOverrides,
      doItForMeUpdateReviewRequest,
      submissionDocumentUpdateDoItForMeRequest,
      submissionCommentsAddCommentRequest,
      submissionAddFieldsDE,
      templatesRequest,
      submissionDocumentUploadDoItForMeRequest,
    ],
    function* () {
      const allowAutoUpdate: boolean = yield select(
        allowDifmAutoUpdateToPreparationSelector,
      );
      if (allowAutoUpdate) {
        yield put(
          doItForMeNextStatus({
            nextStatus: DoItForMeStatus.Preparation,
          }),
        );
      }

      return;
    },
  );
}

export function* difmWithIdentificationApproved(): SagaIterator<void> {
  yield takeLatest(
    approvedIdentificationDoItForMeRequest,
    function* updateDoItForMeReview(
      action: PayloadAction<IdApprovedRequest>,
    ): SagaIterator<void> {
      const token: string = yield select(tokenSelector);
      const correlateId: string = yield select(getCorrelateId);
      const submissionId = action.payload.submissionId;
      try {
        if (!submissionId) {
          throw new Error('There is no submissionId related to this ID.');
        }

        const { doItForMe } = yield call(() =>
          DoItForMeSDK.getAll(
            config.apiUrl,
            {
              userOrToken: token,
              correlateId,
            },
            {
              submissionIds: [submissionId],
            },
          ),
        );

        if (!doItForMe.length) {
          throw new Error('Cannot find a DIFM assign to this submission.');
        }

        const difm = doItForMe[0];
        if (difm.status === DoItForMeStatus.WaitingForIdentification) {
          yield call(() =>
            DoItForMeSDKv4.update(
              config.apiUrl,
              {
                userOrToken: token,
                correlateId,
              },
              {
                id: difm.id,
                status: DoItForMeStatus.WaitingForPreparation,
              },
            ),
          );
        }
      } catch (error) {
        console.log('Error', error);
      }
    },
  );
}
