import React from 'react';
import styled, { css } from 'styled-components';

import { detectHotKeyPattern, patternsMap, swapKeyToSymbol } from './utils';

type ShortcutTextProps = {
  shortcut: string;
  variant?: 'compact' | 'normal';
};

const keyMixin = css`
  display: inline-block;
  text-transform: capitalize;
  border-radius: ${({ theme }): number => theme.shape.border.radius.small}px;
`;

const KeyNormal = styled.kbd`
  ${keyMixin};
  background: ${({ theme }): string => theme.palette.background.default};
  padding: ${({ theme }): string => theme.spacing(0.25, 1)};
  box-shadow: ${({ theme }): string => theme.shadows[1]};
`;

const KeyCompact = styled.kbd`
  ${keyMixin};
  box-shadow: none;
  font-family: sans-serif;
  padding: ${({ theme }): string => theme.spacing(0.25, 0.5)};
  background: ${({ theme }): string => theme.palette.grey[800]};
  color: ${({ theme }): string => theme.palette.grey[300]};
`;

const ShortcutText: React.FC<ShortcutTextProps> = ({
  shortcut,
  variant = 'normal',
}) => {
  const KeyComponent = variant === 'compact' ? KeyCompact : KeyNormal;
  const shortcutPattern = detectHotKeyPattern(shortcut, patternsMap);

  if (!shortcutPattern) {
    return <KeyComponent>{shortcut}</KeyComponent>;
  }

  const shortcutTokens = shortcut.split(shortcutPattern.separator);

  return (
    <kbd data-test-id="keyboard-shortcut">
      {variant === 'compact' ? (
        <KeyComponent>
          {swapKeyToSymbol(shortcut.replace('+', ''))}
        </KeyComponent>
      ) : null}
      {variant === 'normal'
        ? shortcutTokens.map((token, index) => (
            <React.Fragment key={`${shortcut}_${token}`}>
              <KeyComponent data-test-id="single-key">{token}</KeyComponent>
              {index !== shortcutTokens.length - 1
                ? shortcutPattern.textSeparator
                : null}
            </React.Fragment>
          ))
        : null}
    </kbd>
  );
};

export default ShortcutText;
