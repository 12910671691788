import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Services } from '../../components/submission/config';
import { intlStringsRegistry } from '../../intl';
import { SendEmailState } from './types';

const initialState: SendEmailState = {
  loading: false,
  error: undefined,
  success: undefined,
  serviceType: undefined,
};

const sendEmailSlice = createSlice({
  name: 'sendEmail',
  initialState: initialState,
  reducers: {
    documentsRequestRequest: (
      state,
      _: PayloadAction<{
        content: string | null;
        submissionId: number;
      }>,
    ): void => {
      state.loading = true;
      state.error = initialState.error;
      state.success = initialState.success;
    },
    difmDocumentsRequestRequest: (
      state,
      _: PayloadAction<{
        difmId: number;
        data: {
          message: string | null;
          isExternal: boolean;
        };
      }>,
    ): void => {
      state.loading = true;
      state.error = initialState.error;
      state.success = initialState.success;
    },
    documentsRequestSuccess: (
      state,
      // define type to be able to handle "extraReducer" in other slices (submission-comments)
      _: PayloadAction<{
        agentId: number;
        message: string;
        submissionId: number;
      }>,
    ): void => {
      state.loading = false;
      state.error = initialState.error;
      state.success = true;
    },
    documentsRequestFailure: (
      state,
      { payload }: PayloadAction<{ error: Error }>,
    ): void => {
      state.loading = false;
      state.success = initialState.success;
      state.error =
        payload?.error || intlStringsRegistry.getMessage('generic_error');
    },
    documentsRequestReset: (state): void => {
      state.loading = false;
      state.success = initialState.success;
      state.error = initialState.error;
    },
    documentsServiceTypeUpdate: (
      state,
      { payload }: PayloadAction<{ serviceType: Services | undefined }>,
    ): void => {
      state.serviceType = payload.serviceType;
    },
  },
});

export default sendEmailSlice;

export const {
  documentsRequestRequest,
  difmDocumentsRequestRequest,
  documentsRequestSuccess,
  documentsRequestFailure,
  documentsRequestReset,
  documentsServiceTypeUpdate,
} = sendEmailSlice.actions;
