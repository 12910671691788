import { reduxRegistry } from '..';
import slice from './messages';
import * as sagas from './sagas';

export * from './messages';
export * from './types';
export * from './selectors';

const { name, reducer } = slice;

reduxRegistry.registerReducer(name, reducer);
reduxRegistry.registerSagas(name, sagas);
