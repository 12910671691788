import { CountryCodes } from '@taxfix/types';

import { Services } from '../../components/submission/config';

export enum TemplateStatus {
  draft = 'draft',
  published = 'published',
  deleted = 'deleted',
}

export enum TemplateLanguages {
  de = 'de',
  en = 'en',
  it = 'it',
}

export interface TemplateType {
  id: string;
  title: string;
  content: string;
  authorId: number;
  lang: TemplateLanguages;
  status: TemplateStatus;
  countryCode: CountryCodes;
  createdAt: Date;
  description?: string;
  updatedBy?: string;
  updatedAt?: Date;
  deletedBy?: string;
  deletedAt?: Date;
  serviceType?: Services;
}

export interface TemplatesState {
  loading?: boolean;
  error?: Error;
  data: TemplateType[];
  type: string;
  success?: boolean;
}

export interface CreateTemplateFormData {
  lang: TemplateLanguages;
  title: string;
  content: string;
  countryCode?: CountryCodes;
  serviceType?: Services;
}
