import React, { ReactNode, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { intlStringsRegistry, locale, reportIntlError } from './index';

type IntlStringGateProps = {
  children: ReactNode;
};

export const IntlStringsGate: React.FC<IntlStringGateProps> = ({
  children,
}) => {
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    intlStringsRegistry.loadMessages().then(() => {
      setReady(true);
    });
  }, []);

  if (ready) {
    return (
      <IntlProvider
        locale={locale}
        defaultLocale="en"
        messages={intlStringsRegistry.getMessages()}
        onError={reportIntlError}
      >
        {children}
      </IntlProvider>
    );
  }

  return null;
};
