import { useSelector } from 'react-redux';

import { useMediaQuery } from '@taxfix/ui';

import { themeTypeSelector } from '../redux/app';

export const useDarkMode = (): boolean => {
  const systemDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const themeType = useSelector(themeTypeSelector);
  let userDarkMode = null;

  if (themeType) {
    userDarkMode = themeType === 'dark';
  }

  return userDarkMode ?? systemDarkMode;
};
