import { combineReducers, configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import createSentryMiddleware from 'redux-sentry-middleware';

import { reduxRegistry } from './registry';

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware, createSentryMiddleware(Sentry)];

if (!config.disableReduxLogger) {
  middleware.push(logger);
}

export const store = configureStore({
  reducer: state => state,
  middleware,
  devTools: config.reduxDevTools,
});

export type RootState = { [k: string]: unknown };

reduxRegistry.reducerChangeListener = (reducers): void => {
  store.replaceReducer(combineReducers(reducers));
};

reduxRegistry.sagaChangeListener = (sagas): void => {
  for (const saga of sagas) {
    sagaMiddleware.run(saga);
  }
};

export { reduxRegistry };
