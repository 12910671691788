import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { XMLtoTaxForm } from '@taxfix/it-tax-form-data-converter';
import { Namespaces } from '@taxfix/it-xpath-schema';
import { IT_Field } from '@taxfix/submissions-types';

import { SubmissionState, submissionSelector } from '.';
import { intlStringsRegistry } from '../../intl';
import { reportError } from '../errors';
import { remoteConfigSelector } from '../me';
import { MessageSeverity, displayMessage } from '../messages';
import {
  submissionUploadExternalCalculation,
  submissionUploadExternalCalculationFailure,
  submissionUploadExternalCalculationSuccess,
} from './submission';

const readAndTransformFile = async (externalXML: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const xmlReader = new FileReader();
    xmlReader.readAsText(externalXML);
    xmlReader.onload = (): void => resolve(xmlReader.result as string);
    xmlReader.onerror = (error): void => reject(error);
  });

const shouldBeUpdated = (
  originalValue: string | number | undefined,
  importedValue: string | number | undefined,
): boolean => {
  const originalParsed =
    !isNaN(originalValue as number) && typeof originalValue === 'string'
      ? Number(originalValue)
      : originalValue;

  const importedParsed =
    !isNaN(importedValue as number) && typeof importedValue === 'string'
      ? Number(importedValue)
      : importedValue;

  if (
    typeof originalParsed === 'number' &&
    typeof importedParsed === 'number'
  ) {
    // If values are decimals, only update if the difference is > 0.5
    return Math.abs(originalParsed - importedParsed) > 0.5;
  }

  return originalParsed !== importedParsed;
};

type UploadExternalCalculationProps = {
  externalXML: File;
  ignoredPrefixXmlZucchettiImport: string;
  submission: SubmissionState;
};
const uploadExternalCalculation =
  ({
    externalXML,
    ignoredPrefixXmlZucchettiImport,
    submission,
  }: UploadExternalCalculationProps) =>
  async (): Promise<SubmissionState['overrides']> => {
    const ignoredPrefix: string[] = JSON.parse(ignoredPrefixXmlZucchettiImport);
    const importedXML = await readAndTransformFile(externalXML);
    const importedFieldsFromXML = XMLtoTaxForm(Namespaces.m730, importedXML);

    const submissionData = submission.data as IT_Field[];
    const overrides = [...submission.overrides.overrides];
    const additionalFields = [...submission.overrides.additionalFields];
    const additionalOverrides = [...submission.overrides.additionalOverrides];

    importedFieldsFromXML.forEach(importedField => {
      const { id } = importedField.coordinate;

      // Avoid updating unexpected fields:
      const shouldBeIgnored = ignoredPrefix.filter((prefix: string) =>
        id.includes(prefix),
      );
      if (shouldBeIgnored.length) {
        return;
      }

      // Find index in submission data
      const fieldIndex = submissionData.findIndex(
        field => field.coordinate.id === importedField.coordinate.id,
      );

      // Imported field does not exist in submission. Add it as additionalFields
      if (fieldIndex === -1) {
        const fieldIndexInAdditionalFields = additionalFields.findIndex(
          field =>
            field.coordinate.id === importedField.coordinate.id &&
            field.coordinate?.index === importedField.coordinate?.index,
        );

        // Validates if field was already created in additionalFields.
        // If not, add the corresponding entries in additionalOverrides
        if (fieldIndexInAdditionalFields === -1) {
          additionalFields.push({
            value: '',
            coordinate: importedField.coordinate,
          });

          additionalOverrides.push({
            value: importedField.value,
            override: true,
            deleted: false,
            index: importedField.coordinate?.index,
          });
          return;
        }

        // If field is already an additionalField, only update the additionalOverrides
        if (
          shouldBeUpdated(
            additionalOverrides[fieldIndexInAdditionalFields].value,
            importedField.value,
          )
        ) {
          additionalOverrides[fieldIndexInAdditionalFields] = {
            ...additionalOverrides[fieldIndexInAdditionalFields],
            value: importedField.value,
            override: true,
            deleted: false,
            index: importedField.coordinate?.index,
          };
        }
        return;
      }

      // Field exists in submission, only update if value contains a change:
      if (
        shouldBeUpdated(
          submissionData[fieldIndex].value,
          importedField.value,
        ) &&
        shouldBeUpdated(overrides[fieldIndex].value, importedField.value)
      ) {
        overrides[fieldIndex] = {
          ...overrides[fieldIndex],
          value: importedField.value,
          override: true,
        };
        return;
      }
    });

    return {
      overrides,
      additionalFields,
      additionalOverrides,
    };
  };

export function* importZucchettiFile(): SagaIterator {
  yield takeEvery(
    submissionUploadExternalCalculation,
    function* ({ payload: { file } }: PayloadAction<{ file: File }>) {
      try {
        const config = yield select(remoteConfigSelector);
        const submission = yield select(submissionSelector);

        const { overrides, additionalFields, additionalOverrides } = yield call(
          uploadExternalCalculation({
            externalXML: file,
            ignoredPrefixXmlZucchettiImport:
              config.ignoredPrefixXmlZucchettiImport._value,
            submission,
          }),
        );

        yield put(
          submissionUploadExternalCalculationSuccess({
            overrides: {
              overrides,
              additionalFields,
              additionalOverrides,
            },
          }),
        );

        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'it.submission.action.zucchetti.success',
            ),
            severity: MessageSeverity.Success,
          }),
        );
      } catch (err) {
        yield put(reportError({ error: err, showDialog: true }));
        yield put(submissionUploadExternalCalculationFailure());
        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'it.submission.action.zucchetti.error',
            ),
            severity: MessageSeverity.Error,
          }),
        );
      }
    },
  );
}
