// helper types
/**
 * extract the key names of any object as a union type
 */
export type Keys<T> = keyof T;

/**
 * Extract all the value types of an object's keys as an union type
 */
export type TypeValue<T> = T[Keys<T>];

/**
 * Extract the type of an element in an array type
 */
export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType[number];

// tax-specific types
export type JobTitles = {
  A: string;
  B?: string;
};

export type SectionStateSupportedIconType =
  | 'submission'
  | 'identification'
  | 'payment'
  | 'reviewStatus';

export enum LoginErrors {
  HasPopUpsBlocked = 'has_pop_up_blocked',
  IsNotLoggedInGoogle = 'is_not_logged_in_google',
  MissingEmail = 'missing_email',
  MissingToken = 'missing_token',
  Other = 'other',
  Unauthorized = 'unauthorized',
}

export enum IdentificationTypes {
  Identification = 1,
  Document = 2,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFunction = (...args: any) => any;

export type AsyncReturnType<T extends AnyFunction> =
  ReturnType<T> extends Promise<infer P> ? P : never;

export enum IdentificationStates {
  pending = 1,
  accepted = 2,
  declined = 3,
  archived = 4,
}

export type SupportedYears = typeof config.supportedYears[number];
