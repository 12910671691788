import React, { useState } from 'react';
import {
  ErrorBoundaryPropsWithRender,
  ErrorBoundary as ReactErrorBoundary,
} from 'react-error-boundary';
import { useDispatch } from 'react-redux';

import { reportError } from '../../../redux/errors';
import { DefaultErrorScreen } from '../error-screen';

type ErrorBoundaryProps = React.PropsWithChildren<
  Partial<ErrorBoundaryPropsWithRender>
>;

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  children,
  onError,
  fallbackRender = null,
  ...rest
}) => {
  const [windowAboutToReload, setWindowAboutToReload] = useState(false);
  const dispatch = useDispatch();

  const defaultFallbackRender = (): JSX.Element => <DefaultErrorScreen />;
  const fallback = fallbackRender ?? defaultFallbackRender;

  const reloadAwareFallback = windowAboutToReload ? (): null => null : fallback;

  const handleComponentError = (error: Error, componentStack: string): void => {
    console.log('Redux state at error:');

    if (error.name === 'ChunkLoadError') {
      setWindowAboutToReload(true);
      window.location.reload();
      return;
    }

    onError?.(error, componentStack);
    const reportErrorAction = reportError({
      error,
      meta: { stack: componentStack },
      showDialog: true,
    });
    dispatch(reportErrorAction);
  };

  return (
    <ReactErrorBoundary
      onError={handleComponentError}
      fallbackRender={reloadAwareFallback}
      {...rest}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
