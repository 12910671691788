import { DocumentData, QuerySnapshot } from '@firebase/firestore-types';
import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { templatesCollection } from '../../firebase';
import { intlStringsRegistry } from '../../intl';
import { reportError } from '../errors';
import { agentIdSelector } from '../me';
import { MessageSeverity, displayMessage } from '../messages';
import { typeSelector } from './selectors';
import {
  templateCreateRequest,
  templateCreateRequestFailure,
  templateCreateRequestSuccess,
  templateDeleteRequest,
  templateDeleteRequestFailure,
  templateDeleteRequestSuccess,
  templateUpdateRequest,
  templateUpdateRequestFailure,
  templateUpdateRequestSuccess,
  templatesRequest,
  templatesRequestFailure,
  templatesRequestSuccess,
} from './templates';
import { CreateTemplateFormData, TemplateStatus, TemplateType } from './types';
import { templateComposer } from './utils';

// GET
export function* getTemplates(): SagaIterator {
  yield takeLatest(templatesRequest, function* (action: PayloadAction<string>) {
    try {
      const templates: TemplateType[] = [];
      const collection: string = action.payload;
      const response: QuerySnapshot = yield call(() =>
        templatesCollection
          .doc(collection)
          .collection('data')
          .where('status', '==', TemplateStatus.published)
          .get(),
      );
      response.docs.forEach((doc: DocumentData) =>
        templates.push({ ...doc.data(), id: doc.id }),
      );
      yield put(templatesRequestSuccess(templates));
    } catch (error) {
      yield put(templatesRequestFailure({ error }));
      yield put(
        displayMessage({
          message: intlStringsRegistry.getMessage(
            'de.submission.removeReason.templates.get.error',
          ),
          severity: MessageSeverity.Error,
        }),
      );
      yield put(reportError({ error }));
    }
  });
}

// POST
export function* createTemplate(): SagaIterator {
  yield takeLatest(
    templateCreateRequest,
    function* (action: PayloadAction<CreateTemplateFormData>) {
      try {
        const agentId: number = yield select(agentIdSelector);
        const collection: string = yield select(typeSelector);
        const template = templateComposer(action.payload, agentId);
        const response: DocumentData = yield call(() =>
          templatesCollection.doc(collection).collection('data').add(template),
        );

        yield put(
          templateCreateRequestSuccess({ id: response.id, ...template }),
        );
      } catch (error) {
        yield put(templateCreateRequestFailure({ error }));
        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'de.submission.removeReason.templates.post.error',
            ),
            severity: MessageSeverity.Error,
          }),
        );
        yield put(reportError({ error }));
      }
    },
  );
}

// PUT
export function* updateTemplate(): SagaIterator {
  yield takeLatest(
    templateUpdateRequest,
    function* ({
      payload,
    }: PayloadAction<{ id: string | null; formData: CreateTemplateFormData }>) {
      try {
        const agentId: number = yield select(agentIdSelector);
        const collection: string = yield select(typeSelector);
        const {
          id,
          formData: { title, content, lang },
        } = payload;
        yield call(() =>
          templatesCollection
            .doc(collection)
            .collection('data')
            .doc(id)
            .update({
              title,
              lang,
              content,
              updatedBy: agentId,
              updatedAt: new Date(),
            }),
        );
        yield put(templateUpdateRequestSuccess(payload));
      } catch (error) {
        yield put(templateUpdateRequestFailure({ error }));
        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'de.submission.removeReason.templates.put.error',
            ),
            severity: MessageSeverity.Error,
          }),
        );
        yield put(reportError({ error }));
      }
    },
  );
}

// DELETE
export function* deleteTemplate(): SagaIterator {
  yield takeLatest(
    templateDeleteRequest,
    function* ({ payload }: PayloadAction<{ id: string }>) {
      try {
        const agentId: number = yield select(agentIdSelector);
        const collection: string = yield select(typeSelector);
        yield call(() =>
          templatesCollection
            .doc(collection)
            .collection('data')
            .doc(payload.id)
            .update({
              status: TemplateStatus.deleted,
              deletedBy: agentId,
              deletedAt: new Date(),
            }),
        );
        yield put(templateDeleteRequestSuccess(payload));
      } catch (error) {
        yield put(templateDeleteRequestFailure({ error }));
        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'de.submission.removeReason.templates.delete.error',
            ),
            severity: MessageSeverity.Error,
          }),
        );
        yield put(reportError({ error }));
      }
    },
  );
}
