import { reduxRegistry } from '..';
import * as sagas from './sagas';
import slice from './submission-documents';

export * from './submission-documents';
export * from './types';
export * from './selectors';

const { name, reducer } = slice;

reduxRegistry.registerReducer(name, reducer);
reduxRegistry.registerSagas(name, sagas);
