import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { delay, put, spawn, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { addMessage, displayMessage, removeMessage } from './messages';
import { MessageSeverity, PostMessage } from './types';

const getTimeoutWithDefaults = (
  timeout: number | undefined,
  messageSeverity: MessageSeverity,
): number | undefined => {
  if (timeout !== undefined) {
    return timeout;
  }

  switch (messageSeverity) {
    case MessageSeverity.Error:
      return 5000;
    default:
      return 4000;
  }
};

export function* postMessages(): SagaIterator {
  yield takeEvery(
    displayMessage,
    function* ({
      payload: { timeout, ...message },
    }: PayloadAction<PostMessage>) {
      const id = uuidv4();
      yield put(addMessage({ id, ...message }));

      const timeoutWithDefaults = getTimeoutWithDefaults(
        timeout,
        message.severity,
      );

      if (timeoutWithDefaults) {
        yield spawn(function* () {
          yield delay(timeoutWithDefaults);
          yield put(removeMessage(id));
        });
      }
    },
  );
}
