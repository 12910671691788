import { persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/es/storage';

import { reduxRegistry } from '..';
import slice from './app';

export * from './app';
export * from './types';
export * from './selectors';

const persistConfig = {
  key: 'app',
  storage: localStorage,
  version: 0,
};

const { name, reducer } = slice;

reduxRegistry.registerReducer(name, persistReducer(persistConfig, reducer));
