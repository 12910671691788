import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { animated, useTransition } from 'react-spring';
import styled from 'styled-components';

import { Typography } from '@taxfix/ui';
import { Alert } from '@taxfix/ui/lab';

import { messagesSelector, removeMessage } from '../../redux/messages';

const StyledAlert = styled(Alert)`
  margin-bottom: ${({ theme }): string => theme.spacing(2)};
  ${({ theme, severity }): string =>
    severity ? 'color: ' + theme.palette[severity].contrastText : ''};

  & .MuiAlert-icon {
    align-items: center;
  }
`;

const FixedWidth = styled(animated.div)`
  width: 405px;
`;

const SnackbarArea = styled.div`
  position: fixed;
  top: ${({ theme }): string => theme.spacing(1)};
  right: ${({ theme }): string => theme.spacing(3)};
  z-index: ${({ theme }): number => theme.zIndex.tooltip};
`;

const Messages: React.FC = () => {
  const messages = useSelector(messagesSelector);
  const dispatch = useDispatch();

  const transitions = useTransition(messages, ({ id }) => id, {
    from: { opacity: 0, maxHeight: 0 },
    // This is a "good enough" approach to make this work without knowing
    // each item's exact height. The perfect implementation would calculate
    // each message's height and transition to it.
    enter: { opacity: 1, maxHeight: 400 },
    leave: { opacity: 0, maxHeight: 0 },
  });

  return (
    <SnackbarArea>
      {transitions.map(({ item: { message, severity, id }, props }) => (
        <FixedWidth style={props} key={id}>
          <StyledAlert
            elevation={6}
            variant="filled"
            severity={severity}
            icon={false}
            onClose={(): ReturnType<typeof dispatch> =>
              dispatch(removeMessage(id))
            }
          >
            <Typography>{message}</Typography>
          </StyledAlert>
        </FixedWidth>
      ))}
    </SnackbarArea>
  );
};

export default Messages;
