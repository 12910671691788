import { ResponseError } from '@taxfix/taxfix-sdk/dist/request';

// TODO Fix types on taxfix-sdk ResponseError
/* eslint-disable @typescript-eslint/no-explicit-any */
export const transformResponseError = (
  error: ResponseError,
): {
  response: {
    headers: any;
    body: any;
  };
  request: {
    headers: any;
    url: any;
    method: any;
  };
} => ({
  // TODO: Remove when fixing src/request.ts.
  // TODO: unknown type [taxfix-sdk@2.81.0].
  response: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers: error.response.headers,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    body: error.response.data,
  },
  request: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers: error.response.config.headers,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    url: error.response.config.url,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    method: error.response.config.method,
  },
});
/* eslint-enable @typescript-eslint/no-explicit-any */

export const isSessionExpiredError = (error: ResponseError): boolean => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    error.response.status === 403 &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    error.response.data.message === 'not_logged_in'
  );
};
