import {
  v2 as DoItForMeSDK,
  v3 as DoItForMeSdkV3,
} from '@taxfix/do-it-for-me-sdk';
import {
  GetAllDocumentsRequest,
  GetAllDocumentsResponse,
} from '@taxfix/do-it-for-me-sdk/dist/v2/documents/get-all-documents';
import {
  GetDocumentRequest,
  GetDocumentResponse,
} from '@taxfix/do-it-for-me-sdk/dist/v3/documents/get-document';
import { getOne } from '@taxfix/documents-sdk/dist/api/v1';
import { Document } from '@taxfix/documents-sdk/dist/types/v1/documents';
import {
  GetDocumentsResponse,
  GetOneDocumentsRequest,
} from '@taxfix/documents-sdk/dist/types/v1/get-one';

import { ResponseError, ResponseErrorType } from '../redux/common';

type FetchBlobDocuments = (
  token: string,
  data: GetOneDocumentsRequest,
) => Promise<Blob>;

export const fetchBlobDocuments: FetchBlobDocuments = async (token, data) => {
  return getOne(config.apiUrl, token, { ...data, getFileContents: true }).then(
    (res): Blob => {
      if (data.acceptHeader === 'application/json') {
        return new Blob([JSON.stringify(res.data)], {
          type: data.acceptHeader,
        });
      }

      if (res.data instanceof ArrayBuffer) {
        return new Blob([res.data], { type: data.acceptHeader });
      }

      throw new ResponseError(ResponseErrorType.Unknown);
    },
  );
};

export const handleDocsWithBlob = (
  docsChunk: Document[],
  token: string,
): Promise<Document>[] => {
  return docsChunk.map(doc => {
    const { id: documentId, metadata: rawMetadata } = doc;
    const metadata =
      typeof rawMetadata === 'string' ? JSON.parse(rawMetadata) : rawMetadata;
    const { contentType } = metadata;
    return fetchBlobDocuments(token, {
      id: documentId,
      acceptHeader: contentType,
    }).then(
      blob => {
        const path = URL.createObjectURL(blob);
        return {
          ...doc,
          path,
        };
      },
      error => {
        console.warn(error, `One document couldn't be downloaded: ${doc.id}`);
        return { ...doc };
      },
    );
  });
};

type FetchBlobDocumentsDIFM = (
  token: string,
  userCorrelateId: string,
  data: {
    difmId: number;
    documentId: number;
  },
  acceptHeader: string,
) => Promise<Blob>;

const fetchBlobDocumentsDIFM: FetchBlobDocumentsDIFM = async (
  token,
  userCorrelateId,
  data,
  acceptHeader,
) => {
  return DoItForMeSdkV3.getDocument(
    config.apiUrl,
    {
      userOrToken: token,
      correlateId: userCorrelateId,
    },
    { ...data, acceptHeader },
  ).then((res: GetDocumentResponse): Blob => {
    const blob = new Blob([res as ArrayBuffer], { type: acceptHeader });
    return blob;
  });
};

export const fetchDifmDocuments = async (
  token: string,
  userCorrelateId: string,
  params: GetAllDocumentsRequest,
): Promise<GetAllDocumentsResponse> => {
  const docs = await DoItForMeSDK.getAllDocuments(
    config.apiUrl,
    { userOrToken: token, correlateId: userCorrelateId },
    params,
  );

  return {
    data: docs.data.map(d => ({ ...d, id: d.documentId })),
    total: docs.total,
  };
};

export const fetchDifmDocument = async (
  token: string,
  userCorrelateId: string,
  params: GetDocumentRequest,
): Promise<GetDocumentResponse> => {
  const response = await DoItForMeSdkV3.getDocument(
    config.apiUrl,
    { userOrToken: token, correlateId: userCorrelateId },
    params,
  );

  return response;
};

export const fetchDocument = async (
  token: string,
  params: GetOneDocumentsRequest,
): Promise<GetDocumentsResponse> => {
  const response = await getOne(config.apiUrl, token, params);

  return response.data;
};

export const getDocumentObjectURL = ({
  documentId,
  token,
  contentType,
}: {
  documentId: number;
  contentType: string;
  token: string;
}) => {
  return fetchBlobDocuments(token, {
    id: documentId,
    acceptHeader: contentType,
  } as Required<GetOneDocumentsRequest>).then(
    blob => {
      const path = URL.createObjectURL(blob);
      return path;
    },
    error => {
      console.warn(error, `One document couldn't be downloaded: ${documentId}`);
      return '';
    },
  );
};

export const getDIFMDocumentObjectURL = ({
  documentId,
  contentType,
  token,
  userCorrelateId,
  difmId,
}: {
  documentId: number;
  contentType: string;
  token: string;
  userCorrelateId: string;
  difmId: number;
}): Promise<string> => {
  return fetchBlobDocumentsDIFM(
    token,
    userCorrelateId,
    {
      difmId,
      documentId,
    },
    contentType,
  ).then(
    blob => {
      const path = URL.createObjectURL(blob);
      return path;
    },
    error => {
      console.warn(error, `One document couldn't be downloaded: ${documentId}`);
      return '';
    },
  );
};
