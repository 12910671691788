import { call, select } from 'redux-saga/effects';

import { AllocationSdk } from '@taxfix/operations-sdk';
import { GetAllResponse } from '@taxfix/operations-sdk/dist/allocation/types';
import { CountryCodes } from '@taxfix/types';

import {
  RemoteConfigValues,
  getCurrentCountry,
  remoteConfigSelector,
  tokenSelector,
} from '../redux/me';

export function* getDIFMReadyAtTimestamp(resourceId: string) {
  const remoteConfig: RemoteConfigValues = yield select(remoteConfigSelector);
  const countryCode: CountryCodes = yield select(getCurrentCountry);
  const enableAllocationDIFM =
    remoteConfig?.enableAllocationDIFM?.asBoolean() ?? false;
  const token: string = yield select(tokenSelector);

  if (enableAllocationDIFM && countryCode === CountryCodes.DE) {
    try {
      const task: GetAllResponse = yield call(() =>
        AllocationSdk.getAll(config.apiUrl, token, {
          resourceId: resourceId,
        }),
      );
      return task.data[0]?.readyAt;
    } catch (error) {
      // TODO: Enable this after readyAt script is run
      /*console.error(
        `Error getting readyAt timestamp for submission ${resourceId}`,
      );*/
      return undefined;
    }
  }
}
