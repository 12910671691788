import { format } from 'date-fns';
import { SagaIterator } from 'redux-saga';
import { StrictEffect, put, take } from 'redux-saga/effects';

import { reportError } from './errors';
import { ExpertReview } from './expert-reviews';
import { InstantRefund } from './instant-refunds';
import { tokenCheckFinished, tokenCheckRequest } from './me';
import { Payment } from './payments';
import { UserHistory } from './user';

type ItemsToFormat = UserHistory | Payment | InstantRefund | ExpertReview;

type KeysWhenValueIsString<T> = {
  [K in keyof T]: T[K] extends string ? K : never;
}[keyof T];

const cast = <T>(value: unknown): T => value as T;

export const formatDateString = (date: string): string =>
  format(new Date(date), 'DD.MM.YYYY');

export const formatDateHourString = (date: string): string =>
  format(new Date(date), 'HH:mm');

export const formatDate = <T extends ItemsToFormat>(
  array: T[],
  property: KeysWhenValueIsString<T>,
): T[] => {
  const formattedArray = [...array];
  return formattedArray.map((element: T) => {
    const formattedDate = formatDateString(String(element[property]));

    // The type T[KeysWhenValueIsString<T>] should be string, but typescript seems to disagree 🤷🏻‍♀️
    element[property] = cast<T[KeysWhenValueIsString<T>]>(formattedDate);
    return element;
  });
};

export enum ResponseErrorType {
  NotFound = 'not-found',
  Forbidden = 'forbidden',
  BadRequest = 'bad-request',
  Conflict = 'conflict',
  Unknown = 'unknown',
  ServerConflict = 'prefills.prefill_is_not_ready',
  NoProviderAccess = 'no_provider_expert_reviews_access',
  ExpertReviewDeleted = 'expert_review_deleted',
  UnprocessableEntity = 'unprocessable_entity',
  UnreviewedDocsToSubmit = 'unreviewed_docs_for_submission',
  OutdatedDocument = 'outdated-document',
}

export function* handleError(
  error: ResponseError,
): SagaIterator<ResponseErrorType> & Iterable<StrictEffect> {
  const { status = 500, data } = error?.response ?? {};

  switch (status) {
    case 403:
      yield put(tokenCheckRequest());
      yield take(tokenCheckFinished);
      return data?.message === ResponseErrorType.NoProviderAccess
        ? ResponseErrorType.NoProviderAccess
        : ResponseErrorType.Forbidden;
    case 404:
      return ResponseErrorType.NotFound;
    case 409:
      if (data?.message === ResponseErrorType.UnreviewedDocsToSubmit) {
        return ResponseErrorType.UnreviewedDocsToSubmit;
      }
      return ResponseErrorType.ServerConflict;
    case 422:
      return data?.message === ResponseErrorType.ExpertReviewDeleted
        ? ResponseErrorType.ExpertReviewDeleted
        : ResponseErrorType.UnprocessableEntity;
    default:
      yield put(reportError({ error }));
      return ResponseErrorType.Unknown;
  }
}

export class ResponseError extends Error {
  response = {
    status: 500,
    data: {
      message: '',
    },
  };
  name = 'UnkownError';

  constructor(error: ResponseErrorType) {
    super(error);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ResponseError);
    }

    switch (error) {
      case ResponseErrorType.NotFound:
        this.response.status = 404;
        this.name = 'NotFoundError';
        break;
      case ResponseErrorType.Forbidden:
        this.response.status = 403;
        this.name = 'ForbiddenError';
        break;
    }
  }
}
