import { createSelector } from '@reduxjs/toolkit';

import { Documents } from '@taxfix/types';

import {
  DocumentData,
  SubmissionDocumentsGroupedByType,
  SubmissionDocumentsState,
  SubmissionDocumentsStateSummary,
} from './types';

export const submissionDocumentsSelector = ({
  submissionDocuments,
}: {
  submissionDocuments: SubmissionDocumentsState;
}): SubmissionDocumentsState => submissionDocuments;

export const submissionDocumentsErrorSelector = createSelector(
  submissionDocumentsSelector,
  documents => documents.error,
);

export const submissionDocumentsLoadingSelector = createSelector(
  submissionDocumentsSelector,
  ({ loading }) => loading,
);

const submissionDocumentsRawDataSelector = createSelector(
  submissionDocumentsSelector,
  ({ data }) => data,
);

export const submissionDocumentsDataSelector = createSelector(
  submissionDocumentsRawDataSelector,
  data =>
    data.map(
      (document): DocumentData => ({
        id: document.id,
        title: document.metadata.originalName || document.id.toString(),
        year: document.year,
        state: document.state,
        type: document.type,
        isPartnerDocument: document?.isPartnerDocument,
        metadata: document.metadata,
        thumbnail: document.thumbnail,
      }),
    ),
);

export const submissionDocumentUpdatingSelector = createSelector(
  submissionDocumentsSelector,
  ({ updating }) => updating,
);

export const submissionDocumentUploadingSelector = createSelector(
  submissionDocumentsSelector,
  ({ uploading }) => uploading,
);

export const submissionDocumentsURLsSelector = createSelector(
  submissionDocumentsSelector,
  state => state.urls,
);

export const submissionDocumentURLPathSelectorCreator = (docId: number) =>
  createSelector(submissionDocumentsURLsSelector, urls => {
    return urls[docId]?.url;
  });

export const submissionDocumentsStateSummarySelector = createSelector(
  submissionDocumentsDataSelector,
  documents => {
    const defaultSummary: SubmissionDocumentsStateSummary = Object.values(
      Documents.States,
    ).reduce((prev, curr) => ({ ...prev, [curr]: 0 }), {});
    const summary = documents.reduce((prev, curr) => {
      const docState = curr.state;
      prev[docState] = (prev[docState] || 0) + 1;
      return prev;
    }, defaultSummary);
    delete summary[Documents.States.Deleted];
    return summary;
  },
);

export const submissionDocumentsGroupedByCategorySelector = createSelector(
  submissionDocumentsDataSelector,
  documents => {
    const groupedDoc: SubmissionDocumentsGroupedByType = {};
    documents
      .filter(document => document.state !== Documents.States.Deleted)
      .forEach(document => {
        const { type } = document;

        groupedDoc[type] = [...(groupedDoc[type] ?? []), document];
      });
    return groupedDoc;
  },
);
