import { OutputSelector, createSelector } from '@reduxjs/toolkit';

import { AppSettings, AppState, SettingsKeys, ThemeType } from './types';

export const appStateSelector = ({ app }: { app: AppState }): AppState => app;

type SettingsSelector<K extends SettingsKeys> = OutputSelector<
  { app: AppState },
  Pick<AppSettings, K>,
  (state: AppSettings) => Pick<AppSettings, K>
>;

export const createSettingsSliceSelector = <K extends SettingsKeys>(
  keys: K[],
): SettingsSelector<K> =>
  createSelector([appStateSelector], settings =>
    keys.reduce(
      (partialSettings, key) => ({
        ...partialSettings,
        [key]: settings[key],
      }),
      {} as Pick<AppSettings, K>,
    ),
  );

export const themeTypeSelector = ({
  app,
}: {
  app: AppState;
}): ThemeType | undefined => app.themeType;
