import React from 'react';
import { useSelector } from 'react-redux';

import { CountryCodes } from '@taxfix/types';

import { getCurrentCountry } from '../../redux/me';
import { identificationActionsKeyMap } from '../identification/keymap';
import {
  navigationKeyMapDe,
  navigationKeyMapIt,
  submissionActionsKeyMap,
} from '../submission/keymap';
import Help from './help';

type HelpContainerProps = {
  visible: boolean;
  onDismiss: (event: object) => void;
};

const HelpContainer: React.FC<HelpContainerProps> = ({
  visible,
  onDismiss,
}) => {
  const country = useSelector(getCurrentCountry);
  const submissionNavKeyMap =
    country === CountryCodes.DE ? navigationKeyMapDe : navigationKeyMapIt;
  const shortcutsSections = [
    {
      title: 'Navigating within a submission',
      keyMap: submissionNavKeyMap,
    },
    {
      title: 'Submission actions',
      keyMap: submissionActionsKeyMap,
    },
    {
      title: 'Identification actions',
      keyMap: identificationActionsKeyMap,
    },
    {
      title: 'Tables',
      keyMap: {
        openInBackground: {
          sequence: 'command+click',
          name: 'table.open_in_background_tab',
        },
      },
    },
  ];
  return (
    <Help open={visible} onClose={onDismiss} shortcuts={shortcutsSections} />
  );
};

export default HelpContainer;
