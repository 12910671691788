type HotKeyPattern = {
  pattern: RegExp;
  separator: string;
  textSeparator: string;
  test: (str: string) => boolean;
};

type HotKeyPatternMap = {
  [key: string]: HotKeyPattern;
};

const createPattern = (
  partialPattern: Omit<HotKeyPattern, 'test'>,
): HotKeyPattern => ({
  ...partialPattern,
  test(str: string): boolean {
    return Boolean(str.match(this.pattern));
  },
});

type KeySymbolsMap = {
  universal: { [key: string]: string };
  macOS: { [key: string]: string };
};

const keySymbols: KeySymbolsMap = {
  universal: {
    shift: '⇧',
    up: '↑',
    right: '→',
    down: '↓',
    left: '←',
    tab: '⇥',
    backspace: '⟵',
    enter: '↵',
  },
  macOS: {
    meta: '⌘', // command key
    command: '⌘', // command key
    ctrl: '⌃',
    alt: '⌥', // option symbol
    option: '⌥', // option symbol
    backspace: '⌫',
    capslock: '⇪',
  },
};

export const patternsMap: HotKeyPatternMap = {
  sequence: createPattern({
    pattern: /^(([^\s+])+(\s?))+$/gim,
    separator: ' ',
    textSeparator: ' then ',
  }),
  combo: createPattern({
    pattern: /^(\S+)(\++)(\S*)$/gim,
    separator: '+',
    textSeparator: '+',
  }),
};

export const detectHotKeyPattern = (
  shortcut: string,
  map: HotKeyPatternMap,
): HotKeyPattern | undefined => {
  return Object.values(map).find(pattern => pattern.test(shortcut));
};

export const swapKeyToSymbol = (key: string): string => {
  let symbolHotKey = key.slice();
  let symbolMap = { ...keySymbols.universal };
  const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);

  if (isMacLike) {
    symbolMap = { ...symbolMap, ...keySymbols.macOS };
  }

  Object.entries(symbolMap).forEach(([keyDescription, keySymbol]) => {
    const hotkeyContainsKey = key.includes(keyDescription);

    if (hotkeyContainsKey) {
      symbolHotKey = symbolHotKey.replace(keyDescription, keySymbol);
    }
  });

  return symbolHotKey;
};
