import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Typography } from '@taxfix/ui';

import { KeyMapOptions } from '../../../hooks/use-hotkeys-map';
import ShortcutText from '../shortcut-text';

const ShortcutsDefList = styled.dl`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem 1rem;
  grid-auto-flow: row dense;
`;

const ShortcutName = styled.dt`
  grid-column: 2;
`;

const ShortcutKeySequence = styled.dd`
  grid-column: 1;
  margin: 0;
`;

export type ShortcutsListProps = {
  shortcuts: KeyMapOptions[];
};

const ShortcutsList: React.FC<ShortcutsListProps> = ({ shortcuts }) => {
  const { formatMessage } = useIntl();
  const format = useCallback(
    (id: string): string => formatMessage({ id: `shortcut.${id}` }),
    [formatMessage],
  );
  return (
    <ShortcutsDefList>
      {shortcuts.map(shortcut => (
        <React.Fragment key={JSON.stringify(shortcut.sequence)}>
          <ShortcutName>
            {shortcut.name && (
              <Typography variant="body1">{format(shortcut.name)}</Typography>
            )}
          </ShortcutName>
          {Array.isArray(shortcut.sequence) ? (
            shortcut.sequence.map(seq => (
              <ShortcutKeySequence key={`${shortcut.sequence}__${seq}`}>
                <ShortcutText shortcut={seq} />
              </ShortcutKeySequence>
            ))
          ) : (
            <ShortcutKeySequence>
              <ShortcutText shortcut={shortcut.sequence} />
            </ShortcutKeySequence>
          )}
        </React.Fragment>
      ))}
    </ShortcutsDefList>
  );
};

export default ShortcutsList;
