import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { v2 as DoItForMeSDK } from '@taxfix/do-it-for-me-sdk';
import { TaxminSdk } from '@taxfix/operations-sdk';
import { InteractionId, InteractionTypes } from '@taxfix/operations-types';

import { intlStringsRegistry } from '../../intl';
import { doItForMeIdSelector } from '../difm';
import {
  MeState,
  agentIdSelector,
  getCorrelateId,
  meSelector,
  tokenSelector,
} from '../me';
import { MessageSeverity, displayMessage } from '../messages';
import {
  submissionCommentsAddCommentFailure,
  submissionCommentsAddCommentRequest,
  submissionCommentsAddCommentSuccess,
  submissionCommentsEditCommentFailure,
  submissionCommentsEditCommentRequest,
  submissionCommentsEditCommentSuccess,
  submissionCommentsFailure,
  submissionCommentsReplyCommentSuccess,
  submissionCommentsRequest,
  submissionCommentsSuccess,
} from './submission-comments';
import { SubmissionComment } from './types';

export function* getSubmissionComments(): SagaIterator {
  yield takeLatest(
    submissionCommentsRequest,
    function* ({ payload: { submissionId } }) {
      try {
        const token: string = yield select(tokenSelector);
        const results: Array<SubmissionComment> = yield call(() =>
          TaxminSdk.getSubmissionMessages(config.apiUrl, token, {
            id: submissionId,
          }),
        );
        yield put(submissionCommentsSuccess(results));
      } catch (err) {
        yield put(
          displayMessage({
            message: err.message,
            severity: MessageSeverity.Error,
          }),
        );
        yield put(submissionCommentsFailure({ error: err.message }));
      }
    },
  );
}

export function* createSubmissionComment(): SagaIterator {
  yield takeLatest(
    submissionCommentsAddCommentRequest,
    function* ({ payload }) {
      try {
        const token: string = yield select(tokenSelector);
        const userId: number = yield select(agentIdSelector);
        const correlateId: string = yield select(getCorrelateId);
        const doItForMeId: number = yield select(doItForMeIdSelector);

        const { submissionId, message, isDIFM, parentId, department } = payload;
        const newComment = {
          type: InteractionTypes.comment,
          messageTitle: '',
          messageContent: message,
          parentId: parentId,
        };

        const interactionId: InteractionId = yield call(() => {
          if (isDIFM) {
            return DoItForMeSDK.createSubmissionInteraction(
              config.apiUrl,
              {
                userOrToken: token,
                correlateId,
              },
              {
                doItForMeId,
                interaction: newComment,
              },
            );
          }
          return TaxminSdk.postInteraction(config.apiUrl, token, {
            submissionId,
            interaction: newComment,
          });
        });
        const { email: agentEmail }: MeState = yield select(meSelector);

        const optimisticResponseComment: SubmissionComment = {
          id: interactionId,
          submissionId,
          type: InteractionTypes.comment,
          agentId: userId,
          message,
          date: new Date().toString(),
          agentEmail,
          parentId,
          department,
        };

        if (parentId) {
          yield put(
            submissionCommentsReplyCommentSuccess(optimisticResponseComment),
          );
        } else {
          yield put(
            submissionCommentsAddCommentSuccess(optimisticResponseComment),
          );
        }
        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              parentId
                ? 'de.submission.comments.reply-success'
                : 'de.submission.comments.add-success',
            ),
            severity: MessageSeverity.Success,
          }),
        );
      } catch (err) {
        yield put(
          displayMessage({
            message: err.message,
            severity: MessageSeverity.Error,
          }),
        );
        yield put(submissionCommentsAddCommentFailure({ error: err.message }));
      }
    },
  );
}

export function* editSubmissionComment(): SagaIterator {
  yield takeLatest(
    submissionCommentsEditCommentRequest,
    function* ({ payload }) {
      try {
        const token: string = yield select(tokenSelector);
        const { interactionId, message } = payload;
        const newComment = {
          messageContent: message,
        };
        yield call(() => {
          return TaxminSdk.patchInteraction(config.apiUrl, token, {
            interactionId,
            fields: newComment,
          });
        });
        yield put(
          submissionCommentsEditCommentSuccess({
            interactionId,
            message,
          }),
        );
        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'de.submission.comments.edit-success',
            ),
            severity: MessageSeverity.Success,
          }),
        );
      } catch (err) {
        yield put(
          displayMessage({
            message: err.message,
            severity: MessageSeverity.Error,
          }),
        );
        yield put(submissionCommentsEditCommentFailure({ error: err.message }));
      }
    },
  );
}
