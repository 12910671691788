import { HotKeysMap } from '../../hooks/use-hotkeys-map';

export const identificationActionsKeyMap: HotKeysMap = {
  approve: {
    sequence: 'shift+space',
    name: 'identification.approve',
  },
  reject: {
    sequence: 'shift+d',
    name: 'identification.reject',
  },
  next: {
    sequence: 'shift+right',
    name: 'identification.next',
  },
  previous: {
    sequence: 'shift+left',
    name: 'identification.previous',
  },
  save: {
    sequence: 'shift+s',
    name: 'identification.save',
  },
};
