import React from 'react';

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
  Typography,
} from '@taxfix/ui';
import { Close } from '@taxfix/ui/icons';

import { HotKeysMap } from '../../hooks/use-hotkeys-map';
import ShortcutsCheatsheet from './shortcuts-cheatsheet';

type ShortcutsGroup = {
  title: string;
  keyMap: HotKeysMap;
};

type ShortcutsCheatsheetProps = {
  shortcuts: ShortcutsGroup[];
  open: boolean;
  onClose: (event: object, reason?: string) => void;
};

const Help: React.FC<ShortcutsCheatsheetProps> = ({
  shortcuts,
  open,
  onClose,
}) => {
  return (
    <Dialog
      maxWidth="md"
      TransitionComponent={Fade}
      aria-labelledby="cheatsheet-title"
      open={open}
      onClose={onClose}
      scroll="body"
      closeAfterTransition
    >
      <DialogTitle id="cheatsheet-title">
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography variant="h2" style={{ textTransform: 'uppercase' }}>
              Keyboard Shortcuts
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={onClose} size="small">
              <Close fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <ShortcutsCheatsheet shortcuts={shortcuts} />
      </DialogContent>
    </Dialog>
  );
};

export default Help;
