import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { InteractionTypes } from '@taxfix/operations-types';

import { intlStringsRegistry } from '../../intl';
import { documentsRequestSuccess } from '../send-email/send-email';
import {
  CreateSubmissionNoteRequest,
  EditSubmissionMessageRequest,
  SubmissionComment,
  SubmissionCommentsRequest,
  SubmissionCommentsState,
} from './types';

const initialState: SubmissionCommentsState = {
  loading: false,
  items: [],
  uploadingComment: false,
};

const submissionCommentsSlice = createSlice({
  name: 'submissionComments',
  initialState: initialState,
  reducers: {
    submissionCommentsRequest: (
      state,
      _: PayloadAction<SubmissionCommentsRequest>,
    ): void => {
      state.items = initialState.items;
      state.error = initialState.error;
      state.loading = true;
    },
    submissionCommentsSuccess: (
      state,
      action: PayloadAction<SubmissionCommentsState['items']>,
    ): void => {
      state.error = initialState.error;
      state.loading = false;
      state.items = action.payload;
    },
    submissionCommentsFailure: (
      state,
      action: PayloadAction<{ error?: string }>,
    ): void => {
      state.loading = false;
      state.error =
        action.payload?.error ||
        intlStringsRegistry.getMessage('generic_error');
    },
    submissionCommentsAddCommentRequest: (
      state,
      _: PayloadAction<CreateSubmissionNoteRequest>,
    ): void => {
      state.uploadingCommentError = initialState.uploadingCommentError;
      state.uploadingComment = true;
    },
    submissionCommentsAddCommentSuccess: (
      state,
      action: PayloadAction<SubmissionComment>,
    ): void => {
      state.uploadingCommentError = initialState.uploadingCommentError;
      state.uploadingComment = false;
      state.items = [action.payload, ...state.items];
    },
    submissionCommentsAddCommentFailure: (
      state,
      action: PayloadAction<{ error?: string }>,
    ): void => {
      state.uploadingComment = false;
      state.uploadingCommentError =
        action.payload?.error ||
        intlStringsRegistry.getMessage('generic_error');
    },
    submissionCommentsEditCommentRequest: (
      state,
      _: PayloadAction<EditSubmissionMessageRequest>,
    ): void => {
      state.uploadingCommentError = initialState.uploadingCommentError;
      state.uploadingComment = true;
    },
    submissionCommentsEditCommentSuccess: (
      state,
      action: PayloadAction<EditSubmissionMessageRequest>,
    ): void => {
      state.uploadingCommentError = initialState.uploadingCommentError;
      state.uploadingComment = false;
      const { message, interactionId } = action.payload;
      for (const item of state.items) {
        if (item.id === interactionId) {
          item.message = message;
          break;
        }
        if (!item.replies?.length) {
          continue;
        }
        for (const reply of item.replies) {
          if (reply.id === interactionId) {
            reply.message = message;
            break;
          }
        }
      }
    },
    submissionCommentsReplyCommentSuccess: (
      state,
      action: PayloadAction<SubmissionComment>,
    ): void => {
      const { parentId } = action.payload;
      state.uploadingCommentError = initialState.uploadingCommentError;
      state.uploadingComment = false;

      const updatedItem = state.items.find(item => item.id === parentId);
      if (updatedItem) {
        updatedItem.replies = [...(updatedItem?.replies || []), action.payload];
      }
    },
    submissionCommentsEditCommentFailure: (
      state,
      action: PayloadAction<{ error?: string }>,
    ): void => {
      state.uploadingComment = false;
      state.uploadingCommentError =
        action.payload?.error ||
        intlStringsRegistry.getMessage('generic_error');
    },
  },
  extraReducers: builder =>
    builder.addCase(documentsRequestSuccess, (state, action) => {
      // Add latest documentRequest as new comment
      const newComment: SubmissionComment = {
        ...action.payload,
        id: 123,
        date: new Date().toString(),
        type: InteractionTypes.documentRequest,
      };
      state.items = [newComment, ...state.items];
    }),
});

export default submissionCommentsSlice;

export const {
  submissionCommentsRequest,
  submissionCommentsSuccess,
  submissionCommentsFailure,
  submissionCommentsAddCommentRequest,
  submissionCommentsAddCommentSuccess,
  submissionCommentsAddCommentFailure,
  submissionCommentsEditCommentRequest,
  submissionCommentsEditCommentSuccess,
  submissionCommentsEditCommentFailure,
  submissionCommentsReplyCommentSuccess,
} = submissionCommentsSlice.actions;
