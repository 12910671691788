import { ReactIntlError } from 'react-intl';

import { CountryCodes } from '@taxfix/types';

import IntlStringsRegistry from './intl-strings-registry';
import defaultMessages from './locales/en.json';
import sources from './locales/locales-manifest.json';

export enum SupportedLanguages {
  English = 'en',
  German = 'de',
  // Italian = 'it',
  // French = 'fr'
}

export const DEFAULT_LANGUAGE = SupportedLanguages.English;
export const DEFAULT_COUNTRY = CountryCodes.DE;

export const resolveLocale = (): string[] => {
  const supportedLangs = Object.values(SupportedLanguages) as string[];
  const supportedCountries = Object.values(CountryCodes) as string[];

  const [language, country] = navigator.language.split(/[-_]/);

  const isLangSupported = supportedLangs.includes(language);
  const isCountrySupported = supportedCountries.includes(country);

  const resolvedLang = isLangSupported
    ? (language as SupportedLanguages)
    : DEFAULT_LANGUAGE;
  const resolvedCountry = isCountrySupported
    ? (country as CountryCodes)
    : DEFAULT_COUNTRY;

  return Intl.getCanonicalLocales([
    resolvedLang,
    resolvedCountry,
    `${resolvedLang}-${resolvedCountry}`,
  ]);
};

// only report translation errors in development as WARNINGS
export const reportIntlError = (err: ReactIntlError): void => {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }
  console.warn(err);
};

export const [language, country, locale] = resolveLocale();

export const intlStringsRegistry = new IntlStringsRegistry({
  language,
  sources,
  defaultMessages,
});
