import { Reducer } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';

class ReduxRegistry {
  reducers: { [k: string]: Reducer } = {};
  sagas: string[] = [];

  reducerChangeListener:
    | ((_reducers: { [k: string]: Reducer }) => void)
    | null = null;
  sagaChangeListener: ((_sagas: (() => SagaIterator)[]) => void) | null = null;

  registerReducer(name: string, reducer: Reducer): void {
    if (name in this.reducers || !this.reducerChangeListener) {
      return;
    }

    this.reducers = { ...this.reducers, [name]: reducer };
    this.reducerChangeListener(this.reducers);
  }

  registerSagas(
    name: string,
    sagas: { [k: string]: () => SagaIterator },
  ): void {
    if (this.sagas.includes(name) || !this.sagaChangeListener) {
      return;
    }

    this.sagas.push(name);
    this.sagaChangeListener(Object.values(sagas));
  }
}

export const reduxRegistry = new ReduxRegistry();
