import { createAction } from '@reduxjs/toolkit';

import {
  SubmissionDocumentURLRequest,
  SubmissionDocumentsRequest,
} from './types';

export const submissionDocumentsRequest =
  createAction<SubmissionDocumentsRequest>(
    'submissionDocuments/submissionDocumentsRequest',
  );

export const submissionDocumentsByDoItForMeRequest =
  createAction<SubmissionDocumentsRequest>(
    'submissionDocuments/submissionDocumentsByDoItForMeRequest',
  );

export const submissionDocumentsURLRequest =
  createAction<SubmissionDocumentURLRequest>(
    'submissionDocuments/submissionDocumentsURLRequest',
  );

export const submissionDocumentsURLDIFMRequest =
  createAction<SubmissionDocumentURLRequest>(
    'submissionDocuments/submissionDocumentsURLDIFMRequest',
  );
