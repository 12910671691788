import {
  AnyAction,
  PayloadAction,
  Reducer,
  createAction,
  createReducer,
} from '@reduxjs/toolkit';

import { CountryCodes } from '@taxfix/types';

import { LoginErrors } from '../../types';
import { setCurrentCountry } from './shared-action-creators';
import {
  AssignedDoItForMeProviders,
  LoginRequest,
  LoginResponseFailure,
  LoginResponseSuccess,
  LoginStatus,
  MeState,
  RemoteConfigSuccess,
  TokenCheckRequest,
} from './types';

const initialState: MeState = {
  email: '',
  token: '',
  id: 0,
  loginError: null,
  remoteConfig: null,
  rights: [],
  currentCountry: CountryCodes.DE,
  status: LoginStatus.loggedOut,
  allocationRoles: {},
};

const NAME = 'me';

// Actions
export const loginRequest = createAction<LoginRequest>(`${NAME}/loginRequest`);
export const loginSuccess = createAction<LoginResponseSuccess>(
  `${NAME}/loginSuccess`,
);
export const loginFailure = createAction<LoginResponseFailure>(
  `${NAME}/loginFailure`,
);
export const logoutRequest = createAction(`${NAME}/logoutRequest`);
export const logoutSuccess = createAction(`${NAME}/logoutSuccess`);
export const loginGoogleError = createAction(`${NAME}/loginGoogleError`);
export const loginGoogleRequest = createAction(`${NAME}/loginGoogleRequest`);
export const loginGoogleSuccess = createAction(`${NAME}/loginGoogleSuccess`);
export const loginHalted = createAction(`${NAME}/loginHalted`);
export const loginPopUpsError = createAction(`${NAME}/loginPopUpsError`);
export const logoutGoogleRequest = createAction(`${NAME}/logoutGoogleRequest`);
export const logoutGoogleSuccess = createAction(`${NAME}/logoutGoogleSuccess`);
export const tokenCheckFinished = createAction(`${NAME}/tokenCheckFinished`);
export const tokenCheckRequest = createAction<TokenCheckRequest>(
  `${NAME}/tokenCheckRequest`,
);
export const remoteConfigRequest = createAction(`${NAME}/remoteConfigRequest`);
export const remoteConfigSuccess = createAction<RemoteConfigSuccess>(
  `${NAME}/remoteConfigSuccess`,
);

export const assignedDoItForMeProvidersRequest = createAction(
  `${NAME}/assignedProvidersRequest`,
);
export const assignedDoItForMeProvidersSuccess =
  createAction<AssignedDoItForMeProviders>(`${NAME}/assignedProvidersSuccess`);

export * from './shared-action-creators';

const onRemoteConfigSuccess = (
  state: MeState,
  action: PayloadAction<RemoteConfigSuccess>,
): void => {
  state.remoteConfig = action.payload.config;
};

const whenStatusLoggedOut = createReducer(initialState, {
  [loginRequest.toString()]: state => {
    state.status = LoginStatus.busy;
  },
  [loginGoogleRequest.toString()]: state => {
    state.status = LoginStatus.busy;
  },
  [remoteConfigSuccess.toString()]: onRemoteConfigSuccess,
});

const whenStatusBusy = createReducer(initialState, {
  [loginSuccess.toString()]: (
    state,
    action: PayloadAction<LoginResponseSuccess>,
  ) => {
    Object.assign(state, {
      status: LoginStatus.loggedIn,
      email: action.payload.email,
      id: action.payload.id,
      token: action.payload.token,
      rights: action.payload.rights,
      department: action.payload.department,
      allocationRoles: action.payload.allocationRoles,
    });
  },
  [loginFailure.toString()]: (
    state,
    action: PayloadAction<LoginResponseFailure>,
  ) => {
    state.status = LoginStatus.error;
    state.loginError =
      action.payload.status === 401
        ? LoginErrors.Unauthorized
        : LoginErrors.Other;
  },
  [remoteConfigSuccess.toString()]: onRemoteConfigSuccess,
  [loginGoogleError.toString()]: state => {
    state.status = LoginStatus.loggedOut;
    state.loginError = LoginErrors.IsNotLoggedInGoogle;
  },
  [loginPopUpsError.toString()]: state => {
    state.status = LoginStatus.loggedOut;
    state.loginError = LoginErrors.HasPopUpsBlocked;
  },
  [loginGoogleSuccess.toString()]: state => {
    state.status = LoginStatus.loggedIn;
  },
  [loginHalted.toString()]: state => {
    state.status = LoginStatus.loggedOut;
  },
});

const whenStatusLoggedIn = createReducer(initialState, {
  [logoutSuccess.toString()]: (state): MeState => ({
    ...initialState,
    remoteConfig: state.remoteConfig,
  }),
  [logoutGoogleSuccess.toString()]: state => {
    Object.assign(state, initialState, { remoteConfig: state.remoteConfig });
  },
  [remoteConfigSuccess.toString()]: onRemoteConfigSuccess,
  [setCurrentCountry.toString()]: (state, action) => {
    state.currentCountry = action.payload.currentCountry;
  },
  [assignedDoItForMeProvidersSuccess.toString()]: (state, action) => {
    state.assignedDoItForMeProviders =
      action.payload.assignedDoItForMeProviders;
  },
});

const whenStatusError = createReducer(initialState, {
  [loginGoogleError.toString()]: state => {
    state.status = LoginStatus.loggedOut;
    state.loginError = LoginErrors.IsNotLoggedInGoogle;
  },
  [loginPopUpsError.toString()]: state => {
    state.status = LoginStatus.loggedOut;
    state.loginError = LoginErrors.HasPopUpsBlocked;
  },
  [loginRequest.toString()]: state => {
    state.loginError = initialState.loginError;
    state.status = LoginStatus.busy;
  },
  [remoteConfigSuccess.toString()]: onRemoteConfigSuccess,
});

const meStateMachine = (state = initialState, action: AnyAction): MeState => {
  if (state.status === LoginStatus.loggedOut) {
    return whenStatusLoggedOut(state, action);
  }
  if (state.status === LoginStatus.busy) {
    return whenStatusBusy(state, action);
  }
  if (state.status === LoginStatus.loggedIn) {
    return whenStatusLoggedIn(state, action);
  }
  if (state.status === LoginStatus.error) {
    return whenStatusError(state, action);
  }

  return state;
};

export default {
  reducer: meStateMachine as Reducer<MeState>,
  name: NAME,
};
