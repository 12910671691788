import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppSettings, AppState, ThemeType } from './types';

const initialState: AppState = {
  submissionSidebarOpen: true,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    changeSettings: (
      state,
      action: PayloadAction<Partial<AppSettings>>,
    ): AppState => ({
      ...state,
      ...action.payload,
    }),
    setThemeType: (state, action: PayloadAction<ThemeType>): void => {
      state.themeType = action.payload;
    },
  },
});

export default appSlice;

// Actions
export const { changeSettings, setThemeType } = appSlice.actions;
