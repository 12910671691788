import exportFromJSON from 'export-from-json';
import { FlattenSimpleInterpolation, css } from 'styled-components';

import { intlStringsRegistry } from './intl';
import { MessageValues } from './intl/intl-strings-registry';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFunction = (...args: any[]) => any;

export const capitalizeFirst = (str: string): string =>
  str.charAt(0).toUpperCase() + str.substring(1);

/**
 * Styled-components mixin that, when the `disabled` prop is set,
 * sets the following css props:
 * ```
 * filter: grayscale(1);
 * opacity: 0.3;
 * ```
 * This is useful for changing an icon's color when it's disabled.
 *
 * You may alternatively use the `$disabled` prop if you don't wish
 * to forward the prop to the underlying component.
 *
 * *Usage:*
 * ```
 * const StyledButton = styled(IconButton)`
 *   ${disabledMixin}
 * `
 * ```
 */
export const disabledMixin = ({
  $disabled,
  disabled,
}: {
  $disabled?: boolean;
  disabled?: boolean;
}): FlattenSimpleInterpolation =>
  $disabled || disabled
    ? css`
        filter: grayscale(1);
        opacity: 0.3;
      `
    : css``;

export const ellipsisMixin = ({
  $maxLines,
}: {
  $maxLines: number;
}): FlattenSimpleInterpolation =>
  css`
    display: -webkit-box;
    -webkit-line-clamp: ${$maxLines};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `;

export type AsyncReturnType<T extends AnyFunction> =
  ReturnType<T> extends Promise<infer U> ? U : never;

export const json2csv = (data: Record<string, number | string>[]): string =>
  exportFromJSON({
    data,
    exportType: 'csv',
    processor: content => content,
  });

export const formatMessage = (id: string, values?: MessageValues): string => {
  return intlStringsRegistry.getMessage(id, values);
};

/**
 * This function filters user's documents by year.
 *
 * @param documents list of document-data items
 * @param taxYear the year used to filter
 * @returns list of filtered document-data items
 */

export const filterDocumentsByYear = <T extends { year: number }>(
  documents: Array<T>,
  year: number | undefined,
): Array<T> => documents.filter(document => document.year === year);

/**
 * This function filters user's documents by type.
 *
 * @param documents list of document-data items
 * @param type type used to filter
 * @returns list of filtered document-data items
 */

export const filterDocumentsByType = <T extends { type: string }>(
  documents: Array<T>,
  type: string,
): Array<T> => documents.filter(document => document.type === type);

/**
 * This function gets the documents uploaded by the user from the
 * document's list. Documents uploaded by the user has type:
 *
 * * id
 * * Containing 'DOCUMENT_'
 *
 * @param documents list of document-data items
 * @returns list of filtered document-data items
 */

// TODO: If we end using this more, make a filter in back.

export const filterDocumentsByUploadedByUser = <T extends { type: string }>(
  documents: Array<T>,
): Array<T> => {
  const uploadedDocuments = ['id', 'cat_bonus_vacation', 'manually-submitted'];
  return documents.filter(
    document =>
      uploadedDocuments.includes(document.type) ||
      document.type.includes('DOCUMENT_'),
  );
};

export const requestInChunks = async <T, R = T>(
  data: T[],
  handler: (chunk: T[]) => Promise<R>[],
  chunkSize = 10,
): Promise<R[]> => {
  const docsLength = data.length;
  let result: R[] = [];

  for (let i = 0; i < docsLength; i += chunkSize) {
    const docsChunk = data.slice(i, i + chunkSize);

    const promises = handler(docsChunk);

    result = result.concat(await Promise.all(promises));
  }

  return result;
};

/**
 * Extract error message from an Axios error object.
 * @param err
 */
export const extractAxiosErrorMessage = (err: any) => {
  if (err?.response?.status >= 400 && err.response?.data?.message) {
    return `${err.message} - ${err.response.data.message}`;
  }
  return null;
};
