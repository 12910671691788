import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { intlStringsRegistry } from '../../intl';
import { CreateTemplateFormData, TemplateType, TemplatesState } from './types';
import { modifyLocalTemplate, removeLocalTemplate } from './utils';

const initialState: TemplatesState = {
  loading: false,
  error: undefined,
  success: undefined,
  type: '',
  data: [],
};

const templatesSlice = createSlice({
  name: 'templates',
  initialState: initialState,
  reducers: {
    // GET
    templatesRequest: (state, { payload }: PayloadAction<string>): void => {
      state.loading = true;
      state.error = initialState.error;
      state.success = initialState.success;
      state.type = payload;
    },
    templatesRequestSuccess: (
      state,
      { payload }: PayloadAction<TemplateType[]>,
    ): void => {
      state.loading = false;
      state.error = initialState.error;
      state.data = [...payload];
    },
    templatesRequestFailure: (
      state,
      { payload }: PayloadAction<{ error: Error }>,
    ): void => {
      state.loading = false;
      state.error =
        payload?.error || intlStringsRegistry.getMessage('generic_error');
    },
    // POST
    templateCreateRequest: (
      state,
      _: PayloadAction<CreateTemplateFormData>,
    ): void => {
      state.loading = true;
      state.error = initialState.error;
      state.success = initialState.success;
    },
    templateCreateRequestSuccess: (
      state,
      { payload }: PayloadAction<TemplateType>,
    ): void => {
      state.loading = false;
      state.error = undefined;
      state.data = [...state.data, payload];
      state.success = true;
    },
    templateCreateRequestFailure: (
      state,
      { payload }: PayloadAction<{ error: Error }>,
    ): void => {
      state.loading = false;
      state.error =
        payload?.error || intlStringsRegistry.getMessage('generic_error');
      state.success = initialState.success;
    },
    // PUT
    templateUpdateRequest: (
      state,
      _: PayloadAction<{ id: string | null; formData: CreateTemplateFormData }>,
    ): void => {
      state.loading = true;
      state.error = initialState.error;
      state.success = initialState.success;
    },
    templateUpdateRequestSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{ id: string | null; formData: CreateTemplateFormData }>,
    ): void => {
      state.loading = false;
      state.error = initialState.error;
      state.data = modifyLocalTemplate(state.data, payload);
      state.success = true;
    },
    templateUpdateRequestFailure: (
      state,
      { payload }: PayloadAction<{ error: Error }>,
    ): void => {
      state.loading = false;
      state.error =
        payload?.error || intlStringsRegistry.getMessage('generic_error');
      state.success = initialState.success;
    },
    // DELETE
    templateDeleteRequest: (
      state,
      _: PayloadAction<{ id: string | null }>,
    ): void => {
      state.loading = true;
      state.error = initialState.error;
      state.success = initialState.success;
    },
    templateDeleteRequestSuccess: (
      state,
      { payload }: PayloadAction<{ id: string | null }>,
    ): void => {
      state.loading = false;
      state.error = initialState.error;
      state.data = removeLocalTemplate(payload.id, state.data);
      state.success = true;
    },
    templateDeleteRequestFailure: (
      state,
      { payload }: PayloadAction<{ error: Error }>,
    ): void => {
      state.loading = false;
      state.success = initialState.success;
      state.error =
        payload?.error || intlStringsRegistry.getMessage('generic_error');
    },
    templateResetSuccess: (state): void => {
      state.success = initialState.success;
      state.error = initialState.error;
    },
  },
});

export default templatesSlice;

export const {
  templatesRequest,
  templatesRequestSuccess,
  templatesRequestFailure,
  templateCreateRequest,
  templateCreateRequestSuccess,
  templateCreateRequestFailure,
  templateUpdateRequest,
  templateUpdateRequestSuccess,
  templateUpdateRequestFailure,
  templateDeleteRequest,
  templateDeleteRequestSuccess,
  templateDeleteRequestFailure,
  templateResetSuccess,
} = templatesSlice.actions;
