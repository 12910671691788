import { persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/es/storage';

import { reduxRegistry } from '..';
import slice from './difm';
import * as sagas from './sagas';

export * from './difm';
export * from './types';
export * from './selectors';

const { name, reducer } = slice;

const persistConfig = {
  key: 'doItForMe',
  whitelist: ['overviewSeen'],
  storage: localStorage,
  version: 0,
};

reduxRegistry.registerReducer(name, persistReducer(persistConfig, reducer));
reduxRegistry.registerSagas(name, sagas);
