import { PayloadAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import createHTTPError from 'http-errors';
import { SagaIterator } from 'redux-saga';
import { select, takeEvery } from 'redux-saga/effects';

import { ResponseError } from '@taxfix/taxfix-sdk/dist/request';

import { meSelector } from '../me';
import { reportError } from './errors';
import { ErrorReport } from './types';
import { isSessionExpiredError, transformResponseError } from './utils';

export function* sendErrorsToSentry(): SagaIterator {
  yield takeEvery(
    reportError,
    function* ({
      payload: { error, meta, showDialog = false },
    }: PayloadAction<ErrorReport>) {
      // do not track errors when there is not a dsn (development)
      if (config.sentry.dsn === null) {
        return;
      }
      if (error instanceof ResponseError && isSessionExpiredError(error)) {
        return;
      }

      const { id: agentId, email } = yield select(meSelector);
      const errorMeta = { ...meta };

      Sentry.withScope(scope => {
        scope.setUser({ id: agentId, email });

        const responseInfo =
          error instanceof ResponseError ? transformResponseError(error) : {};
        scope.setExtras({ ...errorMeta, ...responseInfo });

        // TODO: Remove when fixing src/request.ts.
        // TODO: unknown type [taxfix-sdk@2.81.0].
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (error instanceof ResponseError && error.response?.status) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          error = createHTTPError(error.response?.status, error.message);
        }

        const eventId = Sentry.captureException(error, scope);

        if (showDialog) {
          Sentry.showReportDialog({ eventId });
        }
      });

      return;
    },
  );
}
