import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Box, Typography } from '@taxfix/ui';

import galgo from '../../../assets/images/error-screen/galgo.svg';

const ContainerBox = styled(Box)`
  background: ${({ theme }): string => theme.palette.background.default};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ theme }): string => theme.spacing(25)};
  height: 100%;
  width: 100%;
  min-height: 85vh;
`;

const GalgoContainer = styled.div`
  padding-bottom: ${({ theme }): string => theme.spacing(5)};
`;

const ErrorScreen: React.FC = ({ children }) => {
  return (
    <ContainerBox>
      <GalgoContainer>
        <img src={galgo} alt="galgo" />
      </GalgoContainer>
      <Box>{children}</Box>
    </ContainerBox>
  );
};

export const DefaultErrorScreen: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <ErrorScreen>
      <Typography variant="h6">
        {formatMessage({ id: 'screen-error.unexpected-error' })}
      </Typography>
    </ErrorScreen>
  );
};

export default ErrorScreen;
