export interface MessagesType {
  [id: string]: string;
}

export type MessageValues = Record<string, string | number>;

class IntlStringsRegistry {
  defaultMessages: MessagesType = {};
  messages: MessagesType = {};
  language: string;
  sources: string[];

  constructor({
    language,
    sources,
    defaultMessages,
  }: {
    language: string;
    sources: string[];
    defaultMessages: MessagesType;
  }) {
    this.language = language;
    this.sources = sources;
    this.defaultMessages = defaultMessages;
  }

  loadMessages(): Promise<void> {
    const appStringsRequests = this.sources.map((source: string) =>
      import(/* webpackChunkName: "[request]" */ `./locales/${source}`)
        .then(module => module.default)
        .then(res => res.strings?.[this.language] || res)
        .catch((err: Error) => {
          console.error(`Request ${source} failed\n${err.message}`);
        }),
    );

    return Promise.allSettled(appStringsRequests)
      .then((values: PromiseSettledResult<MessagesType>[]) =>
        values.reduce(
          (acc: MessagesType, next: PromiseSettledResult<MessagesType>) => {
            if (next.status === 'fulfilled') {
              return { ...acc, ...next.value };
            }
            return acc;
          },
          this.defaultMessages,
        ),
      )
      .then(messages => {
        this.messages = messages;
      });
  }

  getMessages(): MessagesType {
    return this.messages;
  }

  getMessage(key: string, values?: MessageValues): string {
    if (this.messages) {
      if (
        values &&
        Object.keys(values).find(
          value => this.messages[key]?.indexOf(`{${value}}`) > -1,
        )
      ) {
        return Object.keys(values).reduce(
          (acc, currentValue) =>
            acc.replace(
              new RegExp(`{${currentValue}}`, 'g'),
              values[currentValue] as string,
            ),
          this.messages[key],
        );
      }

      return this.messages[key];
    }
    console.warn(`Translation message for key ${key} not found`);
    return key;
  }
}

export default IntlStringsRegistry;
