import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { ResponseErrorType } from '../common';
import { setCurrentCountry } from '../me/shared-action-creators';
import {
  submissionDocumentsByDoItForMeRequest,
  submissionDocumentsRequest,
} from './shared-action-creators';
import {
  PoaIdentificationDocumentRequest,
  SubmissionDocumentPatchRequest,
  SubmissionDocumentPatchResponseSuccess,
  SubmissionDocumentUpdateRequest,
  SubmissionDocumentUpdateResponseSuccess,
  SubmissionDocumentUploadRequest,
  SubmissionDocumentsResponseSuccess,
  SubmissionDocumentsState,
  TaxminDocument,
} from './types';

const initialState: SubmissionDocumentsState = {
  data: [],
  total: 0,
  error: undefined,
  loading: true,
  updating: false,
  updateSuccess: false,
  updateError: undefined,
  uploading: false,
  uploadSuccess: false,
  uploadError: undefined,
  urls: {},
};

const submissionDocumentsSlice = createSlice({
  name: 'submissionDocuments',
  initialState: initialState,
  reducers: {
    submissionDocumentsSuccess: (
      state,
      action: PayloadAction<SubmissionDocumentsResponseSuccess>,
    ): SubmissionDocumentsState => ({
      ...state,
      ...action.payload,
      loading: false,
    }),
    submissionDocumentsFailure: (
      state,
      action: PayloadAction<ResponseErrorType>,
    ): void => {
      state.loading = false;
      state.error = action.payload;
    },
    submissionDocumentUpdateRequest: (
      state,
      _: PayloadAction<SubmissionDocumentUpdateRequest>,
    ): void => {
      state.updating = true;
      state.updateError = initialState.updateError;
      state.updateSuccess = initialState.updateSuccess;
    },
    submissionDocumentUpdateDoItForMeRequest: (
      state,
      _: PayloadAction<SubmissionDocumentUpdateRequest>,
    ): void => {
      state.updating = true;
      state.updateError = initialState.updateError;
      state.updateSuccess = initialState.updateSuccess;
    },
    submissionDocumentUpdateSuccess: (
      state,
      action: PayloadAction<SubmissionDocumentUpdateResponseSuccess>,
    ): void => {
      state.updating = initialState.updating;
      state.updateSuccess = true;
      // update documents state with updated document
      const documents = state.data.map(doc => {
        if (doc.id === action.payload.document.id) {
          return { ...action.payload.document, path: doc.path };
        }
        return doc;
      });
      state.data = documents;
    },
    submissionDocumentUpdateFailure: (
      state,
      action: PayloadAction<ResponseErrorType>,
    ): void => {
      state.updating = initialState.updating;
      state.error = action.payload;
    },
    submissionDocumentPatchRequest: (
      state,
      _: PayloadAction<SubmissionDocumentPatchRequest>,
    ): void => {
      state.updating = true;
      state.updateError = initialState.updateError;
      state.updateSuccess = initialState.updateSuccess;
    },
    submissionDocumentPatchDoItForMeRequest: (
      state,
      _: PayloadAction<SubmissionDocumentPatchRequest>,
    ): void => {
      state.updating = true;
      state.updateError = initialState.updateError;
      state.updateSuccess = initialState.updateSuccess;
    },
    submissionDocumentPatchSuccess: (
      state,
      action: PayloadAction<SubmissionDocumentPatchResponseSuccess>,
    ): void => {
      state.updating = initialState.updating;
      state.updateSuccess = true;
      // update documents state with updated document
      const documents = state.data.map(doc => {
        const { metadata, ...rest } = action.payload;
        if (doc.id === action.payload.id) {
          return {
            ...doc,
            ...rest,
            metadata: metadata
              ? { ...doc.metadata, ...metadata }
              : doc.metadata,
          };
        }
        return doc;
      });
      state.data = documents;
    },
    submissionDocumentPatchFailure: (
      state,
      action: PayloadAction<ResponseErrorType>,
    ): void => {
      state.updating = initialState.updating;
      state.error = action.payload;
    },
    submissionDocumentUploadRequest: (
      state,
      _: PayloadAction<SubmissionDocumentUploadRequest>,
    ): void => {
      state.uploading = true;
      state.uploadError = initialState.uploadError;
      state.uploadSuccess = initialState.uploadSuccess;
    },
    submissionDocumentUploadDoItForMeRequest: (
      state,
      _: PayloadAction<SubmissionDocumentUploadRequest>,
    ): void => {
      state.uploading = true;
      state.uploadError = initialState.uploadError;
      state.uploadSuccess = initialState.uploadSuccess;
    },
    poaIdentificationDocumentUploadRequest: (
      state,
      _: PayloadAction<PoaIdentificationDocumentRequest>,
    ): void => {
      state.uploading = true;
      state.uploadError = initialState.uploadError;
      state.uploadSuccess = initialState.uploadSuccess;
    },
    submissionDocumentUploadSuccess: (
      state,
      action: PayloadAction<TaxminDocument>,
    ): void => {
      state.uploading = initialState.uploading;
      state.uploadSuccess = true;
      const documents = state.data.concat(action.payload);
      state.data = documents;
      state.total = state.total + 1;
    },
    submissionDocumentUploadFailure: (
      state,
      action: PayloadAction<ResponseErrorType>,
    ): void => {
      state.uploading = initialState.uploading;
      state.error = action.payload;
    },
    submissionDocumentLoadURLStart: (
      state,
      action: PayloadAction<{ documentId: number }>,
    ): void => {
      const { documentId } = action.payload;
      state.urls[documentId] = {
        loading: true,
      };
    },
    submissionDocumentLoadURLSuccess: (
      state,
      action: PayloadAction<{ documentId: number; url: string }>,
    ): void => {
      const { documentId, url } = action.payload;
      const item = {
        loading: false,
        url,
      };
      state.urls[documentId] = item;
    },
    submissionDocumentLoadURLFailure: (
      state,
      action: PayloadAction<{ documentId: number; error: ResponseErrorType }>,
    ): void => {
      const { documentId, error } = action.payload;
      state.urls[documentId].loading = false;
      state.urls[documentId].error = error;
    },
  },
  extraReducers: builder =>
    builder
      .addCase(setCurrentCountry, () => initialState)
      .addMatcher(
        isAnyOf(
          submissionDocumentsRequest,
          submissionDocumentsByDoItForMeRequest,
        ),
        state => {
          // Clear blobs -> chrome://blob-internals/
          Object.values(state.urls).forEach(item => {
            URL.revokeObjectURL(item?.url ?? '');
          });
          return {
            ...initialState,
          };
        },
      ),
});

export default submissionDocumentsSlice;

export const {
  submissionDocumentsSuccess,
  submissionDocumentsFailure,
  submissionDocumentUpdateRequest,
  submissionDocumentUpdateDoItForMeRequest,
  submissionDocumentUpdateSuccess,
  submissionDocumentUpdateFailure,
  submissionDocumentPatchRequest,
  submissionDocumentPatchDoItForMeRequest,
  submissionDocumentPatchSuccess,
  submissionDocumentPatchFailure,
  submissionDocumentUploadDoItForMeRequest,
  submissionDocumentUploadSuccess,
  submissionDocumentUploadFailure,
  submissionDocumentLoadURLStart,
  submissionDocumentLoadURLSuccess,
  submissionDocumentLoadURLFailure,
  submissionDocumentUploadRequest,
  poaIdentificationDocumentUploadRequest,
} = submissionDocumentsSlice.actions;

export * from './shared-action-creators';
