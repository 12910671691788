import { SubmissionRelationsIdentificationSource } from '@taxfix/submissions-types';
import { Documents } from '@taxfix/types';

import { SubmissionWithIdAndIdentificationState } from '../redux/submissions';

export type CalculatedIdentified = {
  version: number;
  state: Documents.States;
  id: number;
  identificationSource?: SubmissionRelationsIdentificationSource;
};

export const calculateIdentified = (
  submissionRawData: SubmissionWithIdAndIdentificationState,
): CalculatedIdentified => {
  // KYC Identification
  const KycStateIsaccepted =
    submissionRawData.identificationDocumentState ===
    ('accepted' as Documents.States);
  if (KycStateIsaccepted && submissionRawData.identificationDocumentId) {
    return {
      version: 2,
      state: Documents.States.Approved,
      id: submissionRawData.identificationDocumentId,
      identificationSource: submissionRawData.identificationSource,
    };
  }

  // v2 validated
  if (
    submissionRawData.identificationDocumentState ===
      Documents.States.Approved &&
    submissionRawData.identificationDocumentId
  ) {
    return {
      version: 2,
      state: Documents.States.Approved,
      id: submissionRawData.identificationDocumentId,
      identificationSource: submissionRawData.identificationSource,
    };
  }

  // v2 rejected
  if (
    submissionRawData.identificationDocumentState ===
      Documents.States.Rejected &&
    submissionRawData.identificationDocumentId
  ) {
    return {
      version: 2,
      state: Documents.States.Rejected,
      id: submissionRawData.identificationDocumentId,
      identificationSource: submissionRawData.identificationSource,
    };
  }

  // v2 pending
  if (
    submissionRawData.identificationDocumentState ===
      Documents.States.Created &&
    submissionRawData.identificationDocumentId
  ) {
    return {
      version: 2,
      state: Documents.States.Created,
      id: submissionRawData.identificationDocumentId,
      identificationSource: submissionRawData.identificationSource,
    };
  }

  // v2 deleted
  if (
    submissionRawData.identificationDocumentState === Documents.States.Deleted
  ) {
    return {
      version: 2,
      state: Documents.States.Deleted,
      id: submissionRawData.identificationDocumentId || 0,
      identificationSource: submissionRawData.identificationSource,
    };
  }

  // v2 not-considered
  if (
    submissionRawData.identificationDocumentState ===
    Documents.States.NotConsidered
  ) {
    return {
      version: 2,
      state: Documents.States.NotConsidered,
      id: submissionRawData.identificationDocumentId || 0,
      identificationSource: submissionRawData.identificationSource,
    };
  }

  return {
    version: 2,
    state: Documents.States.Created,
    id: 0,
    identificationSource: submissionRawData.identificationSource,
  };
};
