import React from 'react';
import styled from 'styled-components';

import { CircularProgress, Grid } from '@taxfix/ui';

const FullHeightGrid = styled(Grid)`
  height: 100%;
  background-color: ${({ theme }): string => theme.palette.background.paper};
`;

interface LoadingGridProps {
  transparent?: boolean;
}

const LoadingGrid: React.FC<LoadingGridProps> = ({ transparent = false }) => (
  <FullHeightGrid
    container
    justifyContent="center"
    alignItems="center"
    style={{ backgroundColor: (transparent || '') && 'transparent' }}
  >
    <Grid item>
      <CircularProgress disableShrink />
    </Grid>
  </FullHeightGrid>
);

export default LoadingGrid;
