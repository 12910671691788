import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import {
  call,
  delay,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { v2 as DoItForMeSDK } from '@taxfix/do-it-for-me-sdk';
import { GetAllDocumentsResponse } from '@taxfix/do-it-for-me-sdk/dist/v2/documents/get-all-documents';
import {
  createWithFormData,
  get,
  getOne,
  patch,
} from '@taxfix/documents-sdk/dist/api/v1';
import { update } from '@taxfix/documents-sdk/dist/api/v1/update';
import { DocumentTypes } from '@taxfix/documents-sdk/dist/types/v1/documents';
import { GetDocumentsResponse } from '@taxfix/documents-sdk/dist/types/v1/get';
import { TaxminSdk } from '@taxfix/operations-sdk';
import {
  CountryCodes,
  Documents as DocumentsType,
  Platforms,
} from '@taxfix/types';

import { intlStringsRegistry } from '../../intl';
import {
  AsyncReturnType,
  filterDocumentsByUploadedByUser,
  filterDocumentsByYear,
} from '../../utils';
import {
  fetchDifmDocument,
  fetchDifmDocuments,
  getDIFMDocumentObjectURL,
  getDocumentObjectURL,
} from '../../utils/documents';
import { ResponseErrorType, handleError } from '../common';
import { doItForMeIdSelector } from '../difm';
import {
  getCorrelateId,
  getCurrentCountry,
  remoteConfigSelector,
  tokenSelector,
} from '../me';
import { MessageSeverity, displayMessage } from '../messages';
import {
  submissionCountryCodeSelector,
  submissionUserIdSelector,
  submissionYearSelector,
} from '../submission';
import { submissionDocumentsURLsSelector } from './selectors';
import {
  poaIdentificationDocumentUploadRequest,
  submissionDocumentLoadURLFailure,
  submissionDocumentLoadURLStart,
  submissionDocumentLoadURLSuccess,
  submissionDocumentPatchDoItForMeRequest,
  submissionDocumentPatchFailure,
  submissionDocumentPatchRequest,
  submissionDocumentPatchSuccess,
  submissionDocumentUpdateDoItForMeRequest,
  submissionDocumentUpdateFailure,
  submissionDocumentUpdateRequest,
  submissionDocumentUpdateSuccess,
  submissionDocumentUploadDoItForMeRequest,
  submissionDocumentUploadFailure,
  submissionDocumentUploadRequest,
  submissionDocumentUploadSuccess,
  submissionDocumentsByDoItForMeRequest,
  submissionDocumentsFailure,
  submissionDocumentsRequest,
  submissionDocumentsSuccess,
  submissionDocumentsURLDIFMRequest,
  submissionDocumentsURLRequest,
} from './submission-documents';
import {
  PoaIdentificationDocumentRequest,
  SubmissionDocumentPatchRequest,
  SubmissionDocumentURLRequest,
  SubmissionDocumentUpdateRequest,
  SubmissionDocumentUploadRequest,
  SubmissionDocumentsRequest,
  SubmissionDocumentsResponseSuccess,
  SubmissionDocumentsURLs,
} from './types';

// order to show IT documents in
const documentOrder = [
  DocumentsType.States.Created,
  DocumentsType.States.Approved,
  DocumentsType.States.Rejected,
  DocumentsType.States.NotConsidered,
];

const sortDocumentsByState = <
  T extends { id: number; state: DocumentsType.States },
>(
  documents: Array<T>,
): Array<T> =>
  documents.sort((a, b) => {
    let aIndex, bIndex;
    // if it's a new state not considered yet, we assign a high number (9) so it goes last
    // indexOf returns -1 if it doesn't exist, which would sort it over all others
    documentOrder.indexOf(a.state) === -1
      ? (aIndex = 9)
      : (aIndex = documentOrder.indexOf(a.state));
    documentOrder.indexOf(b.state) === -1
      ? (bIndex = 9)
      : (bIndex = documentOrder.indexOf(b.state));
    return aIndex - bIndex || a.id - b.id;
  });

const parseMetadata = (metadata: string | object) =>
  typeof metadata === 'string' ? JSON.parse(metadata) : metadata;

export function* getDocuments(): SagaIterator {
  yield takeLatest(
    [submissionDocumentsRequest, submissionDocumentsByDoItForMeRequest],
    function* (
      action: PayloadAction<SubmissionDocumentsRequest>,
    ): SagaIterator {
      try {
        const documents: SubmissionDocumentsResponseSuccess = yield call(() => {
          if (action.type === submissionDocumentsByDoItForMeRequest.type) {
            return getDocumentsDIFM(action);
          }
          return getDocumentsCore(action);
        });

        // if required to filter by certain document types, do so before downloading
        if (action.payload.filterByType) {
          documents.data = filterDocumentsByUploadedByUser(
            filterDocumentsByYear(documents.data, action.payload.year),
          );
        }
        const countryCode = yield select(getCurrentCountry);
        if (countryCode === CountryCodes.IT) {
          documents.data = sortDocumentsByState(documents.data);
        }
        // TODO: api should return metadata as an object.
        documents.data.forEach(document => {
          // Parse metadata in case a string is fetch.
          document.metadata = parseMetadata(document.metadata);
          document.metadata.rotation =
            parseInt(document.metadata.rotation as unknown as string) || 0;
        });

        yield put(submissionDocumentsSuccess(documents));
      } catch (error) {
        const errorType: ResponseErrorType = yield* handleError(error);
        yield put(submissionDocumentsFailure(errorType));
      }
    },
  );
}

function* getDocumentsCore(
  action: PayloadAction<SubmissionDocumentsRequest>,
): SagaIterator<GetDocumentsResponse> {
  const token: string = yield select(tokenSelector);
  const documents: GetDocumentsResponse = {
    data: [],
    total: 0,
  };
  const countryCode = yield select(getCurrentCountry);
  yield call(async () => {
    let page = 0;
    const getDocs = async (): Promise<void> => {
      const docs = await get(config.apiUrl, token, {
        ...action.payload,
        page,
        countryCode,
      });

      documents.data = documents.data.concat(docs.data);

      documents.total = docs.total;
      if (documents.data.length < documents.total) {
        page = page + 1;
        await getDocs();
      }
    };

    await getDocs();
  });

  return documents;
}

function* getDocumentsDIFM(
  action: PayloadAction<SubmissionDocumentsRequest>,
): SagaIterator<GetAllDocumentsResponse> {
  const token: string = yield select(tokenSelector);
  const difmId: number = yield select(doItForMeIdSelector);
  const userCorrelateId: string = yield select(getCorrelateId);
  const countryCode = yield select(submissionCountryCodeSelector);

  const documents: GetAllDocumentsResponse = yield call(
    async () =>
      await fetchDifmDocuments(token, userCorrelateId, {
        difmId,
        year: action.payload.year,
        countryCode,
      }),
  );
  return documents;
}

export function* updateDocument(): SagaIterator {
  yield takeLatest(
    submissionDocumentUpdateRequest,
    function* (
      action: PayloadAction<SubmissionDocumentUpdateRequest>,
    ): SagaIterator {
      try {
        const token: string = yield select(tokenSelector);
        const result: AsyncReturnType<typeof update> = yield call(() =>
          update(config.apiUrl, token, action.payload),
        );

        yield put(submissionDocumentUpdateSuccess(result));
      } catch (error) {
        const errorType: ResponseErrorType = yield* handleError(error);
        yield put(submissionDocumentUpdateFailure(errorType));
      }
    },
  );
}

export function* patchDocument(): SagaIterator {
  yield takeLatest(
    submissionDocumentPatchRequest,
    function* (
      action: PayloadAction<SubmissionDocumentPatchRequest>,
    ): SagaIterator {
      try {
        const token: string = yield select(tokenSelector);
        const payload = {
          ...action.payload,
        };
        const result: {
          data: {
            id: number;
            type: DocumentTypes;
            metadata: {
              rotation: number;
            };
          };
        } = yield call(() => patch(config.apiUrl, token, payload));
        const document = result.data;

        yield put(
          submissionDocumentPatchSuccess({
            ...document,
            id: action.payload.id,
          }),
        );
      } catch (error) {
        const errorType: ResponseErrorType = yield* handleError(error);
        yield put(
          displayMessage({
            message: error.message,
            severity: MessageSeverity.Error,
          }),
        );
        yield put(submissionDocumentPatchFailure(errorType));
      }
    },
  );
}

export function* getDocumentURLRequests(): SagaIterator {
  yield takeEvery(
    [submissionDocumentsURLRequest, submissionDocumentsURLDIFMRequest],
    function* (
      action: PayloadAction<SubmissionDocumentURLRequest>,
    ): SagaIterator {
      try {
        const ids = action.payload;
        const highPriority = ids.slice(0);
        const lowPriority = ids.slice(1);
        for (const item of highPriority) {
          yield fork(getDocumentURL, {
            documentId: item.id,
            contentType: item.contentType,
            actionType: action.type,
          });
        }
        yield delay(200); // Just to guarantee that 1st fetch has priority, although I'm not 100% sure this works
        for (const item of lowPriority) {
          yield fork(getDocumentURL, {
            documentId: item.id,
            contentType: item.contentType,
            actionType: action.type,
          });
        }
      } catch (error) {
        const errorType: ResponseErrorType = yield* handleError(error);
        yield put(submissionDocumentsFailure(errorType));
      }
    },
  );
}

function* getDocumentURL({
  documentId,
  contentType,
  actionType,
}: {
  documentId: number;
  contentType: string;
  actionType: string;
}): SagaIterator {
  try {
    const urls: SubmissionDocumentsURLs = yield select(
      submissionDocumentsURLsSelector,
    );
    const currentURL = urls[documentId];
    if (currentURL?.url || currentURL?.loading) {
      return;
    }
    yield put(submissionDocumentLoadURLStart({ documentId }));
    const url: string = yield call(() => {
      if (actionType === submissionDocumentsURLDIFMRequest.type) {
        return getDocumentURLDIFM({ documentId, contentType });
      }
      return getDocumentURLCore({ documentId, contentType });
    });

    yield put(submissionDocumentLoadURLSuccess({ documentId, url }));
  } catch (error) {
    const errorType: ResponseErrorType = yield* handleError(error);
    yield put(
      submissionDocumentLoadURLFailure({ documentId, error: errorType }),
    );
  }
}

function* getDocumentURLDIFM({
  documentId,
  contentType,
}: {
  documentId: number;
  contentType: string;
}): SagaIterator<string> {
  const token: string = yield select(tokenSelector);
  const difmId: number = yield select(doItForMeIdSelector);
  const userCorrelateId: string = yield select(getCorrelateId);

  const url: string = yield call(
    async () =>
      await getDIFMDocumentObjectURL({
        documentId,
        contentType,
        token,
        userCorrelateId,
        difmId,
      }),
  );
  return url;
}

function* getDocumentURLCore({
  documentId,
  contentType,
}: {
  documentId: number;
  contentType: string;
}): SagaIterator<string> {
  const token: string = yield select(tokenSelector);

  const url: string = yield call(
    async () =>
      await getDocumentObjectURL({
        documentId,
        contentType,
        token,
      }),
  );
  return url;
}

// DIFM

export function* createDocumentByDoItForMe(): SagaIterator {
  yield takeLatest(
    submissionDocumentUploadDoItForMeRequest,
    function* (
      action: PayloadAction<SubmissionDocumentUploadRequest>,
    ): SagaIterator {
      const { upload, type, filename, isPartnerDocument } = action.payload;

      const token = yield select(tokenSelector);
      const userCorrelateId: string = yield select(getCorrelateId);
      const difmId: number = yield select(doItForMeIdSelector);

      try {
        const { id } = yield call(() =>
          DoItForMeSDK.createWithFormDataDocument(
            config.apiUrl,
            { userOrToken: token, correlateId: userCorrelateId },
            {
              type,
              difmId,
              upload,
              filename,
              isPartnerDocument,
            },
          ),
        );
        yield delay(1000);

        const { document } = yield call(() =>
          fetchDifmDocument(token, userCorrelateId, {
            documentId: id,
            difmId,
          }),
        );

        const newDocument = {
          ...document.doc,
          ...document,
        };

        newDocument.metadata = parseMetadata(newDocument.metadata);
        newDocument.metadata.rotation =
          parseInt(newDocument.metadata.rotation as unknown as string) || 0;
        yield put(submissionDocumentUploadSuccess(newDocument));
      } catch (error) {
        yield put(
          displayMessage({
            message: error.message,
            severity: MessageSeverity.Error,
          }),
        );
        const errorType: ResponseErrorType = yield* handleError(error);
        yield put(submissionDocumentUploadFailure(errorType));
      }
    },
  );
}

export function* patchDocumentByDoItForMe(): SagaIterator {
  yield takeLatest(
    submissionDocumentPatchDoItForMeRequest,
    function* (
      action: PayloadAction<SubmissionDocumentPatchRequest>,
    ): SagaIterator {
      try {
        const token: string = yield select(tokenSelector);
        const userCorrelateId: string = yield select(getCorrelateId);
        const difmId: number = yield select(doItForMeIdSelector);
        const year = yield select(submissionYearSelector);
        const payload = {
          ...action.payload,
          type: action.payload.type as DocumentTypes,
          year,
        };
        yield call(() =>
          DoItForMeSDK.patchDocument(
            config.apiUrl,
            { userOrToken: token, correlateId: userCorrelateId },
            {
              difmId,
              payload,
            },
          ),
        );
        const document = {
          id: action.payload.id,
          type: action.payload?.type,
          metadata: action.payload?.metadata,
        };
        if (!action.payload?.type) {
          delete document.type;
        }
        if (!action.payload?.metadata) {
          delete document.metadata;
        }
        yield put(submissionDocumentPatchSuccess(document));
      } catch (error) {
        const errorType: ResponseErrorType = yield* handleError(error);
        yield put(
          displayMessage({
            message: error.message,
            severity: MessageSeverity.Error,
          }),
        );
        yield put(submissionDocumentPatchFailure(errorType));
      }
    },
  );
}

export function* updateDocumentByDoItForMe(): SagaIterator {
  yield takeLatest(
    submissionDocumentUpdateDoItForMeRequest,
    function* (
      action: PayloadAction<SubmissionDocumentUpdateRequest>,
    ): SagaIterator {
      try {
        const token: string = yield select(tokenSelector);
        const userCorrelateId: string = yield select(getCorrelateId);
        const difmId: number = yield select(doItForMeIdSelector);
        const year = yield select(submissionYearSelector);
        const result: AsyncReturnType<typeof update> = yield call(() =>
          DoItForMeSDK.updateDocument(
            config.apiUrl,
            { userOrToken: token, correlateId: userCorrelateId },
            {
              difmId,
              payload: {
                ...action.payload,
                year,
              },
            },
          ),
        );
        yield put(submissionDocumentUpdateSuccess(result));
      } catch (error) {
        const errorType: ResponseErrorType = yield* handleError(error);
        yield put(
          displayMessage({
            message: error.message,
            severity: MessageSeverity.Error,
          }),
        );
        yield put(submissionDocumentUpdateFailure(errorType));
      }
    },
  );
}

export function* createDocument(): SagaIterator {
  yield takeLatest(
    submissionDocumentUploadRequest,
    function* (
      action: PayloadAction<SubmissionDocumentUploadRequest>,
    ): SagaIterator {
      const { upload, type, filename } = action.payload;
      const token = yield select(tokenSelector);
      const countryCode = yield select(submissionCountryCodeSelector);
      const year = yield select(submissionYearSelector);
      const userId = yield select(submissionUserIdSelector);
      const remoteConfig = yield select(remoteConfigSelector);

      try {
        let response;
        if (remoteConfig?.enableTaxminApiCreateDoc.asBoolean()) {
          response = yield call(() =>
            TaxminSdk.createDocument(config.apiUrl, token, {
              userId,
              year,
              countryCode,
              type,
              platform: Platforms.api,
              platformVersion: 'taxmin',
              filename,
              file: upload,
            }),
          );
        } else {
          response = yield call(() =>
            createWithFormData(config.apiUrl, token, {
              userId,
              year,
              countryCode,
              upload,
              type,
              platform: Platforms.api,
              platformVersion: 'taxmin',
              filename,
            }),
          );
        }
        const { id } = response;
        const { data } = yield call(() => getOne(config.apiUrl, token, { id }));

        yield put(submissionDocumentUploadSuccess(data));
      } catch (error) {
        const errorType: ResponseErrorType = yield* handleError(error);
        yield put(
          displayMessage({
            message: error.message,
            severity: MessageSeverity.Error,
          }),
        );
        yield put(submissionDocumentUploadFailure(errorType));
      }
    },
  );
}

export function* uploadPoaIdDocument(): SagaIterator {
  yield takeLatest(
    poaIdentificationDocumentUploadRequest,
    function* ({
      payload: {
        identificationFile,
        userId,
        countryCode,
        year,
        isPartnerDocument,
      },
    }: PayloadAction<PoaIdentificationDocumentRequest>): SagaIterator {
      try {
        const token: string = yield select(tokenSelector);

        const { id } = yield call(() =>
          TaxminSdk.createDocumentPoaId(config.apiUrl, token, {
            year,
            countryCode,
            userId,
            file: identificationFile,
            platform: Platforms.api,
            platformVersion: 'admin-interface',
            filename: 'imagefile',
            isPartnerDocument,
          }),
        );
        const { data } = yield call(() => getOne(config.apiUrl, token, { id }));

        yield put(submissionDocumentUploadSuccess(data));
        yield put(
          displayMessage({
            message: intlStringsRegistry.getMessage(
              'de.submission.action.upload.success',
            ),
            severity: MessageSeverity.Success,
          }),
        );
      } catch (err) {
        const errorType: ResponseErrorType = yield* handleError(err);
        const message: string =
          err.message ||
          intlStringsRegistry.getMessage('de.submission.action.upload.error');
        yield put(submissionDocumentUploadFailure(errorType));
        yield put(
          displayMessage({
            message: message,
            severity: MessageSeverity.Error,
          }),
        );
      }
    },
  );
}
