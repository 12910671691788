import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { CountryCodes } from '@taxfix/types';

import {
  GoogleSignInConfig,
  useGoogleSignIn,
} from '../../hooks/use-google-sign-in';
import { doItForMeOverviewSeenInit } from '../../redux/difm';
import {
  getCurrentCountry,
  remoteConfigRequest,
  remoteConfigSelector,
} from '../../redux/me';
import { lazyWithRetry } from '../../utils/lazy-with-retry';
import { ErrorBoundary } from '../errors';
import HelpBubble from '../help-bubble';
import LoadingGrid from '../loading-grid';
import PrivateRoute from '../private-route';

const Home = lazyWithRetry(() => import('../home'));
const Login = lazyWithRetry(() => import('../login'));
const PayslipDocuments = lazyWithRetry(() => import('../payslip-documents'));
const ZendeskContainer = lazyWithRetry(() => import('../zendesk'));
const AdminPanel = lazyWithRetry(() => import('../admin'));

const App: React.FC = () => {
  const dispatch = useDispatch();
  const remoteConfig = useSelector(remoteConfigSelector);
  const currentCountry = useSelector(getCurrentCountry);

  const clientConfig: GoogleSignInConfig = config.googleSignIn;
  useGoogleSignIn(clientConfig);

  useEffect(() => {
    dispatch(remoteConfigRequest());

    // HACK: dispatched this to set up the localstorage
    // for the submission overview modal.
    dispatch(doItForMeOverviewSeenInit());
  }, [dispatch]);

  if (!remoteConfig) {
    return null;
  }

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingGrid />}>
        <Switch>
          <Route path="/login" component={Login} />
          <Redirect from={`/${CountryCodes.FR}`} to="/" />
          <PrivateRoute
            exact
            path={'/payslip/:documentId'}
            component={PayslipDocuments}
          />
          <PrivateRoute
            path="/zendesk/:userId"
            component={ZendeskContainer}
            exact
          />
          <PrivateRoute path="/admin" component={AdminPanel} exact />
          <PrivateRoute
            path={['/users', '/destruction-requests', '/:country']}
            component={Home}
          />
          <Redirect from="/" to={currentCountry} />
        </Switch>
        <HelpBubble />
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;
