import { isNil, omitBy } from 'lodash';

import { CountryCodes } from '@taxfix/types';

import { CreateTemplateFormData, TemplateStatus, TemplateType } from './types';

export const templateComposer = (
  { title, content, lang, countryCode, serviceType }: CreateTemplateFormData,
  authorId: number,
): Omit<TemplateType, 'id'> => {
  return omitBy(
    {
      countryCode: countryCode || CountryCodes.DE,
      lang,
      title,
      content,
      authorId,
      createdAt: new Date(),
      status: TemplateStatus.published,
      serviceType,
    },
    isNil,
  ) as Omit<TemplateType, 'id'>;
};

export const modifyLocalTemplate = (
  templates: TemplateType[],
  payload: {
    id: string | null;
    formData: CreateTemplateFormData;
  },
): TemplateType[] => {
  const {
    id,
    formData: { title, content },
  } = payload;
  return [
    ...templates.map(template => {
      if (template.id === id) {
        template.title = title;
        template.content = content;
      }
      return { ...template };
    }),
  ];
};

export const removeLocalTemplate = (
  id: string | null,
  templates: TemplateType[],
): TemplateType[] => {
  return [...templates.filter(template => template.id !== id)];
};

export const sortByTitle = (templates: TemplateType[]): TemplateType[] =>
  templates.sort((template1, template2) => {
    const t1 = template1.title.toLowerCase();
    const t2 = template2.title.toLowerCase();
    return t1 < t2 ? -1 : t1 > t2 ? 1 : 0;
  });
