import { createSelector } from '@reduxjs/toolkit';

import { TemplatesState } from './types';
import { sortByTitle } from './utils';

interface TemplatesSelector {
  templates: TemplatesState;
}

export const templatesSelector = ({
  templates,
}: TemplatesSelector): TemplatesState => templates;

export const allTemplatesSelector = createSelector(
  templatesSelector,
  ({ data }) => {
    const cData = [...data];
    // We need to sort locally because Firestore is
    // case sensitive.
    return sortByTitle(cData);
  },
);

export const typeSelector = createSelector(
  templatesSelector,
  ({ type }) => type,
);

export const loadingSelector = createSelector(
  templatesSelector,
  ({ loading }) => loading,
);

export const errorSelector = createSelector(
  templatesSelector,
  ({ error }) => error,
);

export const successSelector = createSelector(
  templatesSelector,
  ({ success }) => success,
);
