import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html, body, #root {
    min-height: 100%;
    height: 100%;
  }

  body {
    margin: 0;
    overflow: auto !important;
    background-color: ${({ theme }): string => theme.palette.background.paper};
  }

  * {
    transition: background-color 0.5s, border-color 0.5s;
  }

  body .sentry-error-embed-wrapper {
    z-index: 9999;
  }

  a {
    color: ${({ theme }): string => theme.palette.primary.main};
  }
  #avo-debugger {
    bottom: 50px !important;
  }
`;
