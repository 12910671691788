/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createSelector } from '@reduxjs/toolkit';

import { doItForMeStateMachine } from '@taxfix/do-it-for-me-sdk';
import { DoItForMeStatus } from '@taxfix/do-it-for-me-sdk/dist/types';
import { SERVICE_TYPES } from '@taxfix/operations-types';

import { RootState } from '..';
import { submissionServiceTypeSelector } from '../submission';
import { DoItForMeState } from './types';

const actionableTransitions = new Set([
  // TaxOps begins here
  DoItForMeStatus.WaitingForPreparation,
  DoItForMeStatus.Preparation,
  DoItForMeStatus.WaitingForReview,
  // Tax Advisor begins here
  DoItForMeStatus.Reviewing,
  DoItForMeStatus.WaitingForUserConfirmation,
  DoItForMeStatus.WaitingForUserInput,
  DoItForMeStatus.Terminated,
]);

export const doItForMeSelector = ({
  doItForMe,
}: {
  doItForMe: DoItForMeState;
}): DoItForMeState => doItForMe;

export const doItForMeIdSelector = createSelector(
  doItForMeSelector,
  ({ id }) => id,
);

export const doItForMeLoadingSelector = createSelector(
  doItForMeSelector,
  ({ loading }) => loading,
);

export const doItForMeErrorSelector = createSelector(
  doItForMeSelector,
  ({ error }) => error,
);

export const doItForMeStatusSelector = createSelector(
  doItForMeSelector,
  ({ status }) => status,
);
export const doItForMeStatusLoadingSelector = createSelector(
  doItForMeSelector,
  ({ statusLoading }) => statusLoading,
);

export const doItForMeNextAvailableStatusSelector = createSelector(
  doItForMeSelector,
  ({ status }) =>
    doItForMeStateMachine.getNextTransition(status!)?.filter(nextStatus => {
      return (
        actionableTransitions.has(status!) &&
        nextStatus != DoItForMeStatus.Rejected
      );
    }),
);

export const shouldShowRejectButtonSelector = createSelector(
  doItForMeSelector,
  ({ status }) =>
    doItForMeStateMachine.isAllowedTransition(
      status!,
      DoItForMeStatus.Rejected,
    ),
);

export const doItForMeProviderNameSelector = createSelector(
  doItForMeSelector,
  ({ reviewProviderName }) => reviewProviderName,
);

export const doItForMeReviewSelector = createSelector(
  doItForMeSelector,
  ({ review }) => review,
);

export const doItForMeReviewCreatedSelector = createSelector(
  doItForMeSelector,
  ({ createReviewSuccess }) => createReviewSuccess,
);

export const doItForMeReviewUpdatedSelector = createSelector(
  doItForMeSelector,
  ({ updateReviewSuccess }) => updateReviewSuccess,
);

export const doItForMeSubmissionSelector = createSelector(
  doItForMeSelector,
  ({ submissionId }) => submissionId,
);

export const doItForMePrefillSelector = createSelector(
  doItForMeSelector,
  ({ isPrefilled }) => isPrefilled,
);

export const doItForMeYearSelector = createSelector(
  doItForMeSelector,
  ({ year }) => year,
);

export const submissionHasDoItForMeServiceSelector = createSelector(
  (_: RootState, submissionId: number) => submissionId,
  doItForMeSelector,
  (submissionId, doItForMe) => doItForMe.submissionId === submissionId,
);

export const isSubmissionUnderDoItForMeSelector = createSelector(
  doItForMeSelector,
  submissionHasDoItForMeServiceSelector,
  (doItForMe, hasDoItForMe) =>
    hasDoItForMe &&
    [
      DoItForMeStatus.WaitingForPreparation,
      DoItForMeStatus.WaitingForReview,
      DoItForMeStatus.WaitingForUserConfirmation,
      DoItForMeStatus.WaitingForUserInput,
      DoItForMeStatus.Preparation,
      DoItForMeStatus.Reviewing,
    ].includes(doItForMe.status!),
);

export const doItForMeSubmissionPDFSelector = createSelector(
  doItForMeSelector,
  ({ id, submissionPDF, submissionPDFLoading }) => ({
    id,
    submissionPDF,
    submissionPDFLoading,
  }),
);

export const allowDifmAutoUpdateToPreparationSelector = createSelector(
  submissionServiceTypeSelector,
  doItForMeSelector,
  (submissionServiceTypeSelector, { status }) =>
    submissionServiceTypeSelector === SERVICE_TYPES.DIFM &&
    status === DoItForMeStatus.WaitingForPreparation,
);

export const doItForMeOverviewSeenSelector = createSelector(
  doItForMeSelector,
  ({ id, submissionId, overviewSeen }) => {
    if (submissionId) {
      return overviewSeen[id] ?? false;
    } else {
      // If there is no submissionId, we don't want to show the overview modal yet
      return true;
    }
  },
);
