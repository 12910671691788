import { Value } from '@firebase/remote-config-types';

import { Permissions } from '@taxfix/auth-sdk-types';
import { Departments, SERVICE_TYPES } from '@taxfix/operations-types';
import { CountryCodes } from '@taxfix/types';

import { LoginErrors } from '../../types';

export type RemoteConfigValues = { [key: string]: Value };

export enum LoginStatus {
  loggedOut = 'loggedOut',
  loggedIn = 'loggedIn',
  busy = 'busy',
  error = 'error',
}
export type MeState = {
  email: string;
  id: number;
  token: string;
  loginError: LoginErrors | null;
  reason?: string;
  remoteConfig: RemoteConfigValues | null;
  rights: Permissions[];
  currentCountry: CountryCodes;
  status: LoginStatus;
  assignedDoItForMeProviders?: number[];
  department?: Departments;
  allocationRoles: {
    [countryCode: string]: {
      [serviceType: string]: string | null;
    };
  };
};

export enum LoginType {
  Password = 'password',
  Google = 'google',
}

export type LoginRequest = {
  type: LoginType;
  email: string;
  secret: string;
};

export type LoginResponseSuccess = {
  email: string;
  id: number;
  token: string;
  rights: Permissions[];
  department?: Departments;
  allocationRoles?: {
    [countryCode: string]: {
      [serviceType: string]: string | null;
    };
  };
};

export type LoginResponseFailure = {
  reason: string;
  status: number;
};

export type TokenCheckRequest =
  | {
      country?: CountryCodes;
    }
  | undefined;

export type RemoteConfigSuccess = {
  config: RemoteConfigValues;
};

export type SetCurrentCountry = { currentCountry: CountryCodes };

export type AssignedDoItForMeProviders = {
  assignedDoItForMeProviders: number[];
};

export type Role = {
  countryCode: CountryCodes;
  serviceType: SERVICE_TYPES;
  roleId?: string | null;
};
