import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Box, IconButton, Popover, Typography } from '@taxfix/ui';
import { HelpRounded } from '@taxfix/ui/icons';

import pkg from '../../../package.json';
import { remoteConfigSelector } from '../../redux/me';
import Help from '../help';
import HelpfulLink from './helpful-link';

const StyledIconButton = styled(IconButton)`
  width: ${({ theme }): string => theme.spacing(4)};
  height: ${({ theme }): string => theme.spacing(4)};
  position: absolute;
  bottom: 11px;
  right: 17px;
`;

const StyledLinksContainer = styled(Box)`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledVersion = styled(Typography)`
  padding: 6px;
`;

const StyledPopPover = styled(Popover)`
  .MuiPaper-root {
    overflow-x: unset;
    overflow-y: unset;
    &::before {
      content: '';
      position: absolute;
      bottom: 2px;
      right: 6px;
      width: 10px;
      height: 10px;
      background-color: ${({ theme }) => theme.palette.background.paper};
      transform: translate(-50%, 50%) rotate(135deg);
      clip-path: polygon(
        -5px -5px,
        calc(100% + 5px) -5px,
        calc(100% + 5px) calc(100% + 5px)
      );
    }
  }
`;

const LINKS = [
  {
    id: 'whatsnew',
    text: 'help_menu.whats_new',
  },
  {
    id: 'bugreport',
    text: 'help_menu.bug_report',
  },
  {
    id: 'shortcuts',
    text: 'help_menu.keyboard_shortcuts',
  },
  {
    id: 'helpcenter',
    text: 'help_menu.help_center',
  },
  {
    id: 'feedback',
    text: 'help_menu.send_feedback',
  },
];

const HelpBubble: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [shortcutsVisible, setShortcutsVisible] = useState(false);
  const remoteConfig = useSelector(remoteConfigSelector);
  const helpfulLinks: { [key: string]: string } = JSON.parse(
    remoteConfig?.helpfulLinks?.asString() || '{}',
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <React.Fragment>
      <StyledPopPover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: -5,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <StyledLinksContainer>
          {LINKS.map(link => {
            const onClick = () => {
              if (link.id === 'shortcuts') {
                setShortcutsVisible(true);
              }
            };
            const href = helpfulLinks[link.id];
            return (
              <HelpfulLink
                key={link.id}
                href={href}
                text={link.text}
                onClick={onClick}
              />
            );
          })}
          <StyledVersion color="textSecondary" variant="body2">
            {`Version ${pkg.version}`}
          </StyledVersion>
        </StyledLinksContainer>
      </StyledPopPover>
      <StyledIconButton size="small" onClick={handleClick}>
        <HelpRounded fontSize="small" color="primary" />
      </StyledIconButton>
      {shortcutsVisible && (
        <Help
          visible={shortcutsVisible}
          onDismiss={(): void => setShortcutsVisible(false)}
        />
      )}
    </React.Fragment>
  );
};

export default HelpBubble;
