import { createSelector } from '@reduxjs/toolkit';

import { Permissions } from '@taxfix/auth-sdk-types';
import { SERVICE_TYPES } from '@taxfix/operations-types';
import { CountryCodes } from '@taxfix/types';

import { RootState } from '..';
import { LoginStatus, MeState, Role } from './types';

const includesPermissions = (
  agentRights: Permissions[],
  requiredRights: Permissions[],
): boolean => requiredRights.every(right => agentRights.includes(right));

export const meSelector = ({ me }: { me: MeState }): MeState => me;

export const loginStatusSelector = createSelector(
  meSelector,
  ({ status }) => status,
);

/**
 * DEPRECATED: Please use loginStatusSelector instead.
 * @deprecated
 */
export const isLoggedInSelector = createSelector(
  meSelector,
  ({ status }) => status === LoginStatus.loggedIn,
);

export const loginErrorSelector = createSelector(
  meSelector,
  ({ loginError }) => loginError,
);

export const tokenSelector = createSelector(meSelector, ({ token }) => token);

export const getCorrelateId = createSelector(
  meSelector,
  ({ id }) => `${id}-${new Date().toISOString()}`,
);

export const agentEmailSelector = createSelector(
  meSelector,
  ({ email }) => email,
);

export const agentDepartmentSelector = createSelector(
  meSelector,
  ({ department }) => department,
);

export const agentIdSelector = createSelector(meSelector, ({ id }) => id);

export const remoteConfigSelector = createSelector(
  meSelector,
  ({ remoteConfig }) => remoteConfig,
);

export const agentRightsSelector = createSelector(
  meSelector,
  ({ rights }) => rights,
);

export const isExternalAgentSelector = createSelector(
  agentRightsSelector,
  rights =>
    rights.some(
      right =>
        right === Permissions.admin_identity_external_es ||
        right === Permissions.admin_identity_external,
    ),
);

export const isAgentWithViewPermissionsSelector = createSelector(
  (_: RootState, countryCode: CountryCodes) => countryCode,
  agentRightsSelector,
  isExternalAgentSelector,
  (countryCode, rights, isExternalAgent) => {
    const country = countryCode.toLowerCase();

    return (
      !isExternalAgent &&
      includesPermissions(rights, [
        Permissions.admin_view,
        Permissions.api_submission,
        Permissions[`api_document_${country}` as Permissions],
      ])
    );
  },
);

export const isAgentWithSubmitPermissionsSelector = createSelector(
  (_: RootState, countryCode: CountryCodes) => countryCode,
  agentRightsSelector,
  isAgentWithViewPermissionsSelector,
  (_, rights, hasViewPermissions) =>
    hasViewPermissions &&
    includesPermissions(rights, [
      Permissions.api_submission_submit,
      Permissions.admin_identity_confirm,
    ]),
);

export const isAgentUserAdminSelector = createSelector(
  meSelector,
  isExternalAgentSelector,
  ({ rights }, isExternalAgent) =>
    includesPermissions(rights, [
      Permissions.admin_view,
      Permissions.api_user_admin_rights,
      Permissions.api_user,
    ]) && !isExternalAgent,
);

export const isAgentUserRightsManager = createSelector(
  meSelector,
  isExternalAgentSelector,
  ({ rights }, isExternalAgent) =>
    includesPermissions(rights, [Permissions.admin_manage_rights]) &&
    !isExternalAgent,
);

export const isAgentArchiveRole = createSelector(
  meSelector,
  isExternalAgentSelector,
  ({ rights }, isExternalAgent) =>
    includesPermissions(rights, [
      Permissions.admin_view,
      Permissions.admin_archive_user,
    ]) && !isExternalAgent,
);

export const getCurrentCountry = createSelector(
  meSelector,
  me => me.currentCountry,
);

export const assignedDoItForMeProvidersSelector = createSelector(
  meSelector,
  ({ assignedDoItForMeProviders }) => assignedDoItForMeProviders,
);

export const isAgentAdminSelector = createSelector(
  meSelector,
  isExternalAgentSelector,
  ({ rights }) => includesPermissions(rights, [Permissions.admin_view]),
);

export const isAgentWithSubmissionsPermissionsSelector = createSelector(
  (_: RootState, countryCode: CountryCodes) => countryCode,
  agentRightsSelector,
  isExternalAgentSelector,
  (countryCode, rights, isExternalAgent) => {
    const submissionPermission = `api_submission${
      countryCode === CountryCodes.DE
        ? ''
        : `_${countryCode.toLocaleLowerCase()}`
    }`;

    return (
      !isExternalAgent &&
      includesPermissions(rights, [
        Permissions[submissionPermission as Permissions],
      ])
    );
  },
);

export const isAgentWithExpertReviewsPermissionSelector = createSelector(
  agentRightsSelector,
  rights => {
    return includesPermissions(rights, [Permissions.api_expert_review_de]);
  },
);

export const isAgentWithDoItForMePermissionSelector = createSelector(
  agentRightsSelector,
  rights => {
    return includesPermissions(rights, [Permissions.api_difm_de]);
  },
);

export const isAgentAssignedToDoItForMeProvidersSelector = createSelector(
  assignedDoItForMeProvidersSelector,
  assignedDoItForMeProviders => Boolean(assignedDoItForMeProviders?.length),
);

// for now, we assume for now that we have only one
// but in the future when we will handle one role per country/service, add them as parameters and just return it
export const allocationRolesSelector = createSelector(
  meSelector,
  remoteConfigSelector,
  ({ allocationRoles }, remoteConfig) => {
    const useAllocationAgentsTable =
      remoteConfig?.useAllocationAgentsTable?.asBoolean() ?? false;

    if (useAllocationAgentsTable) {
      for (const countryCode of Object.keys(allocationRoles)) {
        for (const serviceType of Object.keys(allocationRoles[countryCode])) {
          if (allocationRoles[countryCode][serviceType] !== null) {
            return {
              countryCode,
              serviceType,
              roleId: allocationRoles[countryCode][serviceType],
            } as Role;
          }
        }
      }
      return null;
    } else {
      return {
        countryCode: CountryCodes.DE,
        serviceType: SERVICE_TYPES.DIY,
        roleId: allocationRoles?.[CountryCodes.DE]?.[SERVICE_TYPES.DIY],
      };
    }
  },
);
