import { persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/es/storage';

import { reduxRegistry } from '..';
import slice from './me';
import * as sagas from './sagas';

export * from './me';
export * from './types';
export * from './selectors';

const { name, reducer } = slice;

const persistConfig = {
  key: 'me',
  storage: localStorage,
  blacklist: ['loginError', 'remoteConfig'],
  version: 0,
};

reduxRegistry.registerReducer(name, persistReducer(persistConfig, reducer));
reduxRegistry.registerSagas(name, sagas);
