import { HotKeysMap } from '../../hooks/use-hotkeys-map';

export const navigationKeyMapDe: HotKeysMap = {
  'elster-info': {
    sequence: 'g f',
    name: 'navigation.open_fields_tab',
  },
  documents: {
    sequence: 'g d',
    name: 'navigation.open_documents_tab',
  },
  comments: {
    sequence: 'g c',
    name: 'navigation.open_comments_tab',
  },
  responses: {
    sequence: 'g r',
    name: 'navigation.open_response_tab',
  },
  timeline: {
    sequence: 'g t',
    name: 'navigation.open_timeline_tab',
  },
  'prefilled-data': {
    sequence: 'g p',
    name: 'navigation.open_prefilled-data_tab',
  },
  'expert-review': {
    sequence: 'g x',
    name: 'navigation.open_expert-review_tab',
  },
  'tax-form-info': {
    sequence: 'g f',
    name: 'navigation.open_fields_tab',
  },
};

export const navigationKeyMapIt: HotKeysMap = {
  'fields-730': {
    sequence: 'g f',
    name: 'navigation.open_730_fields_tab',
  },
  documents: {
    sequence: 'g d',
    name: 'navigation.open_documents_tab',
  },
  comments: {
    sequence: 'g c',
    name: 'navigation.open_comments_tab',
  },
  messages: {
    sequence: 'g m',
    name: 'navigation.open_messages_tab',
  },
};

export const submissionActionsKeyMap: HotKeysMap = {
  revert: {
    sequence: 'shift+b',
    name: 'submission.revert',
  },
  submit: {
    sequence: 'shift+enter',
    name: 'submission.submit',
  },
  save: {
    sequence: 'shift+s',
    name: 'submission.save',
  },
  upload: {
    sequence: 'shift+u',
    name: 'submission.upload',
  },
  download: {
    sequence: 'shift+d',
    name: 'submission.download',
  },
  downloadTxfx: {
    sequence: 'shift+t',
    name: 'submission.download-txfx',
  },
  downloadDataJson: {
    sequence: 'shift+j',
    name: 'submission.download-json',
  },
  downloadFields: {
    sequence: 'shift+f',
    name: 'submission.download-fields',
  },
  archive: {
    sequence: 'shift+a',
    name: 'submission.archive',
  },
  remove: {
    sequence: 'shift+r',
    name: 'submission.remove',
  },
  openCRM: {
    sequence: 'shift+i',
    name: 'submission.customerService',
  },
  more: {
    sequence: 'shift+m',
    name: 'submission.more',
  },
  zucchetti: {
    sequence: 'shift+z',
    name: 'submission.zucchetti',
  },
};

export const doItForMeKeyMap: HotKeysMap = {
  save: {
    sequence: 'shift+s',
    name: 'submission.save',
  },
  openCRM: {
    sequence: 'shift+i',
    name: 'submission.customerService',
  },
  validate: {
    sequence: 'shift+v',
    name: 'submission.validate',
  },
  importFromJson: {
    sequence: 'shift+j',
    name: 'submission.import-difm-fields',
  },
};

export const germanSidebarKeyMap: HotKeysMap = {
  calculations: {
    sequence: 'g c',
    name: 'navigation.open_calculations_tab',
  },
  responses: {
    sequence: 'g r',
    name: 'navigation.open_response_tab',
  },
  create: {
    sequence: 'g a',
    name: 'navigation.open_create_tab',
  },
  submissionPdf: {
    sequence: 'g p',
    name: 'navigation.open_submissionPdf_tab',
  },
  summary: {
    sequence: 'g s',
    name: 'navigation.open_expert-review_tab',
  },
  handover: {
    sequence: 'g h',
    name: 'navigation.open_fields_tab',
  },
};

export const italianSidebarKeyMap: HotKeysMap = {
  errors: {
    sequence: 'g e',
    name: 'navigation.open_errors_tab',
  },
  responses: {
    sequence: 'g r',
    name: 'navigation.open_response_tab',
  },
  files: {
    sequence: 'g f',
    name: 'navigation.open_files_tab',
  },
  notes: {
    sequence: 'g n',
    name: 'navigation.open_notes_tab',
  },
};

export const spanishSidebarKeyMap: HotKeysMap = {
  prefills: {
    sequence: 'g r',
    name: 'navigation.open_prefilled-data_tab',
  },
  'm100-preview': {
    sequence: 'g m',
    name: 'navigation.open_prefilled-data_tab',
  },
  draft: {
    sequence: 'g d',
    name: 'navigation.open_draft_tab',
  },
};
